export default function downloadImage ({ path }) {
    if (!path) return '';

    const params = `file/download${path}`

    return process.env.IS_DEVELOPMENT === 'true' ?
        `http://localhost:5005/cdn/${params}` :
        `${process.env.REACT_APP_CDN_IMAGE_PATH}/${params}`;
}

