import {
  GET_PROJECT_CATEGORY_DETAIL,
  SET_PROJECT_CATEGORY_DETAIL,
  GET_PROJECT_CATEGORIES,
  SET_PROJECT_CATEGORIES,
  ADD_NEW_PROJECT_CATEGORY,
  UPDATE_PROJECT_CATEGORY,
  DELETE_PROJECT_CATEGORY,
} from "./actionTypes"

export const getProjectCategories = (params) => ({
  type: GET_PROJECT_CATEGORIES,
  payload: { params },
})

export const setProjectCategories = payload => ({
  type: SET_PROJECT_CATEGORIES,
  payload,
})

export const addNewProjectCategory = payload => ({
  type: ADD_NEW_PROJECT_CATEGORY,
  payload,
})

export const getProjectCategoryDetail = (params) => ({
  type: GET_PROJECT_CATEGORY_DETAIL,
  payload: { params },
})

export const setProjectCategoryDetail = payload => ({
  type: SET_PROJECT_CATEGORY_DETAIL,
  payload,
})


export const updateProjectCategory = payload => ({
  type: UPDATE_PROJECT_CATEGORY,
  payload,
})


export const deleteProjectCategory = payload => ({
  type: DELETE_PROJECT_CATEGORY,
  payload,
})


