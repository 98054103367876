
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_REFERANCES, GET_REFERANCE_DETAIL , ADD_NEW_REFERANCE , DELETE_REFERANCE, UPDATE_REFERANCE } from "./actionTypes"

import {
  setReferanceDetail,
  setReferances,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getReferances, getReferanceDetail, deleteReferance, updateReferance, addReferance } from "../../helpers/backend_helper"


function* fetchReferances({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setReferanceDetail({}))
  try {
    const response = yield call(getReferances, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setReferances(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchReferanceDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getReferanceDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setReferanceDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateReferance({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateReferance, payload)
    yield put(toggleActionLoading(false))
    yield put(setReferanceDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Referance updated successfuly.', redirectUrl: '/referance-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteReferance({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteReferance, id)
    const responsePages = yield call(getReferances, { params })
    yield put(setReferances(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Referance deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewReferance({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addReferance, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Referance added successfuly.', redirectUrl: '/referance-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* referancesSaga() {
  yield takeEvery(GET_REFERANCES, fetchReferances)
  yield takeEvery(GET_REFERANCE_DETAIL, fetchReferanceDetail)
  yield takeEvery(ADD_NEW_REFERANCE, onAddNewReferance)
  yield takeEvery(UPDATE_REFERANCE, onUpdateReferance)
  yield takeEvery(DELETE_REFERANCE, onDeleteReferance)
}

export default referancesSaga
