import {
  GET_PRODUCT_DETAIL,
  SET_PRODUCT_DETAIL,
  GET_PRODUCTS,
  SET_PRODUCTS,
  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "./actionTypes"

export const getProducts = (params) => ({
  type: GET_PRODUCTS,
  payload: { params },
})

export const setProducts = payload => ({
  type: SET_PRODUCTS,
  payload,
})

export const addNewProduct = payload => ({
  type: ADD_NEW_PRODUCT,
  payload,
})

export const getProductDetail = (params) => ({
  type: GET_PRODUCT_DETAIL,
  payload: { params },
})

export const setProductDetail = payload => ({
  type: SET_PRODUCT_DETAIL,
  payload,
})


export const updateProduct = payload => ({
  type: UPDATE_PRODUCT,
  payload,
})


export const deleteProduct = payload => ({
  type: DELETE_PRODUCT,
  payload,
})


