/* eslint-disable */
import React, { useEffect, useState, useRef, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "components/Common/Breadcrumb";
import RemoteTable from "components/Common/RemoteTable";

import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "store/contacts/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import CreateContact from "../CreateContact";
import CreateContactForm from "../CreateContactForm";

const ContactsList = props => {
  const dispatch = useDispatch();

  const { contacts } = useSelector(state => ({
    contacts: state.contacts.contacts,
  }));

	const [perPage, setPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState('');
  const [contactList, setContactList] = useState({});
  const [modal, setModal] = useState(false);

  const columns = [
    {
      text: '#',
      formatter: (cellContent, row) => (
        <>
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
            {row?.full_name?.toUpperCase()?.charAt(0)}
            </span>
          </div>
        </>
      ),
    },
    {
      text: `${props.t('Language')}`,
      dataField: "language",
    },
    {
        text: `${props.t("Full Name")}`,
        dataField: 'full_name',
    },
    {
        text: `${props.t("Email")}`,
        dataField: 'email',
    },
    {
      text: `${props.t("Message")}`,
      dataField: 'message',
      formatter: (cellContent, row) => row.message?.slice(0, 40) + (row.message.length > 40 ? "..." : ""),
    },
    {
      text: `${props.t("Read Status")}`,
      formatter: (cellContent, row) => (
        <span
          to="#"
          className="badge badge-soft-primary font-size-11 m-1"
        >
          {row?.isReaded ? 'Readed' : 'Unreaded'}
        </span>
      ),
    },
    {
      text: `${props.t("Menu")}`,
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleContactClick(row)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteContact(row._id)}
            ></i>
          </Link>
          <Link className="text-info" to={`/contact-list/${row._id}`}>
            <i
              className="mdi mdi-read font-size-18"
              id="viewtooltip"
            ></i>
          </Link>
        </div>
      )
    }
  ];

  const handleDeleteContact = id => {
    if (id !== undefined && confirm(props.t('Are you sure?'))) {
      const params = {
        page: contacts.page,
        limit: perPage
      };
      if (searchValue) {
        params['title[regex]'] = searchValue
      }
      
      dispatch(onDeleteContact({ id, params }));
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleContactClick = arg => {
    const contact = arg;

    setContactList({
      _id: contact._id,
      full_name: contact.full_name,
      subject: contact.subject,
      email: contact.email,
      message: contact.message,
    });

    toggle();
  };


  useEffect(() => {
    dispatch(onGetContacts());
  }, []);

  const handleSearch = (inputValue) => {
    const params = {
      limit: perPage,
    }
    if (inputValue) {
      params['email[regex]'] = inputValue;
    }
    setSearchValue(inputValue);
    dispatch(onGetContacts(params));
  }

  const handleTableChange  = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    if (type == 'sort') return;
    setPerPage(sizePerPage);
    const params = {
      limit: sizePerPage,
      page: page
    }
    dispatch(onGetContacts(params));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("page_info_list", { name: props.t("Contact") })} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Contacts")} breadcrumbItem={props.t("page_info_list", { name: props.t("Contact") })} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <RemoteTable
                    search
                    onSearch={handleSearch}
                    columns={columns}
                    data={contacts?.data || []}
                    page={contacts?.page || 1}
                    sizePerPage={perPage}
                    totalSize={contacts?.total || 0}
                    onTableChange={handleTableChange}
                  />
                </CardBody>
              </Card>
            </Col>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {contactList?._id ? `${props.t("edit_btn_for_model", {name: props.t("Contact") })}` : `${props.t("add_btn_for_model", {name: props.t("Contact") })}`}
              </ModalHeader>
              <ModalBody>
                <CreateContactForm inline id={contactList?._id} onClose={toggle} />
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ContactsList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(withRouter(ContactsList));