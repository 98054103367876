const sidebarList = (t) => [
  {
    title: t("Dashboard"),
    child: [
      {
        title: t("Dashboard"),
        icon: "bx bx-home-circle",
        href: "/dashboard",
      },
    ],
  },
  {
    title: t("Global"),
    child: [
      // {
      //   title: t("Global"),
      //   icon: "bx bx-globe",
      //   href: "/global",
      // },
      // {
      //   title: t("Languages"),
      //   icon: "bx bxs-detail",
      //   child: [
      //     {
      //       title: `${t("page_info_list", { name: t("Language")})}`,
      //       href: "/language-list",
      //     },
      //     {
      //       title: `${t("page_info_create", { name: t("Language")})}`,
      //       href: "/language-create",
      //     },
      //   ],
      // },
      {
        title: t("Users"),
        icon: "bx bxs-user",
        child: [
          {
            title: `${t("page_info_list", { name: t("User")})}`,
            href: "/user-list",
          },
          {
            title: `${t("page_info_create", { name: t("User")})}`,
            href: "/user-create",
          },
        ],
      },
    ],
  },
  {
    title: t("Pages"),
    child: [
      {
        title: t("Products"),
        icon: "bx bx-store",
        child: [
          {
            title: `${t("page_info_list", { name: t("Product Category")})}`,
            href: "/products/product-category-list",
          },
          {
            title: `${t("page_info_create", { name: t("Product Category")})}`,
            href: "/products/product-category-create",
          },
          {
            title: `${t("page_info_list", { name: t("Product")})}`,
            href: "/products/product-list",
          },
          {
            title: `${t("page_info_create", { name: t("Product")})}`,
            href: "/products/product-create",
          },
          // {
          //   title: `${t("page_info_list", { name: t("Order")})}`,
          //   href: "/products/order-list",
          // },
        ]
      },
      {
        title: t("Distributorship"),
        icon: "bx bx-store",
        child: [
          {
            title: `${t("page_info_list", { name: t("Distributorship Category")})}`,
            href: "/distributorships/distributorship-category-list",
          },
          {
            title: `${t("page_info_create", { name: t("Distributorship Category")})}`,
            href: "/distributorships/distributorship-category-create",
          },
          {
            title: `${t("page_info_list", { name: t("Distributorship")})}`,
            href: "/distributorships/distributorship-list",
          },
          {
            title: `${t("page_info_create", { name: t("Distributorship")})}`,
            href: "/distributorships/distributorship-create",
          },
        ]
      },
      // {
      //   title: t("Distributorship"),
      //   icon: "bx bxs-news",
      //   child: [
      //     {
      //       title: `${t("page_info_list", { name: t("Distributorship")})}`,
      //       href: "/distributorship-list",
      //     },
      //     {
      //       title: `${t("page_info_create", { name: t("Distributorship")})}`,
      //       href: "/distributorship-create",
      //     },
      //   ],
      // },
      {
        title: t("Projects"),
        icon: "bx bx-store",
        child: [
          {
            title: `${t("page_info_list", { name: t("Project Category")})}`,
            href: "/projects/project-category-list",
          },
          {
            title: `${t("page_info_create", { name: t("Project Category")})}`,
            href: "/projects/project-category-create",
          },
          {
            title: `${t("page_info_list", { name: t("Project")})}`,
            href: "/projects/project-list",
          },
          {
            title: `${t("page_info_create", { name: t("Project")})}`,
            href: "/projects/project-create",
          },
          // {
          //   title: `${t("page_info_list", { name: t("Order")})}`,
          //   href: "/projects/order-list",
          // },
        ]
      },
      {
        title: t("Contact"),
        icon: "bx bxs-detail",
        child: [
          {
            title: `${t("page_info_list", { name: t("Contact")})}`,
            href: "/contact-list",
          },
          {
            title: `${t("page_info_create", { name: t("Contact")})}`,
            href: "/contact-create",
          },
        ],
      },
      {
        title: t("Calculation"),
        icon: "bx bxs-detail",
        child: [
          {
            title: `${t("page_info_list", { name: t("Calculation")})}`,
            href: "/calculation-list",
          },
        ],
      },
      {
        title: t("Dynamic Page"),
        icon: "bx bxs-detail",
        child: [
          {
            title: `${t("page_info_list", { name: t("Dynamic Page")})}`,
            href: "/dynamic-page-list",
          },
          {
            title: `${t("page_info_create", { name: t("Dynamic Page")})}`,
            href: "/dynamic-page-create",
          },
        ],
      },
      {
        title: t("Home Slider"),
        icon: "bx bxs-detail",
        child: [
          {
            title: `${t("page_info_list", { name: t("Home Slider")})}`,
            href: "/home-sliders-list",
          },
          {
            title: `${t("page_info_create", { name: t("Home Slider")})}`,
            href: "/home-sliders-create",
          },
        ],
      },
      {
        title: t("Video Slider"),
        icon: "bx bxs-detail",
        child: [
          {
            title: `${t("page_info_list", { name: t("Video Slider")})}`,
            href: "/video-sliders-list",
          },
          {
            title: `${t("page_info_create", { name: t("Video Slider")})}`,
            href: "/video-sliders-create",
          },
        ],
      },
      // {
      //   title: t("Charging Stations"),
      //   icon: "bx bxs-car",
      //   child: [
      //     {
      //       title: `${t("page_info_list", { name: t("Charging Station")})}`,
      //       href: "/charging-station-list",
      //     },
      //     {
      //       title: `${t("page_info_create", { name: t("Charging Station")})}`,
      //       href: "/charging-station-create",
      //     },
      //   ],
      // },
      // {
      //   title: t("Our Process"),
      //   icon: "bx bxs-detail",
      //   child: [
      //     {
      //       title: `${t("page_info_list", { name: t("Our Process")})}`,
      //       href: "/our-process-list",
      //     },
      //     {
      //       title: `${t("page_info_create", { name: t("Our Process")})}`,
      //       href: "/our-process-create",
      //     },
      //   ],
      // },
      // {
      //   title: t("Solutions"),
      //   icon: "bx bxs-detail",
      //   child: [
      //     {
      //       title: `${t("page_info_list", { name: t("Solutions")})}`,
      //       href: "/solution-list",
      //     },
      //     {
      //       title: `${t("page_info_create", { name: t("Solutions")})}`,
      //       href: "/solution-create",
      //     },
      //   ],
      // },
      // {
      //   title: t("Referances"),
      //   icon: "bx bxs-detail",
      //   child: [
      //     {
      //       title: `${t("page_info_list", { name: t("Referances")})}`,
      //       href: "/referance-list",
      //     },
      //     {
      //       title: `${t("page_info_create", { name: t("Referances")})}`,
      //       href: "/referance-create",
      //     },
      //   ],
      // },
      // {
      //   title: t("FAQ"),
      //   icon: "bx bxs-detail",
      //   child: [
      //     {
      //       title: `${t("page_info_list", { name: t("FAQ")})}`,
      //       href: "/faq-list",
      //     },
      //     {
      //       title: `${t("page_info_create", { name: t("FAQ")})}`,
      //       href: "/faq-create",
      //     },
      //   ],
      // },
      {
        title: t("Our Values"),
        icon: "bx bxs-detail",
        child: [
          {
            title: `${t("page_info_list", { name: t("Our Values")})}`,
            href: "/ourvalue-list",
          },
          {
            title: `${t("page_info_create", { name: t("Our Values")})}`,
            href: "/ourvalue-create",
          },
        ],
      },
      {
        title: t("Directors"),
        icon: "bx bxs-detail",
        child: [
          {
            title: `${t("page_info_list", { name: t("Directors")})}`,
            href: "/director-list",
          },
          {
            title: `${t("page_info_create", { name: t("Directors")})}`,
            href: "/director-create",
          },
        ],
      },
      {
        title: t("Blog Posts"),
        icon: "bx bxs-news",
        child: [
          {
            title: `${t("page_info_list", { name: t("Blog Posts")})}`,
            href: "/blog-post-list",
          },
          {
            title: `${t("page_info_create", { name: t("Blog Posts")})}`,
            href: "/blog-post-create",
          },
        ],
      },
      {
        title: t("Subscribers"),
        icon: "bx bxs-news",
        child: [
          {
            title: `${t("page_info_list", { name: t("Subscribers")})}`,
            href: "/subscriber-list",
          },
          {
            title: `${t("page_info_create", { name: t("Subscribers")})}`,
            href: "/subscriber-create",
          },
        ],
      },
      // {
      //   title: t("Sustainables"),
      //   icon: "bx bxs-detail",
      //   child: [
      //     {
      //       title: `${t("page_info_list", { name: t("Sustainables")})}`,
      //       href: "/sustainable-list",
      //     },
      //     {
      //       title: `${t("page_info_create", { name: t("Sustainables")})}`,
      //       href: "/sustainable-create",
      //     },
      //   ],
      // },
    ],
  },
]

export default sidebarList;