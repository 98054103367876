import {
  GET_LANGUAGE_OPTIONS,
  GET_LANGUAGE_OPTIONS_FAIL,
  GET_LANGUAGE_OPTIONS_SUCCESS,
  
  GET_PRODUCT_CATEGORY_OPTIONS,
  GET_PRODUCT_CATEGORY_OPTIONS_FAIL,
  GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS,
  
  GET_PROJECT_CATEGORY_OPTIONS,
  GET_PROJECT_CATEGORY_OPTIONS_FAIL,
  GET_PROJECT_CATEGORY_OPTIONS_SUCCESS,
  
  GET_PRODUCT_PROPERTIES_OPTIONS,
  GET_PRODUCT_PROPERTIES_OPTIONS_FAIL,
  GET_PRODUCT_PROPERTIES_OPTIONS_SUCCESS,

  GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS,
  GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_FAIL,
  GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_SUCCESS,
  
  TOOGLE_FETCHING_LOADING,
  SHOW_NOTIFICATION,
  RESET_NOTIFICATION,
  TOGGLE_ACTION_LOADING,
} from "./actionTypes"

export const getLanguageOptions = (params) => ({
  type: GET_LANGUAGE_OPTIONS,
  payload: { params },
})

export const getLanguageOptionsSuccess = payload => ({
  type: GET_LANGUAGE_OPTIONS_SUCCESS,
  payload,
})

export const getLanguageOptionsFail = error => ({
  type: GET_LANGUAGE_OPTIONS_FAIL,
  payload: error,
})

export const getProjectCategoryOptions = (params) => ({
  type: GET_PROJECT_CATEGORY_OPTIONS,
  payload: { params },
})

export const getProjectCategoryOptionsSuccess = payload => ({
  type: GET_PROJECT_CATEGORY_OPTIONS_SUCCESS,
  payload,
})

export const getProjectCategoryOptionsFail = error => ({
  type: GET_PROJECT_CATEGORY_OPTIONS_FAIL,
  payload: error,
})

export const getProductCategoryOptions = (params) => ({
  type: GET_PRODUCT_CATEGORY_OPTIONS,
  payload: { params },
})

export const getProductCategoryOptionsSuccess = payload => ({
  type: GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS,
  payload,
})

export const getProductCategoryOptionsFail = error => ({
  type: GET_PRODUCT_CATEGORY_OPTIONS_FAIL,
  payload: error,
})

export const getProductPropertyOptions = (params) => ({
  type: GET_PRODUCT_PROPERTIES_OPTIONS,
  payload: { params },
})

export const getProductPropertyOptionsSuccess = payload => ({
  type: GET_PRODUCT_PROPERTIES_OPTIONS_SUCCESS,
  payload,
})

export const getProductPropertyOptionsFail = error => ({
  type: GET_PRODUCT_PROPERTIES_OPTIONS_FAIL,
  payload: error,
})

export const getDistributorshipCategoryOptions = (params) => ({
  type: GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS,
  payload: { params },
})

export const getDistributorshipCategoryOptionsSuccess = payload => ({
  type: GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_SUCCESS,
  payload,
})

export const getDistributorshipCategoryOptionsFail = error => ({
  type: GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_FAIL,
  payload: error,
})

export const toggleFetchingLoading = payload => ({
  type: TOOGLE_FETCHING_LOADING,
  payload: payload,
})

export const showNotification = notification => ({
  type: SHOW_NOTIFICATION,
  payload: notification,
})

export const resetNotification = notification => ({
  type: SHOW_NOTIFICATION,
  payload: null
})

export const toggleActionLoading = payload => ({
  type: TOGGLE_ACTION_LOADING,
  payload: payload
})