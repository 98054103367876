import React, { useCallback, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import { debounce } from "lodash"
import PropTypes from "prop-types"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap"


const defaultSorted = [
  {
    dataField: "title",
    order: "desc",
  },
]

const cellEditProps = {
  mode: "click",
}

const SearchBar = ({ onSearch }) => {
  const [filterText, setFilterText] = useState('')
  const debounceFn = useCallback(debounce(onSearch, 1000), [])
  
  const handleSearch = (e) => {
    setFilterText(e.target.value)
    debounceFn(e.target.value)
  }
  return (
    <div className="search-box ms-2 mb-2 d-inline-block">
      <div className="position-relative">
        <label htmlFor="search-bar-0" className="search-label">
          <span id="search-bar-0-label" className="sr-only">Search this table</span>
          <input onChange={handleSearch} id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder="Search" value={filterText} />
        </label>
        <i className="bx bx-search-alt search-icon"></i>
      </div>
    </div>
  )
}

SearchBar.propTypes = {
  onSearch: PropTypes.func,
}

SearchBar.defaultProps = {
  search() {},
}

const RemoteTable = ({ data, columns, onRightButtonEvent, rightButtonText, search, onSearch, keyField, page, sizePerPage, onTableChange, totalSize }) => {
  return (
    <div>
      <Row className="mb-2 w-100">
            {search ? (<Col sm="4"><SearchBar onSearch={onSearch} /></Col>) : null}
              {rightButtonText ? (
                <Col sm="8">
                  <div className="text-sm-end">
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={onRightButtonEvent}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        {rightButtonText}
                      </Button>
                  </div>
                </Col>
            ) : null}
          </Row>
      <BootstrapTable
        remote
        keyField={keyField}
        classes={
          "table align-middle table-nowrap table-hover"
        }
        responsive
        bordered={false}
        striped={false}
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        // cellEdit={cellEditFactory(cellEditProps)}
        onTableChange={onTableChange}
      />
    </div>
  )
}

RemoteTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rightButtonText: PropTypes.string,
  onRightButtonEvent: PropTypes.func,
  page: PropTypes.number.isRequired,
  keyField: PropTypes.string,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  search: PropTypes.bool,
}

RemoteTable.defaultProps = {
  keyField: "_id",
  rightButtonText: '',
  onRightButtonEvent() {},
  search: true,
  onSearch() {},
}

export default RemoteTable