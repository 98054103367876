import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import toastr from "toastr"

import "toastr/build/toastr.min.css"

import {
  resetNotification
} from "store/form/actions"


const Mesagemiddleware = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { notification } = useSelector(state => ({
    notification: state.form.notification,
  }))

  useEffect(() => {
    if (notification) {
      if (notification.type == 'success') {
        toastr.success(notification.message, notification.title, {
          timeOut: 1500,
          onHidden: () => {
            dispatch(resetNotification());
          }
        });
      } else if (notification.type == 'error') {
        toastr.error(notification.message, notification.title, {
          timeOut: 1500,
          onHidden: () => {
            dispatch(resetNotification());
          }
        });
      }

      if (notification?.redirectUrl) {
        history.push(notification.redirectUrl)
      }
    }
  }, [notification]);



  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

Mesagemiddleware.propTypes = {
  children: PropTypes.any,
}

export default Mesagemiddleware
