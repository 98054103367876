import {
  GET_VIDEO_SLIDER_DETAIL,
  SET_VIDEO_SLIDER_DETAIL,
  GET_VIDEO_SLIDERS,
  SET_VIDEO_SLIDERS,
  ADD_NEW_VIDEO_SLIDER,
  UPDATE_VIDEO_SLIDER,
  DELETE_VIDEO_SLIDER,
} from "./actionTypes"

export const getVideoSliders = (params) => ({
  type: GET_VIDEO_SLIDERS,
  payload: { params },
})

export const setVideoSliders = payload => ({
  type: SET_VIDEO_SLIDERS,
  payload,
})

export const addNewVideoSlider = payload => ({
  type: ADD_NEW_VIDEO_SLIDER,
  payload,
})

export const getVideoSliderDetail = (params) => ({
  type: GET_VIDEO_SLIDER_DETAIL,
  payload: { params },
})

export const setVideoSliderDetail = payload => ({
  type: SET_VIDEO_SLIDER_DETAIL,
  payload,
})


export const updateVideoSlider = payload => ({
  type: UPDATE_VIDEO_SLIDER,
  payload,
})


export const deleteVideoSlider = payload => ({
  type: DELETE_VIDEO_SLIDER,
  payload,
})


