import React, { useEffect } from "react"
import PropTypes from 'prop-types'

import { Link, useHistory, useParams } from "react-router-dom"
import { createBrowserHistory } from 'history';

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"
import Messagemiddleware from "./routes/message"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import {
  getLanguageOptions,
} from "store/form/actions"

// Import scss
import "./assets/scss/theme.scss"

const history = createBrowserHistory();


const App = props => {
  useEffect(() => {
    props.dispatch(getLanguageOptions())
  }, []);


  const Layout = VerticalLayout;
  return (
    <React.Fragment>
      <Router history={history} >
          <Messagemiddleware>
            <Switch>
                {publicRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    exact
                  />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ))}
            </Switch>
          </Messagemiddleware>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
  form: PropTypes.any,
  dispatch: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    form: state.form,
  }
}


export default connect(mapStateToProps, null)(App)
