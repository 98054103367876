import {
  GET_OUR_PROCESS_DETAIL,
  SET_OUR_PROCESS_DETAIL,
  GET_OUR_PROCESSS,
  SET_OUR_PROCESSS,
  ADD_NEW_OUR_PROCESS,
  UPDATE_OUR_PROCESS,
  DELETE_OUR_PROCESS,
} from "./actionTypes"

export const getOurProcess = (params) => ({
  type: GET_OUR_PROCESSS,
  payload: { params },
})

export const setOurProcess = payload => ({
  type: SET_OUR_PROCESSS,
  payload,
})

export const addNewOurProcess = payload => ({
  type: ADD_NEW_OUR_PROCESS,
  payload,
})

export const getOurProcessDetail = (params) => ({
  type: GET_OUR_PROCESS_DETAIL,
  payload: { params },
})

export const setOurProcessDetail = payload => ({
  type: SET_OUR_PROCESS_DETAIL,
  payload,
})


export const updateOurProcess = payload => ({
  type: UPDATE_OUR_PROCESS,
  payload,
})


export const deleteOurProcess = payload => ({
  type: DELETE_OUR_PROCESS,
  payload,
})


