import {
  SET_GLOBAL_DETAIL
} from "./actionTypes"

const INIT_STATE = {
  globalDetail: {},
}

const global = (state = INIT_STATE, action) => {
  switch (action.type) {

    case SET_GLOBAL_DETAIL:
      return {
        ...state,
        globalDetail: action.payload,
      }
    default:
      return state
  }
}

export default global
