// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper__2H9dc {\n  overflow-x: auto; }\n\n.style_pagination__3Bv-9 {\n  justify-content: center;\n  margin: 30px; }\n\n.style_dataSlice__2kniI {\n  margin-top: 15px; }\n", "",{"version":3,"sources":["webpack://src/components/Pagination/style.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAe,EAAA;;AAGjB;EACE,uBAAuB;EACvB,YAAW,EAAA;;AAGb;EACE,gBAAgB,EAAA","sourcesContent":[".wrapper {\n  overflow-x:auto;\n}\n\n.pagination {\n  justify-content: center;\n  margin:30px;\n}\n\n.dataSlice {\n  margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper__2H9dc",
	"pagination": "style_pagination__3Bv-9",
	"dataSlice": "style_dataSlice__2kniI"
};
export default ___CSS_LOADER_EXPORT___;
