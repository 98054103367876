import React, { useState, useEffect, useCallback, useRef } from "react"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Button } from "reactstrap"
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import Geocode from "react-geocode";

import "@reach/combobox/styles.css"

import mapStyles from "./mapStyles"

import styles from "./styles.module.css"

// const MAP_KEY = "AIzaSyC486QBkTPFZjMbXfQbPdjNN1VfTDnqVYk"
const MAP_KEY = "AIzaSyCeFzK30NskOuyl9mJM1YW2YEaBznFBs0E"

const mapContainerStyle = {
  width: "100%",
  height: "500px",
}

const center = {
  lat: 40.9831796,
  lng: 29.0823555,
}

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
}
const libraries = ["places"];

Geocode.setApiKey(MAP_KEY);

// set response language. Defaults to english.
Geocode.setLanguage("tr");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("tr");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

function MapContainer({ t, initialCenter = center, location, onLocationChange }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: MAP_KEY,
    libraries,
  });
  const [selected, setSelected] = React.useState(false);

  const onMapClick = (e) => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        let address = response.results[0].formatted_address;
        onLocationChange({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          address,
        })
      },
      (error) => {
        console.error(error);
      }
    );
  };


  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(12);
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <Search t={t} panTo={panTo} />
        <Locate t={t} panTo={panTo} />
      </div>
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={initialCenter}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {location?.lat ? (
          <Marker
            key={`${location.lat}-${location.lng}`}
            position={{ lat: location.lat, lng: location.lng }}
            onClick={() => {
              setSelected(true);
            }}
          />
        ) : null}

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(false);
            }}
          >
            <div>
              <h4>İşaretlenen Konum</h4>
              <p>
                <span><strong>Lat</strong> {location.lat}</span> <br/>
                <span><strong>Lon</strong> {location.lng}</span>
              </p>
              <p>
              <span><strong>Address</strong> {location.address}</span>
              </p>
              <Button
                onClick={() => {
                  onLocationChange({
                    lat: '',
                    lng: '',
                    address: '',
                  });
                  setSelected(false);
                }}
                color="danger"
              >
                Delete
              </Button>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
      <div className="p-2 pb-0">
        <div><strong>Lat:</strong> {location?.lat || '-'}</div>
        <div><strong>Lng:</strong> {location?.lng || '-'}</div>
        <div><strong>Address:</strong> {location?.address || '-'}</div>
      </div>
    </div>
  )
}

// eslint-disable-next-line react/prop-types
function Search({ panTo, t }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 40.9831796, lng: () => 29.0823555 },
      radius: 100 * 1000,
    },
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    
    try {
      const results = await getGeocode({ address });
      console.log('results', results);
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <div className="search" style={{ width: '100%' }}>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="form-control"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={t('Search your location')}
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }, idx) => (
                <ComboboxOption key={`${id}-${idx}`} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
function Locate({ panTo, t }) {
  return (
    <button
      className="btn btn-info flex-shrink-0"
      style={{ marginLeft: '1rem' }}
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      {t('Show Own Location')}
    </button>
  );
}


MapContainer.propTypes = {
  t: PropTypes.any,
  location: PropTypes.object,
  initialCenter: PropTypes.object,
  onLocationChange: PropTypes.func
}

export default withTranslation()(MapContainer)