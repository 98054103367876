import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import  { Pagination, PaginationItem, PaginationLink } from 'reactstrap';


import styles from './style.module.scss';

function PaginationComponent({ items, total, pageSize, currentPage, onChange }) {
    const pagesCount = Math.ceil(total / pageSize);

    const handleClick = (e, index) => {
        e.preventDefault();
        onChange(index);
      }

    return (
        <div className={styles.wrapper}>
          <Pagination aria-label="Page navigation">
            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink
                onClick={e => handleClick(e, currentPage - 1)}
                previous
                href="#"
              />
            </PaginationItem>

            {[...Array(pagesCount)].map((page, i) => (
              <PaginationItem active={i === currentPage} key={i}>
                <PaginationLink onClick={e => handleClick(e, i)} href="#">
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink
                onClick={e => handleClick(e, currentPage + 1)}
                next
                href="#"
              />
            </PaginationItem>
          </Pagination>
        </div>
    )
}

PaginationComponent.propTypes = {
  items: PropTypes.array,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onChange: PropTypes.func
};

PaginationComponent.defaultProps = {
  items: [],
  total: 0,
  pageSize: 8,
  currentPage: 0,
  onChange() {}
}

export default PaginationComponent;