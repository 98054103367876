import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { withRouter, Link } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { withTranslation } from 'react-i18next';

import getImage from "helpers/getImage"

import { useSelector, useDispatch } from "react-redux"
import Upload from "./Upload"
import FileManager from "./FileManager"
import downloadImage from "helpers/downloadImage"

function FileUpload({
  onChange,
  multiple,
  t,
  images,
  valueKey,
  errorMessage,
  onDeleteFile,
}) {
  const [modal, setModal] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const toggle = () => {
    setModal(!modal)
  }

  const handleChangeSelectedFiles = (files) => {
    toggle();
    onChange(files)
  }

  return (
    <>
      <Button outline dark onClick={toggle}>{t('File Choose or File Upload')}</Button>
      {errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}
      <div className="dropzone-previews mt-3" id="file-previews">
        {images?.length
          ? images?.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        className="avatar-sm rounded bg-light"
                        alt=""
                        src={getImage({
                          path: f,
                          width: 48,
                          height: 48,
                          fit: "cover",
                        })}
                      />
                      <div className="hstack gap-2">
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => onDeleteFile(f)}
                        >
                          {t("Delete")}
                        </Button>

                        <a
                          className="btn btn-info"
                          href={getImage({ path: f })}
                          target={"_blank"} rel="noreferrer"
                        >
                          {t("Preview")}
                        </a>

                        <a
                          className="btn btn-dark"
                          href={downloadImage({ path: f })}
                        >
                          {t("Download")}
                        </a>
                      </div>
                    </div>
                  </div>
                </Card>
              )
            })
          : null}
      </div>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "80%", maxWidth: "90%" }}>
        <ModalHeader toggle={toggle} tag="h4">
          {t('File Upload')}
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink className={activeTab === "1" ? "active" : ""} onClick={() => setActiveTab("1")}>
                {t('File Manager')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab === "2" ? "active" : ""} onClick={() => setActiveTab("2")}>
                {t('Upload File')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <FileManager multiple={multiple} onChange={handleChangeSelectedFiles} uploadedFiles={images} />
            </TabPane>
            <TabPane tabId="2">
              <Upload />
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </>
  )
}

FileUpload.propTypes = {
  valueKey: PropTypes.string,
  images: PropTypes.any,
  errorMessage: PropTypes.any,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDeleteFile: PropTypes.func,
  t: PropTypes.any,
}

export default withTranslation()(withRouter(FileUpload));