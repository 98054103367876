import { SET_DYNAMIC_PAGES, SET_DYNAMIC_PAGE_DETAIL } from "./actionTypes"

const INIT_STATE = {
  dynamicPages: [],
  dynamicPageDetail: {},
}

const dynamicPage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DYNAMIC_PAGES:
      return {
        ...state,
        dynamicPages: action.payload,
      }

    case SET_DYNAMIC_PAGE_DETAIL:
      return {
        ...state,
        dynamicPageDetail: action.payload,
      }
    default:
      return state
  }
}

export default dynamicPage
