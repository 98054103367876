
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PROJECT_CATEGORIES, GET_PROJECT_CATEGORY_DETAIL , ADD_NEW_PROJECT_CATEGORY , DELETE_PROJECT_CATEGORY, UPDATE_PROJECT_CATEGORY } from "./actionTypes"

import {
  setProjectCategoryDetail,
  setProjectCategories,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../../form/actions"

import { getProjectCategories, getProjectCategoryDetail, deleteProjectCategory, updateProjectCategory, addProjectCategory } from "../../../helpers/backend_helper"


function* fetchProjectCategories({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setProjectCategoryDetail({}))
  try {
    const response = yield call(getProjectCategories, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setProjectCategories(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchProjectCategoryDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getProjectCategoryDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setProjectCategoryDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateProjectCategory({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateProjectCategory, payload)
    yield put(toggleActionLoading(false))
    yield put(setProjectCategoryDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'ProjectCategory updated successfuly.', redirectUrl: '/projects/project-category-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteProjectCategory({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteProjectCategory, id)
    const responsePages = yield call(getProjectCategories, { params })
    yield put(setProjectCategories(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "ProjectCategory deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewProjectCategory({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addProjectCategory, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'ProjectCategory added successfuly.', redirectUrl: '/projects/project-category-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* ProjectCategoriesSaga() {
  yield takeEvery(GET_PROJECT_CATEGORIES, fetchProjectCategories)
  yield takeEvery(GET_PROJECT_CATEGORY_DETAIL, fetchProjectCategoryDetail)
  yield takeEvery(ADD_NEW_PROJECT_CATEGORY, onAddNewProjectCategory)
  yield takeEvery(UPDATE_PROJECT_CATEGORY, onUpdateProjectCategory)
  yield takeEvery(DELETE_PROJECT_CATEGORY, onDeleteProjectCategory)
}

export default ProjectCategoriesSaga
