import {
  GET_ORDER_DETAIL,
  SET_ORDER_DETAIL,
  GET_ORDERS,
  SET_ORDERS,
  ADD_NEW_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER_INLINE,
} from "./actionTypes"

export const getOrders = (params) => ({
  type: GET_ORDERS,
  payload: { params },
})

export const setOrders = payload => ({
  type: SET_ORDERS,
  payload,
})

export const addNewOrder = payload => ({
  type: ADD_NEW_ORDER,
  payload,
})

export const getOrderDetail = (params) => ({
  type: GET_ORDER_DETAIL,
  payload: { params },
})

export const setOrderDetail = payload => ({
  type: SET_ORDER_DETAIL,
  payload,
})


export const updateOrder = payload => ({
  type: UPDATE_ORDER,
  payload,
})


export const deleteOrder = payload => ({
  type: DELETE_ORDER,
  payload,
})

export const updateOrderInline = payload => ({
  type: UPDATE_ORDER_INLINE,
  payload,
})
