
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_HOME_SLIDERS, GET_HOME_SLIDER_DETAIL , ADD_NEW_HOME_SLIDER , DELETE_HOME_SLIDER, UPDATE_HOME_SLIDER } from "./actionTypes"

import {
  setHomeSliderDetail,
  setHomeSliders,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getHomeSliders, getHomeSliderDetail, deleteHomeSlider, updateHomeSlider, addHomeSlider } from "../../helpers/backend_helper"


function* fetchHomeSliders({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setHomeSliderDetail({}))
  try {
    const response = yield call(getHomeSliders, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setHomeSliders(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchHomeSliderDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getHomeSliderDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setHomeSliderDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateHomeSlider({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateHomeSlider, payload)
    yield put(toggleActionLoading(false))
    yield put(setHomeSliderDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'HomeSlider updated successfuly.', redirectUrl: '/home-sliders-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteHomeSlider({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteHomeSlider, id)
    const responsePages = yield call(getHomeSliders, { params })
    yield put(setHomeSliders(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "HomeSlider deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewHomeSlider({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addHomeSlider, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'HomeSlider added successfuly.', redirectUrl: '/home-sliders-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* homeSlidersSaga() {
  yield takeEvery(GET_HOME_SLIDERS, fetchHomeSliders)
  yield takeEvery(GET_HOME_SLIDER_DETAIL, fetchHomeSliderDetail)
  yield takeEvery(ADD_NEW_HOME_SLIDER, onAddNewHomeSlider)
  yield takeEvery(UPDATE_HOME_SLIDER, onUpdateHomeSlider)
  yield takeEvery(DELETE_HOME_SLIDER, onDeleteHomeSlider)
}

export default homeSlidersSaga
