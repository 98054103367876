import React, { useEffect, useState, useRef, useCallback } from 'react';
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Row, Modal, Button, ModalHeader, ModalBody, Form } from 'reactstrap';
import Dropzone from 'react-dropzone';

import { addNewAsset as onAddNewAsset } from 'store/assets/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';
import getImage from 'helpers/getImage';
import convertImage from 'helpers/convertImage';

const Upload = (props) => {
  const dispatch = useDispatch();

  const { assets } = useSelector((state) => ({
    assets: state.assets.assets,
  }));

  const [fileList, setFileList] = useState([]);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );

    setFileList(files);
  }

  const handleValidSubmit = () => {
    const formData = new FormData();

    if (fileList?.length) {
        fileList.forEach((i) => {
        formData.append('files', i);
      });
    }

    dispatch(onAddNewAsset(formData));

    setFileList([]);
  };

  const onDeleteFile = (f) => {
    const newFiles = fileList.filter((f) => f.name !== file.name);
  };

  return (
    <div className="p-4">
      <Dropzone
        multiple={true}
        onDrop={(acceptedFiles) => {
          handleAcceptedFiles(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dz-message needsclick">
                <div className="mb-3">
                  <i className="display-2 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>{props.t('Drop files here or click to upload')}</h4>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {fileList?.length
          ? fileList?.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + '-file'}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="60"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <p className="mb-0">
                          <strong>{f.name}</strong>
                        </p>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col>
                        <Button size='sm' type="button" color="danger" onClick={() => onDeleteFile(f)}>
                          {props.t('Delete')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })
          : null}
      </div>
      <div className='py-4'>
        <Button type="button" color="primary" onClick={handleValidSubmit}>
            {props.t('Add')}
        </Button>
      </div>
    </div>
  );
};

Upload.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(withRouter(Upload));
