import { SET_CHARGING_STATIONS, SET_CHARGING_STATION_DETAIL } from "./actionTypes"

const INIT_STATE = {
  chargingStations: [],
  chargingStationDetail: {},
}

const chargingStation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CHARGING_STATIONS:
      return {
        ...state,
        chargingStations: action.payload,
      }

    case SET_CHARGING_STATION_DETAIL:
      return {
        ...state,
        chargingStationDetail: action.payload,
      }
    default:
      return state
  }
}

export default chargingStation
