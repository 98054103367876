import {
  GET_CALCULATION_DETAIL,
  SET_CALCULATION_DETAIL,
  GET_CALCULATIONS,
  SET_CALCULATIONS,
  ADD_NEW_CALCULATION,
  UPDATE_CALCULATION,
  DELETE_CALCULATION,
} from "./actionTypes"

export const getCalculations = (params) => ({
  type: GET_CALCULATIONS,
  payload: { params },
})

export const setCalculations = payload => ({
  type: SET_CALCULATIONS,
  payload,
})

export const addNewCalculation = payload => ({
  type: ADD_NEW_CALCULATION,
  payload,
})

export const getCalculationDetail = (params) => ({
  type: GET_CALCULATION_DETAIL,
  payload: { params },
})

export const setCalculationDetail = payload => ({
  type: SET_CALCULATION_DETAIL,
  payload,
})


export const updateCalculation = payload => ({
  type: UPDATE_CALCULATION,
  payload,
})


export const deleteCalculation = payload => ({
  type: DELETE_CALCULATION,
  payload,
})


