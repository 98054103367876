import {
  GET_OURVALUE_DETAIL,
  SET_OURVALUE_DETAIL,
  GET_OURVALUES,
  SET_OURVALUES,
  ADD_NEW_OURVALUE,
  UPDATE_OURVALUE,
  DELETE_OURVALUE,
} from "./actionTypes"

export const getOurValues = (params) => ({
  type: GET_OURVALUES,
  payload: { params },
})

export const setOurValues = payload => ({
  type: SET_OURVALUES,
  payload,
})

export const addNewOurValue = payload => ({
  type: ADD_NEW_OURVALUE,
  payload,
})

export const getOurValueDetail = (params) => ({
  type: GET_OURVALUE_DETAIL,
  payload: { params },
})

export const setOurValueDetail = payload => ({
  type: SET_OURVALUE_DETAIL,
  payload,
})


export const updateOurValue = payload => ({
  type: UPDATE_OURVALUE,
  payload,
})


export const deleteOurValue = payload => ({
  type: DELETE_OURVALUE,
  payload,
})


