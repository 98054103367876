//REGISTER
export const POST_REGISTER = "/api/v1/auth/register-for-admin"
export const CONFIRM_MAIL = "/api/v1/auth/confirmemail"
export const POST_PASSWORD_FORGET = "/api/v1/auth/forgotpassword"
export const POST_LOGIN = "/api/v1/auth/login"
export const GET_ME = "/api/v1/auth/me"

export const EDIT_PROFILE = "/api/v1/auth/updatedetails"
export const DASHBOARD_INFO = "/api/v1/dashboard"
export const GLOBAL = "/api/v1/global"
export const LANGUAGES = "/api/v1/languages"
export const USERS = "/api/v1/users"
export const CONTACTS = "/api/v1/contacts"
export const CALCULATIONS = "/api/v1/calculations"
export const DYNAMIC_PAGES = "/api/v1/dynamic-pages"
export const SOLUTION = "/api/v1/solutions"
export const OUR_PROCESS = "/api/v1/our-process"
export const REFERANCE = "/api/v1/referances"
export const SUBSCRIBER = "/api/v1/subscribers"
export const CHARGING_STATION = "/api/v1/charging-stations"
export const SUSTAINABLE = "/api/v1/sustainables"
export const FAQ = "/api/v1/faqs"
export const OUR_VALUES = "/api/v1/our-values"
export const DIRECTORS = "/api/v1/directors"
export const BLOG_POSTS = "/api/v1/blog-posts"
export const CDN = "/cdn"


export const PRODUCTS = "/api/v1/products/products"
export const PRODUCT_CATEGORY = "/api/v1/products/product-categories"
export const PRODUCT_REVIEW = "/api/v1/products/product-reviews"
export const ORDERS = "/api/v1/products/orders"

export const PROJECTS = "/api/v1/projects/projects"
export const PROJECT_CATEGORY = "/api/v1/projects/project-categories"

export const HOME_SLIDERS = "/api/v1/home-sliders"
export const VIDEO_SLIDERS = "/api/v1/video-sliders"

export const DISTRIBUTORSHIP_CATEGORY = "/api/v1/distributorships/distributorship-categories"
export const DISTRIBUTORSHIP = "/api/v1/distributorships/distributorships"