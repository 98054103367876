export default [
    {
        type: 'park',
        name: 'Park Area',
        checked: false,
    },
    {
        type: 'dining',
        name: 'Dining Area',
        checked: false,
    },
    {
        type: 'toilet',
        name: 'Toilet',
        checked: false,
    },
    {
        type: 'accomodation',
        name: 'Accomodation',
        checked: false,
    },
    {
        type: 'washing',
        name: 'Car Washing',
        checked: false,
    },
    {
        type: 'clinic',
        name: 'Clinic',
        checked: false,
    },
]