import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
  Button,
  Col,
  Input,
  Row,
  Label,
  FormFeedback,
} from "reactstrap"

export default function TitleAndListSteps({
  onChange,
  t,
  valueKey,
  errorMessage,
  steps,
}) {
  const [cardField, setCardField] = useState([]);

  useEffect(() => {
    if (steps?.length) {
      setCardField(steps.map((step, idx) => ({
        id: idx + 1,
        title: step.title,
        sort: step.sort,
        content: step.content,
      })))
    } else if (!steps?.length && (!cardField?.length || !cardField?.[0]?.title )) {
      setCardField([]);
    }

  }, [steps]);

  const handleAddRowNested = () => {
    const modifiedCardFields = [...cardField]
    modifiedCardFields.push({
      id: modifiedCardFields.length + 1,
      title: '',
      sort: modifiedCardFields.length + 1,
      content: null,
    })
    setCardField(modifiedCardFields)
  }

  const handleRemoveRow = (id) => {
    if (id !== 1) {
      var modifiedCardFields = [...cardField]
      modifiedCardFields = modifiedCardFields.filter(x => x["id"] !== id)
      setCardField(modifiedCardFields)
      onChange(valueKey, modifiedCardFields.map(t => t.text))
    }
  }


  const handleCardChange = (value, row, key) => {
    const modifiedCardFields = cardField.map((field) => {
      if (field.id === row.id) {
        return {
          ...field,
          [key]: value,
        }
      }
      return field
    })
    setCardField(modifiedCardFields)


    onChange(valueKey, modifiedCardFields)
  }


  const handleDeleteAllRowNested = () => {
    if(confirm(t('Are you sure?'))) {
      setCardField([])
      onChange(valueKey, [])
    }
  }


  return (
    <div className="inner-repeater mb-4">
      <table style={{ width: "100%" }}>
        <tbody>
          {(cardField || []).map((formRow, key) => {
            // const errorFields = errors?.steps ? errors?.steps[key] : {}
            return (
              <tr key={key}>
                <td>
                  <Row className="mb-2">
                    <Col md="10">
                      <Row>
                        <Col md="12" className="mb-2">
                          <Label className="col-form-label">
                            {t('Title')}*
                          </Label>
                          <Input
                            type="text"
                            className="inner form-control"
                            placeholder={t('form_placeholder', { name: t("Title")})}
                            value={steps[key]?.title || ''}
                            // invalid={errorFields?.steps}
                            onChange={(e) => handleCardChange(e.target.value, formRow, 'title')}
                          />
                        {/* {errorFields?.steps && <FormFeedback>{errorFields?.steps}</FormFeedback>} */}
                        </Col>
                        <Col md="12" className="mb-2">
                          <Label className="col-form-label">
                            {t('Sort')}*
                          </Label>
                          <Input
                            type="number"
                            className="inner form-control"
                            placeholder={t('form_placeholder', { name: t("Sort")})}
                            value={steps[key]?.sort || ''}
                            // invalid={errorFields?.steps}
                            onChange={(e) => handleCardChange(Number(e.target.value), formRow, 'sort')}
                          />
                        {/* {errorFields?.steps && <FormFeedback>{errorFields?.steps}</FormFeedback>} */}
                        </Col>
                        <Col md="12" className="mb-2">
                        <Label className="col-form-label">
                          {t('Content')}
                        </Label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={steps[key]?.content || ''}
                            onChange={(event, editor) => {
                              const editorData = editor.getData()
                              // if (id) {
                              //   if (id && data?.content?.charAt(0) === '<') {
                              //     handleCardChange(editorData, formRow, 'content')
                              //   }
                              // } else {
                              // }
                              handleCardChange(editorData, formRow, 'content')
                            }}
                          />
                          {/* {errorFields?.content && <FormFeedback>{errorFields?.content}</FormFeedback>} */}
                        </Col>
                        
                      </Row>
                    </Col>
                    <Col md="2">
                      <Button
                        color="danger"
                        className="btn-block inner"
                        id="unknown-btn"
                        style={{ width: "100%" }}
                        onClick={e => {
                          handleRemoveRow(formRow.id)
                        }}
                      >
                        {t('Delete')}
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Button
        onClick={handleAddRowNested}
        color="success"
        className="mt-1"
      >
        {t('Add Step')}
      </Button>
      {cardField?.length ? (<Button
        onClick={handleDeleteAllRowNested}
        color="danger"
        className="mt-1"
        style={{ marginLeft: '1rem' }}
      >
        {t('Delete All Steps')}
      </Button>) : null}
    </div>
  )
}

TitleAndListSteps.propTypes = {
  valueKey: PropTypes.string,
  steps: PropTypes.any,
  errorMessage: PropTypes.any,
  errors: PropTypes.any,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDeleteFile: PropTypes.func,
  t: PropTypes.any,
}
