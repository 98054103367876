import { SET_CALCULATIONS, SET_CALCULATION_DETAIL } from "./actionTypes"

const INIT_STATE = {
  calculations: [],
  calculationDetail: {},
}

const calculations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CALCULATIONS:
      return {
        ...state,
        calculations: action.payload,
      }

    case SET_CALCULATION_DETAIL:
      return {
        ...state,
        calculationDetail: action.payload,
      }
    default:
      return state
  }
}

export default calculations
