import {
  GET_DISTRIBUTORSHIP_CATEGORY_DETAIL,
  SET_DISTRIBUTORSHIP_CATEGORY_DETAIL,
  GET_DISTRIBUTORSHIP_CATEGORIES,
  SET_DISTRIBUTORSHIP_CATEGORIES,
  ADD_NEW_DISTRIBUTORSHIP_CATEGORY,
  UPDATE_DISTRIBUTORSHIP_CATEGORY,
  DELETE_DISTRIBUTORSHIP_CATEGORY,
} from "./actionTypes"

export const getDistributorshipCategories = (params) => ({
  type: GET_DISTRIBUTORSHIP_CATEGORIES,
  payload: { params },
})

export const setDistributorshipCategories = payload => ({
  type: SET_DISTRIBUTORSHIP_CATEGORIES,
  payload,
})

export const addNewDistributorshipCategory = payload => ({
  type: ADD_NEW_DISTRIBUTORSHIP_CATEGORY,
  payload,
})

export const getDistributorshipCategoryDetail = (params) => ({
  type: GET_DISTRIBUTORSHIP_CATEGORY_DETAIL,
  payload: { params },
})

export const setDistributorshipCategoryDetail = payload => ({
  type: SET_DISTRIBUTORSHIP_CATEGORY_DETAIL,
  payload,
})


export const updateDistributorshipCategory = payload => ({
  type: UPDATE_DISTRIBUTORSHIP_CATEGORY,
  payload,
})


export const deleteDistributorshipCategory = payload => ({
  type: DELETE_DISTRIBUTORSHIP_CATEGORY,
  payload,
})