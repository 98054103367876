import {
  SET_USERS,
  SET_USER_DETAIL,
  GET_USER_DETAIL,
  GET_USERS,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  ADD_NEW_USER_INLINE,
  UPDATE_USER_INLINE,
} from "./actionTypes"


export const getUsers = (params) => ({
  type: GET_USERS,
  payload: { params },
})

export const setUsers = payload => ({
  type: SET_USERS,
  payload,
})

export const addNewUser = payload => ({
  type: ADD_NEW_USER,
  payload,
})

export const addNewUserInline = payload => ({
  type: ADD_NEW_USER_INLINE,
  payload,
})

export const getUsersDetail = (params) => ({
  type: GET_USER_DETAIL,
  payload: { params },
})

export const setUserDetail = payload => ({
  type: SET_USER_DETAIL,
  payload,
})


export const updateUser = payload => ({
  type: UPDATE_USER,
  payload,
})

export const updateUserInline = payload => ({
  type: UPDATE_USER_INLINE,
  payload,
})

export const deleteUser = payload => ({
  type: DELETE_USER,
  payload,
})
