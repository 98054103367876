/* eslint-disable */
import React, { useEffect, useState, useRef, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "components/Common/Breadcrumb";
import RemoteTable from "components/Common/RemoteTable";

import {
  getCalculations as onGetCalculations,
  addNewCalculation as onAddNewCalculation,
  updateCalculation as onUpdateCalculation,
  deleteCalculation as onDeleteCalculation,
} from "store/calculations/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import CreateCalculationForm from "../CreateCalculationForm";

const CalculationsList = props => {
  const dispatch = useDispatch();

  const { calculations } = useSelector(state => ({
    calculations: state.calculations.calculations,
  }));

	const [perPage, setPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState('');
  const [calculationList, setCalculationList] = useState({});
  const [modal, setModal] = useState(false);

  const columns = [
    {
      text: '#',
      formatter: (cellContent, row) => (
        <>
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
            {row?.firm_name?.toUpperCase()?.charAt(0)}
            </span>
          </div>
        </>
      ),
    },
    {
        text: `${props.t("Phone Number")}`,
        dataField: 'phone_number',
    },
    {
        text: `${props.t("Email")}`,
        dataField: 'email',
    },
    {
      text: `${props.t("Related Person")}`,
      dataField: 'related_person',
    },
    {
      text: `${props.t("Menu")}`,
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleCalculationClick(row)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteCalculation(row._id)}
            ></i>
          </Link>
        </div>
      )
    }
  ];

  const handleDeleteCalculation = id => {
    if (id !== undefined && confirm(props.t('Are you sure?'))) {
      const params = {
        page: calculations.page,
        limit: perPage
      };
      if (searchValue) {
        params['title[regex]'] = searchValue
      }
      
      dispatch(onDeleteCalculation({ id, params }));
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleCalculationClick = arg => {
    const calculation = arg;

    setCalculationList({
      _id: calculation._id,
      full_name: calculation.full_name,
      subject: calculation.subject,
      email: calculation.email,
      message: calculation.message,
    });

    toggle();
  };


  useEffect(() => {
    dispatch(onGetCalculations());
  }, []);

  const handleSearch = (inputValue) => {
    const params = {
      limit: perPage,
    }
    if (inputValue) {
      params['email[regex]'] = inputValue;
    }
    setSearchValue(inputValue);
    dispatch(onGetCalculations(params));
  }

  const handleTableChange  = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    if (type == 'sort') return;
    setPerPage(sizePerPage);
    const params = {
      limit: sizePerPage,
      page: page
    }
    dispatch(onGetCalculations(params));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("page_info_list", { name: props.t("Calculation") })} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Calculations")} breadcrumbItem={props.t("page_info_list", { name: props.t("Calculation") })} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <RemoteTable
                    search
                    onSearch={handleSearch}
                    columns={columns}
                    data={calculations?.data || []}
                    page={calculations?.page || 1}
                    sizePerPage={perPage}
                    totalSize={calculations?.total || 0}
                    onTableChange={handleTableChange}
                  />
                </CardBody>
              </Card>
            </Col>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {props.t("Calculation")}
              </ModalHeader>
              <ModalBody>
                <CreateCalculationForm inline id={calculationList?._id} onClose={toggle} />
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CalculationsList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(withRouter(CalculationsList));