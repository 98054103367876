import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import avatar1 from "../../assets/images/users/avatar-1.png"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = (props) => {
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUserData(obj)
    }
  }, [])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{props.t("Welcome Back !")}</h5>
                <p>Dragon CMS</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        {userData && (
          <CardBody className="pt-0">
            <Row>
              <Col sm="12">
                <div className="avatar-md profile-user-wid mb-2">
                  <img
                    src={avatar1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <p className="font-size-15 mb-0 text-truncate">
                  {userData.name}
                </p>
                <p className="text-muted mb-0 font-size-12 text-truncate">{userData.role}</p>
                <div className="mt-2">
                  <Link
                    to="/profile"
                    className="btn btn-primary btn-sm"
                  >
                    {props.t("View Profile")} <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </Col>
            </Row>
          </CardBody>)}
      </Card>
    </React.Fragment>
  )
}

WelcomeComp.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(WelcomeComp)
