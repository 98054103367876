import { SET_LANGUAGES, SET_LANGUAGE_DETAIL } from "./actionTypes"

const INIT_STATE = {
  languages: [],
  languageDetail: {},
}

const language = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      }

    case SET_LANGUAGE_DETAIL:
      return {
        ...state,
        languageDetail: action.payload,
      }

    default:
      return state
  }
}

export default language
