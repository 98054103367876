import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import { Link, useHistory, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"

import {
  addNewLanguage,
  updateLanguage,
  getLanguageDetail as onGetLanguageDetail,
  deleteLanguage as onDeleteLanguage
} from "store/languages/actions"
import {
  getLanguageOptions
} from "store/form/actions"

import { generateValidation } from '../../../validations';

import Breadcrumbs from "components/Common/Breadcrumb"
import FileUpload from "components/FileUpload"

import useForm from "hooks/useForm"

const validationList = ['code', 'name'];

const LanguageCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { languageDetail, loading } = useSelector(state => ({
    loading: state.languages.loading,
    languageDetail: state.languages.languageDetail,
  }))


  useEffect(() => {
    if (id) {
      dispatch(onGetLanguageDetail(id))
    } else {
      setDataForce({
        name: '',
        code: '',
        image: [],
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(languageDetail).length) {
      setDataForce({
        name: languageDetail.name,
        code: languageDetail.code,
        image: languageDetail?.image ? [languageDetail?.image] : [],
      })
    }
  }, [languageDetail])
  

  const {
    handleSubmit,
    handleChange,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations: {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      name: '',
      code: '',
      image: [],
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      image: data.image[0] || '',
      name: data.name,
      code: data.code,
    }

    if (id) {
      dispatch(updateLanguage(params))
    } else {
      dispatch(addNewLanguage(params))
    }

  }


  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm('Are you sure?')) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }



  const handleDelete = () => {
    if(confirm('Are you sure?')) {
      dispatch(onDeleteLanguage(id))
      setTimeout(() => {
        history.push("/language-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_edit", { name: props.t("Language") }) : props.t("page_info_create", { name: props.t("Language") });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Language')} titleLink="/language-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="name"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Name')}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          invalid={errors.name}
                          onChange={handleChange('name')}
                          value={data.name}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Name")})}
                        />
                        {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="code"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Code')}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="code"
                          name="code"
                          type="text"
                          invalid={errors.code}
                          onChange={handleChange('code')}
                          value={data.code}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Code")})}
                        />
                        {errors.code && <FormFeedback>{errors.code}</FormFeedback>}
                      </Col>
                    </FormGroup>           
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t('Image')}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image")(files)}
                          multiple={false}
                          images={data?.image}
                          errorMessage={errors.image}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image')}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Language")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Language")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Language")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

LanguageCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LanguageCreate)