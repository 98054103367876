import {
  GET_FAQ_DETAIL,
  SET_FAQ_DETAIL,
  GET_FAQS,
  SET_FAQS,
  ADD_NEW_FAQ,
  UPDATE_FAQ,
  DELETE_FAQ,
} from "./actionTypes"

export const getFaqs = (params) => ({
  type: GET_FAQS,
  payload: { params },
})

export const setFaqs = payload => ({
  type: SET_FAQS,
  payload,
})

export const addNewFaq = payload => ({
  type: ADD_NEW_FAQ,
  payload,
})

export const getFaqDetail = (params) => ({
  type: GET_FAQ_DETAIL,
  payload: { params },
})

export const setFaqDetail = payload => ({
  type: SET_FAQ_DETAIL,
  payload,
})


export const updateFaq = payload => ({
  type: UPDATE_FAQ,
  payload,
})


export const deleteFaq = payload => ({
  type: DELETE_FAQ,
  payload,
})


