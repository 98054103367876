import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import { useSelector, useDispatch } from "react-redux"

import { getLanguagesWithoutPagination } from "helpers/backend_helper"

import {
  addNewChargingStation,
  updateChargingStation,
  getChargingStationDetail as onGetChargingStationDetail,
  deleteChargingStation as onDeleteChargingStation
} from "store/charging_stations/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import MapContainer from "components/Map/MapContainer"
import FileUpload from "components/FileUpload"
import useForm from "hooks/useForm"
import servicesList from "./servicesList"


const openUsageOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
]

const typeOptions = [
  {label: 'AC', value: 'AC'},
  {label: 'DC', value: 'DC'},
]

const ChargingStationCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const [fetchingLanguages, setFetchingLanguages] = useState(true)
  const [languages, setLanguages] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [selectedOpenUsage, setSelectedOpenUsage] = useState({label: 'No', value: false})
  const [selectedType, setSelectedType] = useState({label: 'AC', value: 'AC'})

  const dispatch = useDispatch()
  const { chargingStations, chargingStationDetail } = useSelector(state => ({
    chargingStations: state.chargingStations.chargingStations,
    chargingStationDetail: state.chargingStations.chargingStationDetail,
  }))

  useEffect(() => {
    getLanguagesWithoutPagination().then((response) => {
      setLanguages((response?.data || []).map((c) => ({ label: c.name, value: c.code })))
      setFetchingLanguages(false)
    }).catch((err) => {
      setFetchingLanguages(false)
      console.log('fetching languages error: ', err)
    })
  }, [])


  useEffect(() => {
    if (id) {
      dispatch(onGetChargingStationDetail(id))
    } else {
      setDataForce({
        name: '',
        language: '',
        description: '',
        power: '',
        ccs2: '',
        opening_time: '',
        closing_time: '',
        open_usage: false,
        type: 'AC',
        image_cover: [],
        services: servicesList.map(i => ({ ...i, name: props.t(i.name) })),
        location: {
          lat: '',
          lng: '',
          address: '',
        }
      })
    }
  }, [id])

  useEffect(() => {
    if (id && Object.keys(chargingStationDetail).length) {
      console.log('chargingStationDetail?.services', chargingStationDetail?.services);
      setDataForce({
        name: chargingStationDetail.name,
        language: chargingStationDetail.language,
        location: chargingStationDetail.location,
        description: chargingStationDetail.description,
        power: chargingStationDetail.power,
        ccs2: chargingStationDetail.ccs2,
        opening_time: chargingStationDetail.opening_time,
        closing_time: chargingStationDetail.closing_time,
        open_usage: chargingStationDetail.open_usage,
        image_cover: chargingStationDetail?.image_cover ? [chargingStationDetail?.image_cover] : [],
        services: chargingStationDetail?.services?.length ? chargingStationDetail.services : servicesList,
        type: chargingStationDetail.type,
      })
      
      if (chargingStationDetail?.open_usage) {
        setSelectedOpenUsage(openUsageOptions.find(st => st.value === chargingStationDetail.open_usage) || null)
      }
      
      if (chargingStationDetail?.type) {
        setSelectedType(typeOptions.find(st => st.value === chargingStationDetail.type) || null)
      }
    }
  }, [chargingStationDetail])
  
  useEffect(() => {
    if (id && languages.length && chargingStationDetail?.language) {
      setSelectedLanguage(languages.find(lan => lan.value === chargingStationDetail.language) || null)
    }
  }, [chargingStationDetail, languages])

  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations: {
      name: { required: { value: true, message: 'Name required.' }},
      language: { required: { value: true, message: 'Language required.' }},
      description: { required: { value: true, message: 'Description required.' }},
      type: { required: { value: true, message: 'Type is required.' }},
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      name: '',
      language: '',
      location: {
        lat: '',
        lng: '',
        address: '',
      },
      image_cover: [],
      services: [...servicesList],
      description: '',
      power: '',
      ccs2: '',
      opening_time: '',
      closing_time: '',
      open_usage: false,
      type: 'AC',
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      name: data.name,
      language: data.language,
      location: data.location,
      description: data.description,
      power: data.power,
      ccs2: data.ccs2,
      opening_time: data.opening_time,
      closing_time: data.closing_time,
      open_usage: data.open_usage,
      type: data.type,
      services: data.services,
      image_cover: data.image_cover[0] || '',
    }

    if (id) {
      dispatch(updateChargingStation(params))
    } else {
      dispatch(addNewChargingStation(params))
    }
  }

  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm(props.t('Are you sure?'))) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }

  const handleServiceChange = (e, idx) => {
    const newServices = [...data.services];
    newServices[idx].checked = e.target.checked;

    console.log('newServices', e.target.checked, newServices);
    handleChange('services')(newServices);

  }


  const handleDelete = () => {
    if(confirm('Are you sure?')) {
      dispatch(onDeleteChargingStation({ id }))
      setTimeout(() => {
        history.push("/charging-station-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Charging Station") }) : props.t("page_info_create", { name: props.t("Charging Station") });

  console.log('data', data);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t("Charging Station")} titleLink="/charging-station-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <Select
                            id="language"
                            name="language"
                            className={errors.language ? 'react-select-error' : ''}
                            value={selectedLanguage}
                            onChange={(e) => {
                              setSelectedLanguage(e)
                              handleChange('language')(e.value)
                            }}
                            options={languages}
                            classNamePrefix="select2-selection"
                            isLoading={fetchingLanguages}
                            placeholder={props.t('Select language...')}
                          />
                          {errors.language && <span className="error-form-field">{errors.language}</span>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="name"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Name')}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          invalid={errors.name}
                          onChange={handleChange('name')}
                          value={data.name}
                          className="form-control"
                          placeholder={props.t('Enter Name...')}
                        />
                        {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="power"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Power')}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="power"
                          name="power"
                          type="text"
                          invalid={errors.power}
                          onChange={handleChange('power')}
                          value={data.power}
                          className="form-control"
                          placeholder={props.t('Enter Power...')}
                        />
                        {errors.power && <FormFeedback>{errors.power}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="ccs2"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('CCS2')}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="ccs2"
                          name="ccs2"
                          type="text"
                          invalid={errors.ccs2}
                          onChange={handleChange('ccs2')}
                          value={data.ccs2}
                          className="form-control"
                          placeholder={props.t('Enter Power...')}
                        />
                        {errors.ccs2 && <FormFeedback>{errors.ccs2}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="type"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Type')}
                      </Label>
                      <Col lg="10">
                        <Select
                            id="type"
                            name="type"
                            className={errors.type ? 'react-select-error' : ''}
                            value={selectedType}
                            onChange={(e) => {
                              setSelectedType(e)
                              handleChange('type')(e.value)
                            }}
                            options={typeOptions}
                            classNamePrefix="select2-selection"
                            placeholder={props.t('Select Type...')}
                          />
                          {errors.type && <span className="error-form-field">{errors.type}</span>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="opening_time"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Opening Time')}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="opening_time"
                          name="opening_time"
                          type="time"
                          invalid={errors.opening_time}
                          onChange={handleChange('opening_time')}
                          value={data.opening_time}
                          className="form-control"
                        />
                        {errors.opening_time && <FormFeedback>{errors.opening_time}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="closing_time"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Closing Time')}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="closing_time"
                          name="closing_time"
                          type="time"
                          invalid={errors.closing_time}
                          onChange={handleChange('closing_time')}
                          value={data.closing_time}
                          className="form-control"
                        />
                        {errors.closing_time && <FormFeedback>{errors.closing_time}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="open_usage"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Open Usage')}
                      </Label>
                      <Col lg="10">
                        <Select
                            id="open_usage"
                            name="open_usage"
                            className={errors.open_usage ? 'react-select-error' : ''}
                            value={selectedOpenUsage}
                            onChange={(e) => {
                              setSelectedOpenUsage(e)
                              handleChange('open_usage')(e.value)
                            }}
                            options={openUsageOptions}
                            classNamePrefix="select2-selection"
                            placeholder={props.t('Select Open Usage...')}
                          />
                          {errors.open_usage && <span className="error-form-field">{errors.open_usage}</span>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Services')}
                      </Label>
                      <Col lg="10">
                        <Row>
                          {servicesList.map((item, idx) => {
                            const currentItem = data.services[idx];
                            return (
                              <Col lg="4" key={idx}>
                                <FormGroup>
                                  <Input
                                    type="checkbox"
                                    id={item.type}
                                    name={item.type}
                                    onChange={(e) => handleServiceChange(e, idx)}
                                    checked={currentItem?.checked}
                                    /> {' '}
                                  <Label check={currentItem?.checked} htmlFor={item.type}>
                                    {props.t(item.name)}
                                  </Label>
                                </FormGroup>
                              </Col>
                            )
                          })}

                        </Row>
                      </Col>
                    </FormGroup>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover")(files)}
                          multiple={false}
                          images={data?.image_cover}
                          errorMessage={errors.image_cover}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover')}
                        />
                      </Col>
                    </Row>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="map"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Map')}
                      </Label>
                      <Col lg="10">
                        <MapContainer
                          initialCenter={data?.location?.lat ? {
                            lat: data?.location?.lat,
                            lng: data?.location?.lng,
                          } : undefined}
                          location={data?.location || null}
                          onLocationChange={(location) => handleChange('location')(location)}
                        />
                      </Col>
                    </FormGroup>
                    
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Description')}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="description"
                          name="description"
                          type="textarea"
                          rows={5}
                          invalid={errors.description}
                          onChange={handleChange('description')}
                          value={data?.description || ''}
                          className="form-control"
                          placeholder={props.t('Enter Description...')}
                        />
                        {errors.description && <FormFeedback>{errors.description}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <hr />       
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                              Edit ChargingStation
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                              Delete ChargingStation
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            Create ChargingStation
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ChargingStationCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ChargingStationCreate)
