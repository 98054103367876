
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_SOLUTIONS, GET_SOLUTION_DETAIL , ADD_NEW_SOLUTION , DELETE_SOLUTION, UPDATE_SOLUTION } from "./actionTypes"

import {
  setSolutionDetail,
  setSolutions,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getSolutions, getSolutionDetail, deleteSolution, updateSolution, addSolution } from "../../helpers/backend_helper"


function* fetchSolutions({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setSolutionDetail({}))
  try {
    const response = yield call(getSolutions, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setSolutions(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchSolutionDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getSolutionDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setSolutionDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateSolution({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateSolution, payload)
    yield put(toggleActionLoading(false))
    yield put(setSolutionDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Solution updated successfuly.', redirectUrl: '/solution-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteSolution({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteSolution, id)
    const responsePages = yield call(getSolutions, { params })
    yield put(setSolutions(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Solution deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewSolution({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addSolution, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Solution added successfuly.', redirectUrl: '/solution-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* solutionsSaga() {
  yield takeEvery(GET_SOLUTIONS, fetchSolutions)
  yield takeEvery(GET_SOLUTION_DETAIL, fetchSolutionDetail)
  yield takeEvery(ADD_NEW_SOLUTION, onAddNewSolution)
  yield takeEvery(UPDATE_SOLUTION, onUpdateSolution)
  yield takeEvery(DELETE_SOLUTION, onDeleteSolution)
}

export default solutionsSaga
