import {
  GET_PRODUCT_CATEGORY_DETAIL,
  SET_PRODUCT_CATEGORY_DETAIL,
  GET_PRODUCT_CATEGORIES,
  SET_PRODUCT_CATEGORIES,
  ADD_NEW_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
} from "./actionTypes"

export const getProductCategories = (params) => ({
  type: GET_PRODUCT_CATEGORIES,
  payload: { params },
})

export const setProductCategories = payload => ({
  type: SET_PRODUCT_CATEGORIES,
  payload,
})

export const addNewProductCategory = payload => ({
  type: ADD_NEW_PRODUCT_CATEGORY,
  payload,
})

export const getProductCategoryDetail = (params) => ({
  type: GET_PRODUCT_CATEGORY_DETAIL,
  payload: { params },
})

export const setProductCategoryDetail = payload => ({
  type: SET_PRODUCT_CATEGORY_DETAIL,
  payload,
})


export const updateProductCategory = payload => ({
  type: UPDATE_PRODUCT_CATEGORY,
  payload,
})


export const deleteProductCategory = payload => ({
  type: DELETE_PRODUCT_CATEGORY,
  payload,
})


