import {
  GET_HOME_SLIDER_DETAIL,
  SET_HOME_SLIDER_DETAIL,
  GET_HOME_SLIDERS,
  SET_HOME_SLIDERS,
  ADD_NEW_HOME_SLIDER,
  UPDATE_HOME_SLIDER,
  DELETE_HOME_SLIDER,
} from "./actionTypes"

export const getHomeSliders = (params) => ({
  type: GET_HOME_SLIDERS,
  payload: { params },
})

export const setHomeSliders = payload => ({
  type: SET_HOME_SLIDERS,
  payload,
})

export const addNewHomeSlider = payload => ({
  type: ADD_NEW_HOME_SLIDER,
  payload,
})

export const getHomeSliderDetail = (params) => ({
  type: GET_HOME_SLIDER_DETAIL,
  payload: { params },
})

export const setHomeSliderDetail = payload => ({
  type: SET_HOME_SLIDER_DETAIL,
  payload,
})


export const updateHomeSlider = payload => ({
  type: UPDATE_HOME_SLIDER,
  payload,
})


export const deleteHomeSlider = payload => ({
  type: DELETE_HOME_SLIDER,
  payload,
})


