/* eslint-disable */
import React, { useEffect, useState, useRef, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  Label,
  UncontrolledTooltip
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "components/Common/Breadcrumb";
import RemoteTable from "components/Common/RemoteTable";
import Pagination from "components/Pagination";

import {
  getAssets as onGetAssets,
  deleteAsset as onDeleteAsset,
} from "store/assets/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import getImage from "helpers/getImage";
import convertImage from "helpers/convertImage";
import downloadImage from "helpers/downloadImage";

const AssetsList = props => {
  const dispatch = useDispatch();

  const { assets } = useSelector(state => ({
    assets: state.assets.assets,
  }));

	const [perPage, setPerPage] = useState(8);
	const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([...props.uploadedFiles]);
  const [modal, setModal] = useState(false);


  const handleChangeItem = (checked, path) => {
    let files = [...selectedFiles];
    if (props.multiple) {
      if (checked) {
        files.push(path)
      } else {
        files.filter(f => f != path);
      }
    } else {
      files = [path];
    }
    setSelectedFiles(files);
  }

  const handleDeleteAsset = path => {
    if (path !== undefined && confirm(props.t('Are you sure?'))) {

      console.log('path', path);
      
      dispatch(onDeleteAsset({ path, params: {} }));
    }
  };


  useEffect(() => {
    dispatch(onGetAssets({ limit: perPage }));
  }, []);

  const handleSearch = () => {
    const params = {
      limit: perPage,
    }
    if (searchValue) {
      params['path[regex]'] = searchValue;
    }

    setCurrentPage(0);
    dispatch(onGetAssets(params));
  }

  const handleChangePage = (page) => {
    const params = {
      limit: perPage,
      page: page + 1
    }
    if (searchValue) {
      params['path[regex]'] = searchValue;
    }
    setCurrentPage(page);
    dispatch(onGetAssets(params));
  }

  return (
    <Container fluid>
      <Row>
          <Col lg={12}>
              <Card className="job-filter">
                  <CardBody>
                    <Row className="g-3">
                        <Col lg={6}>
                            <div className="position-relative">
                                <Input type="text" value={searchValue} className="form-control" id="searchAsset" placeholder={props.t('form_placeholder', { name: props.t('File')})} onChange={(e) => setSearchValue(e.target.value)} />
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="position-relative h-100 hstack gap-3">
                                <button onClick={handleSearch} className="btn btn-primary h-100 w-100"><i className="bx bx-search-alt align-middle"></i> {props.t('Search')}</button>
                                <button onClick={() => props.onChange(selectedFiles)} className="btn btn-secondary h-100 w-100">
                                    <i className="bx bx-filter-alt align-middle"></i> {props.t('Apply')}</button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <p className="my-2 text-end">{props.t('selected_items', { name: selectedFiles.length })}</p>
                      </Col>
                    </Row>
                  </CardBody>
              </Card>
          </Col>
      </Row>
      <Row>
        {assets?.data?.map((asset, key) => {
          const isSelected = selectedFiles.includes(asset.path);
          return (
            <Col xl={4} md={3} xl={2} key={key}>
                <Card>
                    <CardBody style={isSelected ? { background: 'rgba(179, 181, 235, 0.35)' } : {}}>
                      {isSelected &&  (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            position: 'absolute', right: '-10px', top: '-20px',
                            fontSize: 30, background: 'rgba(179, 181, 235, 0.75)',
                            color: '#4449ca',
                            width: 40, height: 40, borderRadius: '100%'
                          }}>
                            <i className="mdi mdi-check"></i>
                        </div>
                      )}
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <img src={getImage({ path: asset.path })} alt="" className="mb-3 rounded avatar-lg" />
                        <p className="col-12 text-truncate">{asset.path}</p>
                        <div className="mt-2 hstack flex-column gap-2">
                          <div className="hstack gap-2">
                          
                            <Link to="#" className="text-dark w-100" id={`select-${key}`} onClick={() => handleChangeItem(!isSelected, asset.path)}>
                              {isSelected ? <i className="mdi mdi-close font-size-18 d-block"></i> : <i className="mdi mdi-check font-size-18 d-block"></i>}
                              <UncontrolledTooltip
                                placement="top"
                                target={`select-${key}`}
                              >
                                {props.t(isSelected ? 'Unselect' : 'Select')}
                              </UncontrolledTooltip>
                            </Link>

                            <a className="text-success w-100" id={`pre-${key}`} href={getImage({ path: asset.path })} target={"_blank"}>
                              <i className="mdi mdi-eye font-size-18 d-block"></i>
                              <UncontrolledTooltip
                                placement="top"
                                target={`pre-${key}`}
                              >
                                {props.t('Preivew')}
                              </UncontrolledTooltip>
                            </a>

                            <Link to="#" className="text-danger w-100" id={`del-${key}`} onClick={() => handleDeleteAsset(asset.path)}>
                              <i className="mdi mdi-delete font-size-18 d-block"></i>
                              <UncontrolledTooltip
                                placement="top"
                                target={`del-${key}`}
                              >
                                {props.t('Delete')}
                              </UncontrolledTooltip>
                            </Link>

                            <a className="text-info w-100" id={`down-${key}`} href={downloadImage({ path: asset.path })}>
                              <i className="mdi mdi-cloud-download font-size-18 d-block"></i>
                              <UncontrolledTooltip
                                placement="top"
                                target={`down-${key}`}
                              >
                                {props.t('Download')}
                              </UncontrolledTooltip>
                            </a>

                          </div>
                        </div>
                      </div>
                    </CardBody>
                </Card>
            </Col>
          )
        })}
      </Row>
      <Row>
        <Pagination
          total={assets.total}
          onChange={handleChangePage}
          currentPage={currentPage}
          pageSize={perPage}
        />
      </Row>
    </Container>
  );
};

AssetsList.propTypes = {
  t: PropTypes.any,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
}

export default withTranslation()(withRouter(AssetsList));