import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

import {
  putProfile,
} from "../../../helpers/backend_helper"


function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(putProfile, {
      name: user.name,
      email: user.email,
    })
    const userData = JSON.parse(localStorage.getItem("authUser"))
    userData.name = response.name
    localStorage.setItem("authUser", JSON.stringify(userData))
    yield put(profileSuccess('The transaction was performed successfully.'))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(profileError(errorMessage))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
