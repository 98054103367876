import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, map } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next"
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import {
    getOrderDetail as onGetOrderDetail
} from "store/products/orders/actions";




const OrderDetail = props => {
  const dispatch = useDispatch();

  const { orderDetail } = useSelector(state => ({
    orderDetail: state.orders.orderDetail,
  }));

  const {
    match: { params },
  } = props;

  console.log('orderDetail', orderDetail);

  // useEffect(() => {
  //   if (params && params.id) {
  //     dispatch(onGetOrderDetail(params.id));
  //   }
  // }, [params, onGetOrderDetail]);

  useEffect(() => {
    dispatch(onGetOrderDetail('63bed78886aa0e6e25541006'));
  }, [])

  //Print the Order
  const printOrder = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
          {props.t('Order Detail')} | Dragon CMS - DaseinSoftware Hub
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t('Order')} breadcrumbItem={props.t('Order Detail')} />
          {!isEmpty(orderDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="Order-title">
                      <h4 className="float-end font-size-16">
                      {props.t('Order')} #{orderDetail._id}
                      </h4>
                    </div>
                    <hr />
                    
                    <Row>
                      <Col xs="6">
                        <address>
                          <h5 className="mb-0">{props.t('Shipping Information')}</h5>
                          <br />
                          <span><strong>{props.t('Country')}: </strong>{orderDetail?.shippingAddress?.country}</span><br />
                          <span><strong>{props.t('City')}: </strong>{orderDetail?.shippingAddress?.city}</span><br />
                          <span><strong>{props.t('Postal Code')}: </strong>{orderDetail?.shippingAddress?.postalCode}</span><br />
                          {/* <span><strong>{props.t('Shipping Price')}: </strong>{orderDetail?.shippingAddress?.shippingPrice || 0}</span><br /> */}
                        </address>
                      </Col>
                      <Col xs="6" className="text-end">
                        <address>
                          <strong>{props.t('Address')}</strong>
                          <br />
                          <span>{orderDetail?.shippingAddress?.address}</span>
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" className="mt-3">
                        <address>
                          <h5 className="mb-0">{props.t('Payment Info')}</h5>
                          <br />
                          <span><strong>{props.t('Recipient Name Surname')}: </strong>{orderDetail?.user?.name}</span>
                          <br />
                          <span><strong>{props.t('Payment Method')}: </strong>{orderDetail?.paymentMethod}</span>
                        </address>
                      </Col>
                      <Col xs="6" className="mt-3 text-end">
                        <address>
                          <strong>{props.t('Order Date')}:</strong>
                          <br />
                          {moment(orderDetail.createdAt).format("DD.MM.YY")}
                          <br />
                          <br />
                        </address>
                      </Col>
                    </Row>
                    <div className="py-2">
                      <h3 className="font-size-15 fw-bold">{props.t('Order Summary')}</h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th>{props.t('Item')}</th>
                            <th>{props.t('Count')}</th>
                            <th className="text-end">{props.t('Price')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(
                            orderDetail.products,
                            (item, key) => (
                              <tr key={key}>
                                <td>{item.product.title}</td>
                                <td>{item.totalProductQuantity}</td>
                                <td className="text-end">{item.totalProductPrice}</td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td colSpan="2" className="text-end">
                              {props.t('Subtotal')}
                            </td>
                            <td className="text-end">
                              {orderDetail.totalPrice} {orderDetail.currency}
                            </td>
                          </tr>
                          {orderDetail.taxPrice ? <tr>
                            <td colSpan="2" className="border-0 text-end">
                              <strong>%{orderDetail.taxPrice} {props.t('Tax Price')}</strong>
                            </td>
                            <td className="border-0 text-end">
                                {orderDetail.taxPrice} {orderDetail.currency}
                            </td>
                          </tr> : null}
                          <tr>
                            <td colSpan="2" className="border-0 text-end">
                              <strong>{props.t('Total Amount')}</strong>
                            </td>
                            <td className="border-0 text-end">
                              <h4 className="m-0">
                              {orderDetail.totalPrice + orderDetail.taxPrice + orderDetail.shippingPrice} {orderDetail.currency} {orderDetail.currency}
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-print-none">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printOrder}
                          className="btn btn-success  me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        <Link to="/order-list" className="btn btn-primary w-md me-2">
                        {props.t('Go Back')}
                        </Link>
                        <Link to={`/order-edit/${params.id}`} className="btn btn-secondary w-md ">
                          {props.t('Edit')}
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

OrderDetail.propTypes = {
  match: PropTypes.any,
  t: PropTypes.any
};

export default withTranslation()(withRouter(OrderDetail))
