import { del, get, getWithoutPagination, post, put, putFormData, postFormData } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

// AUTHENTICATION
export const isUserAuthenticated = () => getLoggedInUser() !== null
export const postRegister = data => post(url.POST_REGISTER, data)
export const confirmEmail = token => get(`${url.CONFIRM_MAIL}?token=${token}`)
export const postForgetPwd = data => post(url.POST_PASSWORD_FORGET, data)
export const postLogin = data => post(url.POST_LOGIN, data)
export const getMeViaToken = token => get(url.GET_ME, { headers: { Authorization: `Bearer ${token}` }});

// PROFILE
export const putProfile = data => put(url.EDIT_PROFILE, data)

// DASHBOARD
export const getDashboardInfo = () => get(url.DASHBOARD_INFO)

// GLOBAL
export const getGlobalDetail = () => get(url.GLOBAL)
export const updateGlobal = params => put(url.GLOBAL, params)

// LANGUAGES
export const getLanguages = params => get(`${url.LANGUAGES}`, params)
export const getLanguagesWithoutPagination = params => getWithoutPagination(`${url.LANGUAGES}`, params)
export const getLanguageDetail = id => get(`${url.LANGUAGES}/${id}`, { params: { id } })
export const addLanguage = params => post(`${url.LANGUAGES}`, params)
export const updateLanguage = params => put(url.LANGUAGES, params)
export const deleteLanguage = id => del(`${url.LANGUAGES}/${id}`, { params: { id } })

// USERS
export const getUsers = params => {
  console.log('sago', params);
  return get(`${url.USERS}`, params)
}
export const getUserDetail = id => get(`${url.USERS}/${id}`, { params: { id } })
export const addUser = params => post(`${url.USERS}`, params)
export const updateUser = params => put(url.USERS, params)
export const deleteUser = id => del(`${url.USERS}/${id}`, { params: { id } })

// CONTACTS
export const getContacts = params => get(`${url.CONTACTS}`, params)
export const getContactDetail = id => get(`${url.CONTACTS}/${id}`, { params: { id } })
export const addContact = params => post(`${url.CONTACTS}`, params)
export const readStatusForContact = () => put(url.CONTACTS)
export const updateContact = params => put(url.CONTACTS, params)
export const deleteContact = id => del(`${url.CONTACTS}/${id}`, { params: { id } })

// CALCULATION
export const getCalculations = params => get(`${url.CALCULATIONS}`, params)
export const getCalculationDetail = id => get(`${url.CALCULATIONS}/${id}`, { params: { id } })
export const addCalculation = params => post(`${url.CALCULATIONS}`, params)
export const readStatusForCalculation = () => put(url.CALCULATIONS)
export const updateCalculation = params => put(url.CALCULATIONS, params)
export const deleteCalculation = id => del(`${url.CALCULATIONS}/${id}`, { params: { id } })

// DYNAMIC PAGE
export const getDynamicPages = params => get(`${url.DYNAMIC_PAGES}`, params)
export const getDynamicPageDetail = id => get(`${url.DYNAMIC_PAGES}/${id}`, { params: { id } })
export const addDynamicPage = params => post(`${url.DYNAMIC_PAGES}`, params)
export const updateDynamicPage = params => put(url.DYNAMIC_PAGES, params)
export const deleteDynamicPage = id => del(`${url.DYNAMIC_PAGES}/${id}`, { params: { id } })

// BLOG POSTS
export const getBlogPosts = params => get(`${url.BLOG_POSTS}`, params)
export const getBlogPostDetail = id => get(`${url.BLOG_POSTS}/${id}`, { params: { id } })
export const addBlogPost = params => post(`${url.BLOG_POSTS}`, params)
export const updateBlogPost = params => put(url.BLOG_POSTS, params)
export const deleteBlogPost = id => del(`${url.BLOG_POSTS}/${id}`, { params: { id } })

// SOLUTIONS
export const getSolutions = params => get(`${url.SOLUTION}`, params)
export const getSolutionDetail = id => get(`${url.SOLUTION}/${id}`, { params: { id } })
export const addSolution = params => post(`${url.SOLUTION}`, params)
export const updateSolution = params => put(url.SOLUTION, params)
export const deleteSolution = id => del(`${url.SOLUTION}/${id}`, { params: { id } })

// OUR PROCESS
export const getOurProcess = params => get(`${url.OUR_PROCESS}`, params)
export const getOurProcessDetail = id => get(`${url.OUR_PROCESS}/${id}`, { params: { id } })
export const addOurProcess = params => post(`${url.OUR_PROCESS}`, params)
export const updateOurProcess = params => put(url.OUR_PROCESS, params)
export const deleteOurProcess = id => del(`${url.OUR_PROCESS}/${id}`, { params: { id } })

// REFERANCES
export const getReferances = params => get(`${url.REFERANCE}`, params)
export const getReferanceDetail = id => get(`${url.REFERANCE}/${id}`, { params: { id } })
export const addReferance = params => post(`${url.REFERANCE}`, params)
export const updateReferance = params => put(url.REFERANCE, params)
export const deleteReferance = id => del(`${url.REFERANCE}/${id}`, { params: { id } })

// SUBSCRIBERS
export const getSubscribers = params => get(`${url.SUBSCRIBER}`, params)
export const getSubscriberDetail = id => get(`${url.SUBSCRIBER}/${id}`, { params: { id } })
export const addSubscriber = params => post(`${url.SUBSCRIBER}`, params)
export const updateSubscriber = params => put(url.SUBSCRIBER, params)
export const deleteSubscriber = id => del(`${url.SUBSCRIBER}/${id}`, { params: { id } })

// SUBSCRIBERS
export const getChargingStations = params => get(`${url.CHARGING_STATION}`, params)
export const getChargingStationDetail = id => get(`${url.CHARGING_STATION}/${id}`, { params: { id } })
export const addChargingStation = params => post(`${url.CHARGING_STATION}`, params)
export const updateChargingStation = params => put(url.CHARGING_STATION, params)
export const deleteChargingStation = id => del(`${url.CHARGING_STATION}/${id}`, { params: { id } })

// FAQ
export const getFaqs = params => get(`${url.FAQ}`, params)
export const getFaqDetail = id => get(`${url.FAQ}/${id}`, { params: { id } })
export const addFaq = params => post(`${url.FAQ}`, params)
export const updateFaq = params => put(url.FAQ, params)
export const deleteFaq = id => del(`${url.FAQ}/${id}`, { params: { id } })

// FAQ
export const getSustainables = params => get(`${url.SUSTAINABLE}`, params)
export const getSustainableDetail = id => get(`${url.SUSTAINABLE}/${id}`, { params: { id } })
export const addSustainable = params => post(`${url.SUSTAINABLE}`, params)
export const updateSustainable = params => put(url.SUSTAINABLE, params)
export const deleteSustainable = id => del(`${url.SUSTAINABLE}/${id}`, { params: { id } })

// OurValue
export const getOurValues = params => get(`${url.OUR_VALUES}`, params)
export const getOurValueDetail = id => get(`${url.OUR_VALUES}/${id}`, { params: { id } })
export const addOurValue = params => post(`${url.OUR_VALUES}`, params)
export const updateOurValue = params => put(url.OUR_VALUES, params)
export const deleteOurValue = id => del(`${url.OUR_VALUES}/${id}`, { params: { id } })

// Director
export const getDirectors = params => get(`${url.DIRECTORS}`, params)
export const getDirectorDetail = id => get(`${url.DIRECTORS}/${id}`, { params: { id } })
export const addDirector = params => post(`${url.DIRECTORS}`, params)
export const updateDirector = params => put(url.DIRECTORS, params)
export const deleteDirector = id => del(`${url.DIRECTORS}/${id}`, { params: { id } })

// ASSETS
export const getAssets = params => get(`${url.CDN}/files`, params)
export const addAsset = params => postFormData(`${url.CDN}/files`, params)
export const deleteAsset = path => del(`${url.CDN}/file${path}`, { params: { } })

// Ecommerce Product
export const getProducts = params => get(`${url.PRODUCTS}`, params)
export const getProductDetail = id => get(`${url.PRODUCTS}/${id}`, { params: { id } })
export const addProduct = params => post(`${url.PRODUCTS}`, params)
export const updateProduct = params => put(url.PRODUCTS, params)
export const deleteProduct = id => del(`${url.PRODUCTS}/${id}`, { params: { id } })

// Ecommerce Product Category
export const getProductCategories = params => get(`${url.PRODUCT_CATEGORY}`, params)
export const getProductCategoryDetail = id => get(`${url.PRODUCT_CATEGORY}/${id}`, { params: { id } })
export const addProductCategory = params => post(`${url.PRODUCT_CATEGORY}`, params)
export const updateProductCategory = params => put(url.PRODUCT_CATEGORY, params)
export const deleteProductCategory = id => del(`${url.PRODUCT_CATEGORY}/${id}`, { params: { id } })
export const getProductCategoriesWithoutPagination = params => getWithoutPagination(`${url.PRODUCT_CATEGORY}`, params)

// Ecommerce Order
export const getOrders = params => get(`${url.ORDERS}`, params)
export const getOrderDetail = id => get(`${url.ORDERS}/${id}`, { params: { id } })
export const addOrder = params => post(`${url.ORDERS}`, params)
export const updateOrder = params => put(url.ORDERS, params)
export const deleteOrder = id => del(`${url.ORDERS}/${id}`, { params: { id } })

// Ecommerce Product Review
export const getProductReviews = params => get(`${url.PRODUCT_REVIEW}`, params)
export const getProductReviewDetail = id => get(`${url.PRODUCT_REVIEW}/${id}`, { params: { id } })
export const addProductReview = params => post(`${url.PRODUCT_REVIEW}`, params)
export const updateProductReview = params => put(url.PRODUCT_REVIEW, params)
export const deleteProductReview = id => del(`${url.PRODUCT_REVIEW}/${id}`, { params: { id } })


// Project
export const getProjects = params => get(`${url.PROJECTS}`, params)
export const getProjectDetail = id => get(`${url.PROJECTS}/${id}`, { params: { id } })
export const addProject = params => post(`${url.PROJECTS}`, params)
export const updateProject = params => put(url.PROJECTS, params)
export const deleteProject = id => del(`${url.PROJECTS}/${id}`, { params: { id } })

// Ecommerce Project Category
export const getProjectCategories = params => get(`${url.PROJECT_CATEGORY}`, params)
export const getProjectCategoryDetail = id => get(`${url.PROJECT_CATEGORY}/${id}`, { params: { id } })
export const addProjectCategory = params => post(`${url.PROJECT_CATEGORY}`, params)
export const updateProjectCategory = params => put(url.PROJECT_CATEGORY, params)
export const deleteProjectCategory = id => del(`${url.PROJECT_CATEGORY}/${id}`, { params: { id } })
export const getProjectCategoriesWithoutPagination = params => getWithoutPagination(`${url.PROJECT_CATEGORY}`, params)

// HOME SLIDER
export const getHomeSliders = params => get(`${url.HOME_SLIDERS}`, params)
export const getHomeSliderDetail = id => get(`${url.HOME_SLIDERS}/${id}`, { params: { id } })
export const addHomeSlider = params => post(`${url.HOME_SLIDERS}`, params)
export const updateHomeSlider = params => put(url.HOME_SLIDERS, params)
export const deleteHomeSlider = id => del(`${url.HOME_SLIDERS}/${id}`, { params: { id } })

// HOME SLIDER
export const getVideoSliders = params => get(`${url.VIDEO_SLIDERS}`, params)
export const getVideoSliderDetail = id => get(`${url.VIDEO_SLIDERS}/${id}`, { params: { id } })
export const addVideoSlider = params => post(`${url.VIDEO_SLIDERS}`, params)
export const updateVideoSlider = params => put(url.VIDEO_SLIDERS, params)
export const deleteVideoSlider = id => del(`${url.VIDEO_SLIDERS}/${id}`, { params: { id } })

// Distributorship Category
export const getDistributorshipCategories = params => get(`${url.DISTRIBUTORSHIP_CATEGORY}`, params)
export const getDistributorshipCategoryDetail = id => get(`${url.DISTRIBUTORSHIP_CATEGORY}/${id}`, { params: { id } })
export const addDistributorshipCategory = params => post(`${url.DISTRIBUTORSHIP_CATEGORY}`, params)
export const updateDistributorshipCategory = params => put(url.DISTRIBUTORSHIP_CATEGORY, params)
export const deleteDistributorshipCategory = id => del(`${url.DISTRIBUTORSHIP_CATEGORY}/${id}`, { params: { id } })
export const getDistributorshipCategoriesWithoutPagination = params => getWithoutPagination(`${url.DISTRIBUTORSHIP_CATEGORY}`, params)

export const getDistributorships = params => get(`${url.DISTRIBUTORSHIP}`, params)
export const getDistributorshipDetail = id => get(`${url.DISTRIBUTORSHIP}/${id}`, { params: { id } })
export const addDistributorship = params => post(`${url.DISTRIBUTORSHIP}`, params)
export const updateDistributorship = params => put(url.DISTRIBUTORSHIP, params)
export const deleteDistributorship = id => del(`${url.DISTRIBUTORSHIP}/${id}`, { params: { id } })