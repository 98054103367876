
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_CALCULATIONS, GET_CALCULATION_DETAIL , ADD_NEW_CALCULATION , DELETE_CALCULATION, UPDATE_CALCULATION } from "./actionTypes"

import {
  setCalculationDetail,
  setCalculations,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getCalculations, getCalculationDetail, deleteCalculation, updateCalculation, addCalculation } from "../../helpers/backend_helper"


function* fetchCalculations({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setCalculationDetail({}))
  try {
    const response = yield call(getCalculations, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setCalculations(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchCalculationDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getCalculationDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setCalculationDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateCalculation({ payload }) {
  const isInline = !!payload.inline;
  delete payload.inline;
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateCalculation, payload)
    yield put(setCalculationDetail(response.data))
    if (isInline) {
      const responseData = yield call(getCalculations, {})
      yield put(setCalculations(responseData))
    }
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Calculation updated successfuly.', redirectUrl: isInline ? null : '/calculation-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteCalculation({ payload: { id, isInline } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteCalculation, id)
    const responsePages = yield call(getCalculations, {})
    yield put(setCalculations(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Calculation deleted successfuly.", redirectUrl: isInline ? null : '/calculation-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewCalculation({ payload }) {
  const isInline = !!payload.inline;
  delete payload.inline;
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addCalculation, payload)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Calculation added successfuly.', redirectUrl: isInline ? null : '/calculation-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* calculationsSaga() {
  yield takeEvery(GET_CALCULATIONS, fetchCalculations)
  yield takeEvery(GET_CALCULATION_DETAIL, fetchCalculationDetail)
  yield takeEvery(ADD_NEW_CALCULATION, onAddNewCalculation)
  yield takeEvery(UPDATE_CALCULATION, onUpdateCalculation)
  yield takeEvery(DELETE_CALCULATION, onDeleteCalculation)
}

export default calculationsSaga
