import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import useForm from "hooks/useForm"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Alert,
  Button,
  CardTitle,
  Form, FormGroup, Input, Label, FormFeedback
} from "reactstrap";
import toastr from "toastr"

import { generateValidation } from '../../../validations';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  addNewUser as onAddNewUser,
} from "store/users/actions";

//redux
import { useSelector, useDispatch } from "react-redux";


const CreateUser = props => {
  const dispatch = useDispatch();
  const { users } = useSelector(state => ({
    users: state.users.users,
  }));


  const validationList = ['email', 'name', 'password'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      email: '',
      name: '',
      password: '',
    },
  })

  const handleValidSubmit = () => {
    dispatch(onAddNewUser(data))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("page_info_create", { name: props.t("User") })} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('User')} titleLink="/user-list" breadcrumbItem={props.t("page_info_create", { name: props.t("User") })} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t("page_info_create", { name: props.t("User") })}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="email"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Email")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          invalid={errors.email}
                          onChange={handleChange('email')}
                          value={data.email}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Email")})}
                        />
                        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="name"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Name")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          invalid={errors.name}
                          onChange={handleChange('name')}
                          value={data.name}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Name")})}
                        />
                        {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="Password"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Password")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          invalid={errors.password}
                          onChange={handleChange('password')}
                          value={data.password}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Password")})}
                        />
                        {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                          {props.t("Save")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
};

CreateUser.propTypes = {
  t: PropTypes.any,
  inPopup: PropTypes.bool,
}

export default withTranslation()(CreateUser)