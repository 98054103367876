import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../../validations';

import {
  addNewDistributorship,
  updateDistributorship,
  getDistributorshipDetail as onGetDistributorshipDetail,
  deleteDistributorship as onDeleteDistributorship
} from "store/distributorship/actions"

import {
  getDistributorshipCategoryOptions,
} from "store/form/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import FileUpload from "components/FileUpload"
import TagInput from "components/TagInput"
import CategorySelect from "components/CategorySelect"
import LanguageSelect from "components/LanguageSelect"
import StateSelect from "components/StateSelect"


const DistributorshipCreateOrEdit = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { distributorships, distributorshipDetail, formOptions } = useSelector(state => ({
    distributorships: state.distributorships.distributorships,
    distributorshipDetail: state.distributorships.distributorshipDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    dispatch(getDistributorshipCategoryOptions())
    if (id) {
      dispatch(onGetDistributorshipDetail(id))
    } else {
      setDataForce({
        language: '',
        title: '',
        short_title: '',
        meta_title: '',
        meta_description: '',
        content: '',
        video_urls: [],
        logo_image: [],
        image_cover: [],
        images: [],
        category: null,
        state: 'published',
        type: 'distributorship',
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(distributorshipDetail).length) {
      setDataForce({
        language: distributorshipDetail.language,
        title: distributorshipDetail.title,
        short_title: distributorshipDetail.short_title,
        meta_title: distributorshipDetail.meta_title,
        meta_description: distributorshipDetail.meta_description,
        content: distributorshipDetail.content,
        video_urls: distributorshipDetail?.video_urls || [],
        logo_image: distributorshipDetail?.logo_image ? [distributorshipDetail?.logo_image] : [],
        image_cover: distributorshipDetail?.image_cover ? [distributorshipDetail?.image_cover] : [],
        images: distributorshipDetail?.images || [],
        category: distributorshipDetail.category?.['_id'] || null,
        state: distributorshipDetail.state,
        type: distributorshipDetail.type,
      })
    }
  }, [distributorshipDetail])

  useEffect(() => {
    setDataForce({
      ...data,
      properties: formOptions?.distributorshipPropertyOptions || []
    })
  }, [formOptions.distributorshipPropertyOptions])
  

  const validationList = ['title', 'language','short_title','meta_title','meta_description','state', 'type', 'content'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      title: '',
      short_title: '',
      meta_title: '',
      meta_description: '',
      content: '',
      video_urls: [],
      logo_image: [],
      image_cover: [],
      images: [],
      category: null,
      state: 'published',
      type: 'distributorship'
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      title: data.title,
      language: data.language,
      short_title: data.short_title,
      video_urls: data.video_urls,
      meta_title: data.meta_title,
      meta_description: data.meta_description,
      content: data.content,
      state: data.state,
      type: data.type,
      logo_image: data.logo_image[0] || '',
      image_cover: data.image_cover[0] || '',
      images: data.images,
      category: data.category,
    }

    if (id) {
      dispatch(updateDistributorship(params))
    } else {
      dispatch(addNewDistributorship(params))
    }

  }


  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm(props.t('Are you sure?'))) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteDistributorship({ id }))
      setTimeout(() => {
        history.push("/distributorships/distributorship-list")
      }, 300)
    }
  }

  const handleChangeCategory = (key, value) => {
    handleChange(key)(value);
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Distributorship") }) : props.t("page_info_create", { name: props.t("Distributorship") });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Distributorship')} titleLink="/distributorships/distributorship-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          invalid={errors.title}
                          onChange={handleChange('title')}
                          value={data.title}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Title")})}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="category"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Category")}
                      </Label>
                      <Col lg="10">
                        <CategorySelect
                          t={props.t}
                          value={data?.category}
                          valueKey="category"
                          errorMessage={errors.category}
                          onChange={handleChangeCategory}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("State")}
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          t={props.t}
                          value={data?.state}
                          valueKey="state"
                          onChange={(key, value) => handleChange(key)(value)}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="short_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Short Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="short_title"
                          name="short_title"
                          type="text"
                          invalid={errors.short_title}
                          onChange={handleChange('short_title')}
                          value={data?.short_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Short Title")})}
                        />
                        {errors.short_title && <FormFeedback>{errors.short_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="meta_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Meta Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="meta_title"
                          name="meta_title"
                          type="text"
                          invalid={errors.meta_title}
                          onChange={handleChange('meta_title')}
                          value={data?.meta_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Meta Title")})}
                        />
                        {errors.meta_title && <FormFeedback>{errors.meta_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="meta_description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Meta Description")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="meta_description"
                          name="meta_description"
                          type="text"
                          invalid={errors.meta_description}
                          onChange={handleChange('meta_description')}
                          value={data?.meta_description || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Meta Description")})}
                        />
                        {errors.meta_description && <FormFeedback>{errors.meta_description}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="content"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Content")}
                      </Label>
                      <Col lg="10">
                        <CKEditor
                          editor={ClassicEditor}
                          data={data.content}
                          onChange={(event, editor) => {
                            const editorData = editor.getData()
                            if (id) {
                              if (id && data?.content?.charAt(0) === '<') {
                                handleChange('content')(editorData)
                              }
                            } else {
                              handleChange('content')(editorData)
                            }
                          }}
                        />
                        {errors.content && <span className="error-form-field">{errors.content}</span>}
                      </Col>
                    </FormGroup>  
                    <FormGroup className="mb-4" row>
                      <Label
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Video URLs")}
                      </Label>
                      <Col lg="10">
                        <TagInput
                          t={props.t}
                          tags={data?.video_urls || []}
                          errorMessage={errors.video_urls}
                          valueKey="video_urls"
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup> 
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Icon")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("logo_image")(files)}
                          multiple={false}
                          images={data?.logo_image}
                          errorMessage={errors.logo_image}
                          valueKey="logo_image"
                          onDeleteFile={(f) => handleDeleteFile(f, 'logo_image')}
                        />
                      </Col>
                    </Row>                
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover")(files)}
                          multiple={false}
                          images={data?.image_cover}
                          errorMessage={errors.image_cover}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover')}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Images")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("images")(files)}
                          multiple
                          images={data?.images}
                          errorMessage={errors.images}
                          valueKey="images"
                          onDeleteFile={(f) => handleDeleteFile(f, 'images')}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Dynamic Page")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Dynamic Page")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Dynamic Page")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DistributorshipCreateOrEdit.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DistributorshipCreateOrEdit)
