import { call, put, takeEvery } from "redux-saga/effects"

import { GET_LANGUAGE_OPTIONS, GET_PRODUCT_CATEGORY_OPTIONS, GET_PROJECT_CATEGORY_OPTIONS, GET_PRODUCT_PROPERTIES_OPTIONS, GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS } from "./actionTypes"

import {
  getLanguageOptionsSuccess,
  getLanguageOptionsFail,
  
  getProductCategoryOptionsSuccess,
  getProductCategoryOptionsFail,
  
  getProjectCategoryOptionsSuccess,
  getProjectCategoryOptionsFail,

  getProductPropertyOptionsSuccess,
  getProductPropertyOptionsFail,

  getDistributorshipCategoryOptionsSuccess,
  getDistributorshipCategoryOptionsFail,

  toggleFetchingLoading,
} from "./actions"

import {
  getLanguagesWithoutPagination,
  getProductCategoriesWithoutPagination,
  getProjectCategoriesWithoutPagination,
  getDistributorshipCategoriesWithoutPagination,
  getProductCategoryDetail,
} from "../../helpers/backend_helper"


function* fetchLanguageOptions({ payload: {params} }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getLanguagesWithoutPagination, { params })
    const data = (response?.data || []).map((c) => ({ label: c.name, value: c.code }));
    yield put(getLanguageOptionsSuccess(data))
    yield put(toggleFetchingLoading(false))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(getLanguageOptionsFail(errorMessage))
  }
}


function* fetchProjectCategoryOptions({ payload: {params} }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getProjectCategoriesWithoutPagination, { params })

    const data = (response?.data || []).map((c) => ({ label: c.title, value: c._id }));
    yield put(getProjectCategoryOptionsSuccess(data))
    yield put(toggleFetchingLoading(false))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(getProjectCategoryOptionsFail(errorMessage))
  }
}

function* fetchProductCategoryOptions({ payload: {params} }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getProductCategoriesWithoutPagination, { params })

    const data = (response?.data || []).map((c) => ({ label: c.title, value: c._id }));
    yield put(getProductCategoryOptionsSuccess(data))
    yield put(toggleFetchingLoading(false))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(getProductCategoryOptionsFail(errorMessage))
  }
}

function* fetchProductPropertyOptions({ payload: {params} }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getProductCategoryDetail, params)
    const data = (response?.data?.properties || []).map((c) => ({ label: c.name, value: null, input: c.input }));
    yield put(getProductPropertyOptionsSuccess(data))
    yield put(toggleFetchingLoading(false))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(getProductPropertyOptionsFail(errorMessage))
  }
}


function* fetchDistributorshipCategoryOptions({ payload: {params} }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getDistributorshipCategoriesWithoutPagination, { params })

    const data = (response?.data || []).map((c) => ({ label: c.title, value: c._id }));
    yield put(getDistributorshipCategoryOptionsSuccess(data))
    yield put(toggleFetchingLoading(false))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(getDistributorshipCategoryOptionsFail(errorMessage))
  }
}

function* dynamicPageSaga() {
  yield takeEvery(GET_LANGUAGE_OPTIONS, fetchLanguageOptions)
  yield takeEvery(GET_PRODUCT_CATEGORY_OPTIONS, fetchProductCategoryOptions)
  yield takeEvery(GET_PROJECT_CATEGORY_OPTIONS, fetchProjectCategoryOptions)
  yield takeEvery(GET_PRODUCT_PROPERTIES_OPTIONS, fetchProductPropertyOptions)
  yield takeEvery(GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS, fetchDistributorshipCategoryOptions)
}

export default dynamicPageSaga
