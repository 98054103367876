import {

  GET_LANGUAGE_OPTIONS_SUCCESS,
  GET_LANGUAGE_OPTIONS_FAIL,
  
  GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS,
  GET_PRODUCT_CATEGORY_OPTIONS_FAIL,
  
  GET_PROJECT_CATEGORY_OPTIONS_SUCCESS,
  GET_PROJECT_CATEGORY_OPTIONS_FAIL,

  GET_PRODUCT_PROPERTIES_OPTIONS_SUCCESS,
  GET_PRODUCT_PROPERTIES_OPTIONS_FAIL,

  GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_SUCCESS,
  GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_FAIL,
  
  TOOGLE_FETCHING_LOADING,
  SHOW_NOTIFICATION,
  RESET_NOTIFICATION,
  TOGGLE_ACTION_LOADING,
} from "./actionTypes"

const INIT_STATE = {
  stateOptions: [
    {label: 'Published', value: 'published'},
    {label: 'Draft', value: 'draft'}
  ],
  blogFormTypeOptions: [
    {label: 'Blog', value: 'blog'},
    {label: 'News', value: 'news'}
  ],
  distributorshipFormTypeOptions: [
    {label: 'Distributorship', value: 'distributorship'},
    {label: 'OtherProduct', value: 'otherproduct'}
  ],
  languageOptions: [],
  categoryOptions: [],
  productPropertyOptions: [],
  fetchingLoading: false,
  actionLoading: false,
  notification: null,
}

const form = (state = INIT_STATE, action) => {
  switch (action.type) {
    

    case GET_LANGUAGE_OPTIONS_SUCCESS:
      return {
        ...state,
        languageOptions: action.payload,
      }

    case GET_LANGUAGE_OPTIONS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }
    
    case GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        categoryOptions: action.payload,
      }

    case GET_PRODUCT_CATEGORY_OPTIONS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }

    case GET_PROJECT_CATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        categoryOptions: action.payload,
      }

    case GET_PROJECT_CATEGORY_OPTIONS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }

    case GET_PRODUCT_PROPERTIES_OPTIONS_SUCCESS:
      return {
        ...state,
        productPropertyOptions: action.payload,
      }

    case GET_PRODUCT_PROPERTIES_OPTIONS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }

    case GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        categoryOptions: action.payload,
      }

    case GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }

    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      }
    
    case RESET_NOTIFICATION:
      return {
        ...state,
        notification: null,
      }

    case TOGGLE_ACTION_LOADING:
      return {
        ...state,
        actionLoading: action.payload,
      }

    case TOOGLE_FETCHING_LOADING:
      return {
        ...state,
        fetchingLoading: action.payload,
      }

    default:
      return state
  }
}

export default form
