import { SET_ASSETS, SET_ASSET_DETAIL } from "./actionTypes"

const INIT_STATE = {
  assets: [],
}

const asset = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ASSETS:
      return {
        ...state,
        assets: action.payload,
      }
    default:
      return state
  }
}

export default asset
