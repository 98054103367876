
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_ORDERS, GET_ORDER_DETAIL , ADD_NEW_ORDER , DELETE_ORDER, UPDATE_ORDER, UPDATE_ORDER_INLINE } from "./actionTypes"

import {
  setOrderDetail,
  setOrders,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../../form/actions"

import { getOrders, getOrderDetail, deleteOrder, updateOrder, addOrder } from "../../../helpers/backend_helper"


function* fetchOrders({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setOrderDetail({}))
  try {
    const response = yield call(getOrders, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setOrders(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchOrderDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getOrderDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setOrderDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateOrder({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateOrder, payload)
    yield put(toggleActionLoading(false))
    yield put(setOrderDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Order updated successfuly.', redirectUrl: '/products/order-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteOrder({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteOrder, id)
    const responsePages = yield call(getOrders, { params })
    yield put(setOrders(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Order deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewOrder({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addOrder, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Order added successfuly.', redirectUrl: '/products/order-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* onUpdateOrderInline({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateOrder, user)
    const responseDatas = yield call(getOrders, {})
    yield put(toggleActionLoading(false))
    yield put(setOrders(responseDatas))
    yield put(setOrderDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Order updated successfuly.' }))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}


function* OrdersSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(GET_ORDER_DETAIL, fetchOrderDetail)
  yield takeEvery(ADD_NEW_ORDER, onAddNewOrder)
  yield takeEvery(UPDATE_ORDER, onUpdateOrder)
  yield takeEvery(DELETE_ORDER, onDeleteOrder)
  yield takeEvery(UPDATE_ORDER_INLINE, onUpdateOrderInline)
}

export default OrdersSaga
