
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_DISTRIBUTORSHIPS, GET_DISTRIBUTORSHIP_DETAIL , ADD_NEW_DISTRIBUTORSHIP , DELETE_DISTRIBUTORSHIP, UPDATE_DISTRIBUTORSHIP } from "./actionTypes"

import {
  setDistributorshipDetail,
  setDistributorships,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../../form/actions"

import { getDistributorships, getDistributorshipDetail, deleteDistributorship, updateDistributorship, addDistributorship } from "../../../helpers/backend_helper"


function* fetchDistributorship({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setDistributorshipDetail({}))
  try {
    const response = yield call(getDistributorships, { params })

    console.log('response', response);
    yield put(toggleFetchingLoading(false))
    yield put(setDistributorships(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchDistributorshipDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getDistributorshipDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setDistributorshipDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateDistributorship({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateDistributorship, payload)
    yield put(toggleActionLoading(false))
    yield put(setDistributorshipDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Distributorship updated successfuly.', redirectUrl: '/distributorships/distributorship-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* ondeleteDistributorship({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteDistributorship, id)
    const responsePages = yield call(getDistributorships, { params })
    yield put(setDistributorships(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Distributorship deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewDistributorship({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addDistributorship, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Distributorship added successfuly.', redirectUrl: '/distributorships/distributorship-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* distributorshipSaga() {
  yield takeEvery(GET_DISTRIBUTORSHIPS, fetchDistributorship)
  yield takeEvery(GET_DISTRIBUTORSHIP_DETAIL, fetchDistributorshipDetail)
  yield takeEvery(ADD_NEW_DISTRIBUTORSHIP, onAddNewDistributorship)
  yield takeEvery(UPDATE_DISTRIBUTORSHIP, onUpdateDistributorship)
  yield takeEvery(DELETE_DISTRIBUTORSHIP, ondeleteDistributorship)
}

export default distributorshipSaga
