/* eslint-disable */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import RemoteTable from "components/Common/RemoteTable";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  getOrders as onGetOrders,
  deleteOrder as onDeleteOrder,
  updateOrderInline as onUpdateOrderInline,
} from "store/products/orders/actions";

//redux
import { useSelector, useDispatch } from "react-redux";


const OrdersList = props => {
  const dispatch = useDispatch();

  const { orders, loading } = useSelector(state => ({
    orders: state.orders.orders,
    loading: state.orders.loading,
  }));

	const [perPage, setPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState('');
  const [userList, setOrderList] = useState({});
  const [modal, setModal] = useState(false);

  const badgeColor = {
    'Cancelled': 'badge-soft-danger',
    'Processing': 'badge-soft-dark',
    'Delivered': 'badge-soft-success',
    'Not Processed': 'badge-soft-info',
    'Shipped': 'badge-soft-info',
  }

  const toggle = () => {
    setModal(!modal);
  };

  const handleOrderClick = arg => {
    setOrderList({
      ...arg
    });

    toggle();
  };

  const columns = [
    {
      text: `${props.t('Product Count')}`,
      formatter: (cellContent, row) => (
        <>
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
            {row.products?.length || 0}
            </span>
          </div>
        </>
      ),
    },
    {
      text: `${props.t("User")}`,
      formatter: (cellContent, row) => row.user?.name || '-'
    },
    {
      text: 'Total Amount',
      formatter: (cellContent, row) => (
        <div>
          <span>{row.totalPrice}</span>
        </div>
      ),
    },
    {
      text: 'Payment Method',
      formatter: (cellContent, row) => (
        <div>
          <span>{row.paymentMethod}</span>
        </div>
      ),
    },
    {
      text: "Status",
      formatter: (cellContent, row) => (
        <span
          to="#"
          className={`badge ${badgeColor[row?.status]} font-size-11 m-1`}
        >
          {row?.status?.toUpperCase()}
        </span>
      ),
    },
    {
      text: `${props.t("Menu")}`,
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-primary" to={`/projects/order-detail/${row._id}`}>
            <i
              className="mdi mdi-cloud-print-outline font-size-18"
              id="edittooltip"
            ></i>
            cem
          </Link>
          <Link className="text-success" to="#">
            <i
              onClick={() => handleOrderClick(row)}
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteOrder(row._id)}
            ></i>
          </Link>
        </div>
      )
    }
  ];

  const handleDeleteOrder = id => {
    if (id !== undefined && confirm('Are you sure?')) {
      const params = {
        page: orders.page,
        limit: perPage
      };
      if (searchValue) {
        params['title[regex]'] = searchValue
      }
      
      dispatch(onDeleteOrder({ id, params }));
    }
  };

  useEffect(() => {
    dispatch(onGetOrders());
  }, []);

  
  const handleSearch = (inputValue) => {
    const params = {
      limit: perPage,
    }
    if (inputValue) {
      params['code[regex]'] = inputValue;
    }
    setSearchValue(inputValue);
    dispatch(onGetOrders(params));
  }


  const handleTableChange  = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    if (type == 'sort') return;
    setPerPage(sizePerPage);
    const params = {
      limit: sizePerPage,
      page: page
    }
    dispatch(onGetOrders(params));
  }

  const handleValidOrderSubmit = (e, values) => {
    const updateOrder = {
      _id: values._id,
      status: values.statuss,
    };

    dispatch(onUpdateOrderInline(updateOrder));

    toggle();
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("page_info_list", { name: props.t("Order") })} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t('Orders')} breadcrumbItem={props.t("page_info_list", { name: props.t("Order") })} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <RemoteTable
                    search
                    onSearch={handleSearch}
                    columns={columns}
                    data={orders?.data || []}
                    page={orders?.page || 1}
                    sizePerPage={perPage}
                    totalSize={orders?.total || 0}
                    onTableChange={handleTableChange}
                  />
                </CardBody>
              </Card>
            </Col>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {props.t("edit_btn_for_model", { name: props.t("Order") })}
              </ModalHeader>
              <ModalBody>
                <AvForm
                  onValidSubmit={handleValidOrderSubmit}
                >
                  <Row form>
                    <Col xs={12}>
                    <div className="mb-3">
                        <AvField
                          name="_id"
                          label="ID"
                          type="text"
                          disabled
                          value={userList._id || ""}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                    <div className="mb-3">
                        <AvField
                          name="statuss"
                          label={props.t('Status')}
                          type="select"
                          errorMessage={props.t("form_invalid_error_message", { name: props.t("Status") })}
                          validate={{
                            required: { value: true },
                          }}
                          value={userList.status || ""}
                        >
                          {Object.keys(badgeColor).map((s, idx) => <option key={idx}>{s}</option>)}
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          {props.t('Save')}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

OrdersList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(withRouter(OrdersList))
