import React, { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { Container, Row, Col, Spinner } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
//Import Images
import error from "../../assets/images/error-img.png"
import { confirmEmail } from "helpers/backend_helper"

const MailConfirm = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(true)
  const history = useHistory()
  const { token } = useParams()
  useEffect(() => {
    if (!token) {
      history.push('/dashboard')
      return;
    }
    confirmEmail(token).then((response) => {
      if(response.user) {
        setLoading(false);
      }
    }).catch((err) => {
      console.log('err', err);
      history.push('/500')
    })
  }, [])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>{props.t('Mail Confirm')} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container>
          {loading ? (
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <Spinner />
                </div>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col lg="12">
                  <div className="text-center mb-5">
                    <h1 className="display-2 font-weight-medium">
                      200
                    </h1>
                    <h4 className="text-uppercase">
                      {props.t('Mail confirmed successfully')}
                    </h4>
                    <div className="mt-5 text-center">
                      <Link
                        className="btn btn-primary "
                        to="/dashboard"
                      >
                        {props.t('Back to Dashboard')}
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="8" xl="6">
                  <div>
                    <img src={error} alt="" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

MailConfirm.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(MailConfirm)
