
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_FAQS, GET_FAQ_DETAIL , ADD_NEW_FAQ , DELETE_FAQ, UPDATE_FAQ } from "./actionTypes"

import {
  setFaqDetail,
  setFaqs,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getFaqs, getFaqDetail, deleteFaq, updateFaq, addFaq } from "../../helpers/backend_helper"


function* fetchFaqs({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setFaqDetail({}))
  try {
    const response = yield call(getFaqs, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setFaqs(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchFaqDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getFaqDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setFaqDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateFaq({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateFaq, payload)
    yield put(toggleActionLoading(false))
    yield put(setFaqDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Faq updated successfuly.', redirectUrl: '/faq-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteFaq({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteFaq, id)
    const responsePages = yield call(getFaqs, { params })
    yield put(setFaqs(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Faq deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewFaq({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addFaq, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Faq added successfuly.', redirectUrl: '/faq-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* faqsSaga() {
  yield takeEvery(GET_FAQS, fetchFaqs)
  yield takeEvery(GET_FAQ_DETAIL, fetchFaqDetail)
  yield takeEvery(ADD_NEW_FAQ, onAddNewFaq)
  yield takeEvery(UPDATE_FAQ, onUpdateFaq)
  yield takeEvery(DELETE_FAQ, onDeleteFaq)
}

export default faqsSaga
