import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"


import form from "./form/reducer"
import languages from "./languages/reducer"
import global from "./global/reducer"
import contacts from "./contacts/reducer"
import calculations from "./calculations/reducer"
import users from "./users/reducer"
import dynamicPages from "./dynamic_pages/reducer"
import blogPosts from "./blog_posts/reducer"
import ourProcess from "./our_process/reducer"
import solutions from "./solutions/reducer"
import referances from "./referances/reducer"
import subscribers from "./subscribers/reducer"
import chargingStations from "./charging_stations/reducer"
import directors from "./directors/reducer"
import faqs from "./faqs/reducer"
import sustainables from "./sustainables/reducer"
import ourValues from "./our_values/reducer"
import assets from "./assets/reducer"
import productCategories from "./products/product_categories/reducer"
import products from "./products/products/reducer"
import orders from "./products/orders/reducer"
import homeSliders from "./home_sliders/reducer"
import videoSliders from "./video_sliders/reducer"

import projectCategories from "./projects/project_categories/reducer"
import projects from "./projects/projects/reducer"

import distributorshipCategories from "./distributorships/distributorship_categories/reducer"
import distributorships from "./distributorships/distributorship/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  form,
  languages,
  global,
  contacts,
  calculations,
  users,
  dynamicPages,
  blogPosts,
  ourProcess,
  solutions,
  referances,
  subscribers,
  chargingStations,
  directors,
  faqs,
  sustainables,
  ourValues,
  assets,
  products,
  orders,
  productCategories,
  projects,
  projectCategories,
  homeSliders,
  videoSliders,
  distributorships,
  distributorshipCategories,
})

export default rootReducer
