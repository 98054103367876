import { SET_SUBSCRIBERS, SET_SUBSCRIBER_DETAIL } from "./actionTypes"

const INIT_STATE = {
  subscribers: [],
  subscriberDetail: {},
}

const subscriber = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SUBSCRIBERS:
      return {
        ...state,
        subscribers: action.payload,
      }

    case SET_SUBSCRIBER_DETAIL:
      return {
        ...state,
        subscriberDetail: action.payload,
      }
    default:
      return state
  }
}

export default subscriber
