import {
  GET_DIRECTOR_DETAIL,
  SET_DIRECTOR_DETAIL,
  GET_DIRECTORS,
  SET_DIRECTORS,
  ADD_NEW_DIRECTOR,
  UPDATE_DIRECTOR,
  DELETE_DIRECTOR,
} from "./actionTypes"

export const getDirectors = (params) => ({
  type: GET_DIRECTORS,
  payload: { params },
})

export const setDirectors = payload => ({
  type: SET_DIRECTORS,
  payload,
})

export const addNewDirector = payload => ({
  type: ADD_NEW_DIRECTOR,
  payload,
})

export const getDirectorDetail = (params) => ({
  type: GET_DIRECTOR_DETAIL,
  payload: { params },
})

export const setDirectorDetail = payload => ({
  type: SET_DIRECTOR_DETAIL,
  payload,
})


export const updateDirector = payload => ({
  type: UPDATE_DIRECTOR,
  payload,
})


export const deleteDirector = payload => ({
  type: DELETE_DIRECTOR,
  payload,
})


