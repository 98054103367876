import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Container, Row, Col, Card, CardBody, Media, Button } from "reactstrap"
import { useParams, useHistory } from "react-router"
import {
  getSubscriberDetail as onGetSubscriberDetail,
  updateSubscriber as onUpdateSubscriber,
  deleteSubscriber as onDeleteSubscriber,
} from "store/subscribers/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Image
import avatar2 from "../../../assets/images/users/avatar-1.png"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const SubscriberDetail = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const { subscriberDetail } = useSelector(state => ({
    subscriberDetail: state.subscribers.subscriberDetail,
  }))

  useEffect(() => {
    dispatch(onGetSubscriberDetail(id))
  }, [])

  const handleDeleteSubscriber = () => {
    dispatch(onDeleteSubscriber(subscriberDetail._id))
    history.push("/subscriber-list")
  }
  
  const handleReadStatus = () => {
    // dispatch(onDeleteSubscriber(subscriberDetail._id));
    // history.push("/subscriber-list");
    const updateSubscriber = {
      _id: subscriberDetail._id,
      isReaded: !subscriberDetail.isReaded,
      readedAt: subscriberDetail.isReaded ? null : Date.now()
    }

    // update subscriber
    dispatch(onUpdateSubscriber(updateSubscriber))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Subscriber Detail")} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Subscribers")} breadcrumbItem={props.t("Subscriber Detail")} />

          <Row>
            <Col xs="12">
                <Card>
                  {/* Render Email Top Tool Bar */}
                  <div className="btn-toolbar p-3" role="toolbar">
                    <div className="btn-group me-2 mb-2 mb-sm-0">
                      <Button
                        type="button"
                        color="primary"
                        onClick={handleReadStatus}
                      >
                        <i className={`fa ${subscriberDetail.isReaded ? 'fa-envelope' : 'fa-envelope-open-text'}`} />
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        onClick={handleDeleteSubscriber}
                      >
                        <i className="far fa-trash-alt" />
                      </Button>
                    </div>
                  </div>

                  <CardBody>

                    <h4 className="mt-0 font-size-16">
                    {subscriberDetail.email}
                    </h4>
                    <hr />
                    <p className="mb-0">
                      <strong>{props.t("Created At")}: </strong> {moment(subscriberDetail.createdAt).format("DD-MM-YY hh:mm")}
                    </p>
                    <p className="mb-0">
                      <strong>{props.t("Read Status")}:</strong> {subscriberDetail.isReaded ? 'Readed' : 'Unreaded'}
                    </p>
                    {subscriberDetail.isReaded && (
                      <p className="mb-0">
                        <strong>{props.t("Read Date")}:</strong> {moment(subscriberDetail.readedAt).format("DD-MM-YY hh:mm")}
                      </p>
                    )}
                    <hr />

                    <Link
                      to="/subscriber-list"
                      className="btn btn-secondary  mt-4"
                    >
                      <i className="mdi mdi-reply"></i> {props.t("Go Back")}
                    </Link>
                  </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}



SubscriberDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SubscriberDetail)
