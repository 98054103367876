import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_USERS, GET_USER_DETAIL , ADD_NEW_USER , DELETE_USER, UPDATE_USER, UPDATE_USER_INLINE, ADD_NEW_USER_INLINE } from "./actionTypes"

import {
  setUserDetail,
  setUsers,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getUsers, getUserDetail, deleteUser, updateUser, addUser } from "../../helpers/backend_helper"

function* fetchUsers({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setUserDetail({}))
  try {
    const response = yield call(getUsers, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setUsers(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchUserDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getUserDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setUserDetail(response.data))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateUser({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateUser, user)
    yield put(toggleActionLoading(false))
    yield put(setUserDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Contact updated successfuly.', redirectUrl: '/user-list'}))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteUser({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteUser, id)
    const responsePages = yield call(getUsers, { params })
    yield put(toggleActionLoading(false))
    yield put(setUsers(responsePages))
    yield put(showNotification({ type: 'success', message: 'User deleted successfuly.', redirectUrl: '/user-list'}))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewUser({ payload: user }) {
  yield put(toggleActionLoading(false))
  try {
    const response = yield call(addUser, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'User added successfuly.', redirectUrl: '/user-list'}))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

// cem


function* onUpdateUserInline({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateUser, user)
    const responseDatas = yield call(getUsers, {})
    yield put(toggleActionLoading(false))
    yield put(setUsers(responseDatas))
    yield put(setUserDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Contact updated successfuly.' }))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}


function* onAddNewUserInline({ payload: user }) {
  yield put(toggleActionLoading(false))
  try {
    const response = yield call(addUser, user)
    const responseDatas = yield call(getUsers, {})
    yield put(toggleActionLoading(false))
    yield put(setUsers(responseDatas))
    yield put(showNotification({ type: 'success', message: 'User added successfuly.' }))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}


function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(UPDATE_USER_INLINE, onUpdateUserInline)
  yield takeEvery(ADD_NEW_USER_INLINE, onAddNewUserInline)
}

export default usersSaga
