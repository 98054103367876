
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_SUBSCRIBERS, GET_SUBSCRIBER_DETAIL , ADD_NEW_SUBSCRIBER , DELETE_SUBSCRIBER, UPDATE_SUBSCRIBER } from "./actionTypes"

import {
  setSubscriberDetail,
  setSubscribers,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getSubscribers, getSubscriberDetail, deleteSubscriber, updateSubscriber, addSubscriber } from "../../helpers/backend_helper"


function* fetchSubscribers({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setSubscriberDetail({}))
  try {
    const response = yield call(getSubscribers, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setSubscribers(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchSubscriberDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getSubscriberDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setSubscriberDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateSubscriber({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateSubscriber, payload)
    yield put(toggleActionLoading(false))
    yield put(setSubscriberDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Subscriber updated successfuly.', redirectUrl: '/subscriber-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteSubscriber({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteSubscriber, id)
    const responsePages = yield call(getSubscribers, { params })
    yield put(setSubscribers(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Subscriber deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewSubscriber({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addSubscriber, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Subscriber added successfuly.', redirectUrl: '/subscriber-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* subscribersSaga() {
  yield takeEvery(GET_SUBSCRIBERS, fetchSubscribers)
  yield takeEvery(GET_SUBSCRIBER_DETAIL, fetchSubscriberDetail)
  yield takeEvery(ADD_NEW_SUBSCRIBER, onAddNewSubscriber)
  yield takeEvery(UPDATE_SUBSCRIBER, onUpdateSubscriber)
  yield takeEvery(DELETE_SUBSCRIBER, onDeleteSubscriber)
}

export default subscribersSaga
