import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../../validations';

import {
  addNewDistributorshipCategory,
  updateDistributorshipCategory,
  getDistributorshipCategoryDetail as onGetDistributorshipCategoryDetail,
  deleteDistributorshipCategory as onDeleteDistributorshipCategory
} from "store/distributorships/distributorship_categories/actions"

import {
  getDistributorshipCategoryOptions,
} from "store/form/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import FileUpload from "components/FileUpload"
import LanguageSelect from "components/LanguageSelect"
import CategorySelect from "components/CategorySelect"
import StateSelect from "components/StateSelect"
import TagInput from "components/TagInput"

const DistributorshipCategoryCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { distributorshipCategories, distributorshipCategoryDetail, formOptions } = useSelector(state => ({
    distributorshipCategories: state.distributorshipCategories.distributorshipCategories,
    distributorshipCategoryDetail: state.distributorshipCategories.distributorshipCategoryDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    dispatch(getDistributorshipCategoryOptions())
    if (id) {
      dispatch(onGetDistributorshipCategoryDetail(id))
    } else {
      setDataForce({
        language: '',
        title: '',
        short_title: '',
        slogan: '',
        content_title: '',
        meta_title: '',
        meta_description: '',
        description: '',
        logo: [],
        image_cover: [],
        state: 'published',
        parentId: null,
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(distributorshipCategoryDetail).length) {
      setDataForce({
        language: distributorshipCategoryDetail.language,
        title: distributorshipCategoryDetail.title,
        short_title: distributorshipCategoryDetail.short_title,
        slogan: distributorshipCategoryDetail.slogan,
        content_title: distributorshipCategoryDetail.content_title,
        meta_title: distributorshipCategoryDetail.meta_title,
        meta_description: distributorshipCategoryDetail.meta_description,
        description: distributorshipCategoryDetail.description,
        logo: distributorshipCategoryDetail?.logo ? [distributorshipCategoryDetail?.logo] : [],
        image_cover: distributorshipCategoryDetail?.image_cover ? [distributorshipCategoryDetail?.image_cover] : [],
        state: distributorshipCategoryDetail.state,
        parentId: distributorshipCategoryDetail.parentId,
      })
    }
  }, [distributorshipCategoryDetail])
  

  const validationList = ['title', 'language','short_title','meta_title','meta_description','state', 'description'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      title: '',
      short_title: '',
      slogan: '',
      content_title: '',
      meta_title: '',
      meta_description: '',
      description: '',
      logo: [],
      image_cover: [],
      state: 'published',
      parentId: null
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      title: data.title,
      language: data.language,
      short_title: data.short_title,
      slogan: data.slogan,
      content_title: data.content_title,
      meta_title: data.meta_title,
      meta_description: data.meta_description,
      description: data.description,
      state: data.state,
      parentId: data.parentId,
      logo: data.logo[0] || '',
      image_cover: data.image_cover[0] || ''
    }

    if (id) {
      dispatch(updateDistributorshipCategory(params))
    } else {
      dispatch(addNewDistributorshipCategory(params))
    }

  }


  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm(props.t('Are you sure?'))) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteDistributorshipCategory({ id }))
      setTimeout(() => {
        history.push("/distributorships/distributorship-category-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Distributorship Category") }) : props.t("page_info_create", { name: props.t("Distributorship Category") });


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Distributorship Categories')} titleLink="/distributorships/distributorship-category-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          invalid={errors.title}
                          onChange={handleChange('title')}
                          value={data.title}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Title")})}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="parentId"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Category Parent Id")}
                      </Label>
                      <Col lg="10">
                        <CategorySelect
                          t={props.t}
                          value={data?.parentId}
                          valueKey="parentId"
                          errorMessage={errors.parentId}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("State")}
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          t={props.t}
                          value={data?.state}
                          valueKey="state"
                          onChange={(key, value) => handleChange(key)(value)}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="short_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Short Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="short_title"
                          name="short_title"
                          type="text"
                          invalid={errors.short_title}
                          onChange={handleChange('short_title')}
                          value={data?.short_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Short Title")})}
                        />
                        {errors.short_title && <FormFeedback>{errors.short_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="slogan"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Slogan")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="slogan"
                          name="slogan"
                          type="text"
                          invalid={errors.slogan}
                          onChange={handleChange('slogan')}
                          value={data?.slogan || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Slogan")})}
                        />
                        {errors.slogan && <FormFeedback>{errors.slogan}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="content_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Content Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="content_title"
                          name="content_title"
                          type="text"
                          invalid={errors.content_title}
                          onChange={handleChange('content_title')}
                          value={data?.content_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Content Title")})}
                        />
                        {errors.content_title && <FormFeedback>{errors.content_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="meta_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Meta Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="meta_title"
                          name="meta_title"
                          type="text"
                          invalid={errors.meta_title}
                          onChange={handleChange('meta_title')}
                          value={data?.meta_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Meta Title")})}
                        />
                        {errors.meta_title && <FormFeedback>{errors.meta_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="meta_description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Meta Description")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="meta_description"
                          name="meta_description"
                          type="text"
                          invalid={errors.meta_description}
                          onChange={handleChange('meta_description')}
                          value={data?.meta_description || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Meta Description")})}
                        />
                        {errors.meta_description && <FormFeedback>{errors.meta_description}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Content")}
                      </Label>
                      <Col lg="10">
                        <CKEditor
                          editor={ClassicEditor}
                          data={data.description}
                          onChange={(event, editor) => {
                            const editorData = editor.getData()
                            if (id) {
                              if (id && data?.description?.charAt(0) === '<') {
                                handleChange('description')(editorData)
                              }
                            } else {
                              handleChange('description')(editorData)
                            }
                          }}
                        />
                        {errors.description && <span className="error-form-field">{errors.description}</span>}
                      </Col>
                    </FormGroup>                   
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Logo")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("logo")(files)}
                          multiple={false}
                          images={data?.logo}
                          errorMessage={errors.logo}
                          valueKey="logo"
                          onDeleteFile={(f) => handleDeleteFile(f, 'logo')}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover")(files)}
                          multiple={false}
                          images={data?.image_cover}
                          errorMessage={errors.image_cover}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover')}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Distributorship Category")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Distributorship Category")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Distributorship Category")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DistributorshipCategoryCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DistributorshipCategoryCreate)
