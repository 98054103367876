
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_VIDEO_SLIDERS, GET_VIDEO_SLIDER_DETAIL , ADD_NEW_VIDEO_SLIDER , DELETE_VIDEO_SLIDER, UPDATE_VIDEO_SLIDER } from "./actionTypes"

import {
  setVideoSliderDetail,
  setVideoSliders,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getVideoSliders, getVideoSliderDetail, deleteVideoSlider, updateVideoSlider, addVideoSlider } from "../../helpers/backend_helper"


function* fetchVideoSliders({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setVideoSliderDetail({}))
  try {
    const response = yield call(getVideoSliders, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setVideoSliders(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchVideoSliderDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getVideoSliderDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setVideoSliderDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateVideoSlider({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateVideoSlider, payload)
    yield put(toggleActionLoading(false))
    yield put(setVideoSliderDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'VideoSlider updated successfuly.', redirectUrl: '/video-sliders-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteVideoSlider({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteVideoSlider, id)
    const responsePages = yield call(getVideoSliders, { params })
    yield put(setVideoSliders(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "VideoSlider deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewVideoSlider({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addVideoSlider, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'VideoSlider added successfuly.', redirectUrl: '/video-sliders-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* videoSlidersSaga() {
  yield takeEvery(GET_VIDEO_SLIDERS, fetchVideoSliders)
  yield takeEvery(GET_VIDEO_SLIDER_DETAIL, fetchVideoSliderDetail)
  yield takeEvery(ADD_NEW_VIDEO_SLIDER, onAddNewVideoSlider)
  yield takeEvery(UPDATE_VIDEO_SLIDER, onUpdateVideoSlider)
  yield takeEvery(DELETE_VIDEO_SLIDER, onDeleteVideoSlider)
}

export default videoSlidersSaga
