
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_OUR_PROCESSS, GET_OUR_PROCESS_DETAIL , ADD_NEW_OUR_PROCESS , DELETE_OUR_PROCESS, UPDATE_OUR_PROCESS } from "./actionTypes"

import {
  setOurProcessDetail,
  setOurProcess,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getOurProcess, getOurProcessDetail, deleteOurProcess, updateOurProcess, addOurProcess } from "../../helpers/backend_helper"


function* fetchOurProcess({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setOurProcessDetail({}))
  try {
    const response = yield call(getOurProcess, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setOurProcess(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchOurProcessDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getOurProcessDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setOurProcessDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateOurProcess({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateOurProcess, payload)
    yield put(toggleActionLoading(false))
    yield put(setOurProcessDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'OurProcess updated successfuly.', redirectUrl: '/dynamic-page-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteOurProcess({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteOurProcess, id)
    const responsePages = yield call(getOurProcess, { params })
    yield put(setOurProcess(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "OurProcess deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewOurProcess({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addOurProcess, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'OurProcess added successfuly.', redirectUrl: '/our-process-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* ourProcessSaga() {
  yield takeEvery(GET_OUR_PROCESSS, fetchOurProcess)
  yield takeEvery(GET_OUR_PROCESS_DETAIL, fetchOurProcessDetail)
  yield takeEvery(ADD_NEW_OUR_PROCESS, onAddNewOurProcess)
  yield takeEvery(UPDATE_OUR_PROCESS, onUpdateOurProcess)
  yield takeEvery(DELETE_OUR_PROCESS, onDeleteOurProcess)
}

export default ourProcessSaga
