/* eslint-disable */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import RemoteTable from "components/Common/RemoteTable";

import {
  getBlogPosts as onGetBlogPosts,
  addNewBlogPost as onAddNewBlogPost,
  updateBlogPost as onUpdateBlogPost,
  deleteBlogPost as onDeleteBlogPost,
} from "store/blog_posts/actions";

//redux
import { useSelector, useDispatch } from "react-redux";


const BlogPostsList = props => {
  const dispatch = useDispatch();

  const { blogPosts, loading } = useSelector(state => ({
    blogPosts: state.blogPosts.blogPosts,
    loading: state.blogPosts.loading,
  }));

	const [perPage, setPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState('');

  const columns = [
    {
      text: `${props.t('Language')}`,
      dataField: "language",
    },
    {
      text: `${props.t('Title')}`,
      dataField: "title",
    },
    {
      text: `${props.t("Short Title")}`,
      dataField: "short_title",
      formatter: (cellContent, row) => row.short_title ? row.short_title?.slice(0, 20) + (row.short_title.length > 20 ? "..." : "") : ''
    },
    {
      text: `${props.t("Creator")}`,
      formatter: (cellContent, row) => row.user?.name || '-'
    },
    {
      text: `${props.t('State')}`,
      formatter: (cellContent, row) => (
        <span
          to="#"
          className="badge badge-soft-primary font-size-11 m-1"
        >
          {row?.state?.toUpperCase()}
        </span>
      ),
    },
    {
      text: `${props.t("Menu")}`,
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to={`/blog-post-edit/${row._id}`}>
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteBlogPost(row._id)}
            ></i>
          </Link>
        </div>
      )
    }
  ];

  const handleDeleteBlogPost = id => {
    if (id !== undefined && confirm('Are you sure?')) {
      const params = {
        page: blogPosts.page,
        limit: perPage
      };
      if (searchValue) {
        params['title[regex]'] = searchValue
      }
      
      dispatch(onDeleteBlogPost({ id, params }));
    }
  };

  useEffect(() => {
    dispatch(onGetBlogPosts());
  }, []);

  
  const handleSearch = (inputValue) => {
    const params = {
      limit: perPage,
    }
    if (inputValue) {
      params['title[regex]'] = inputValue;
    }
    setSearchValue(inputValue);
    dispatch(onGetBlogPosts(params));
  }


  const handleTableChange  = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    if (type == 'sort') return;
    setPerPage(sizePerPage);
    const params = {
      limit: sizePerPage,
      page: page
    }
    dispatch(onGetBlogPosts(params));
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("page_info_list", { name: props.t("Dynamic Page") })} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t('Blog Posts')} breadcrumbItem={props.t("page_info_list", { name: props.t("Dynamic Page") })} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <RemoteTable
                    search
                    onSearch={handleSearch}
                    columns={columns}
                    data={blogPosts?.data || []}
                    page={blogPosts?.page || 1}
                    sizePerPage={perPage}
                    totalSize={blogPosts?.total || 0}
                    onTableChange={handleTableChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

BlogPostsList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(withRouter(BlogPostsList))
