import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../validations';

import {
  addNewCalculation,
  updateCalculation,
  getCalculationDetail as onGetCalculationDetail,
  deleteCalculation as onDeleteCalculation
} from "store/calculations/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import LanguageSelect from "components/LanguageSelect"

const CalculationCreateForm = (props) => {
  const history = useHistory()
  const id = props?.id || useParams()?.id;

  const dispatch = useDispatch()



  const { calculations, calculationDetail, formOptions } = useSelector(state => ({
    calculations: state.calculations.calculations,
    calculationDetail: state.calculations.calculationDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    if (id) {
      dispatch(onGetCalculationDetail(id))
    } else {
      setDataForce({
        // link: '',
        // termLink: '',
        phone_number: '',
        firm_name: '',
        project_name: '',
        related_person: '',
        email: '',
      })
    }
  }, [id])


  console.log('calculationDetail', calculationDetail);

  useEffect(() => {
    if (id && Object.keys(calculationDetail).length) {
      setDataForce({
        // link: calculationDetail.link,
        // termLink: calculationDetail.termLink,
        phone_number: calculationDetail.phone_number,
        firm_name: calculationDetail.firm_name,
        project_name: calculationDetail.project_name,
        related_person: calculationDetail.related_person,
        email: calculationDetail.email,

      })
    }
  }, [calculationDetail])
  

  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      // link: '',
      // termLink: '',
      phone_number: '',
      firm_name: '',
      project_name: '',
      related_person: '',
      email: '',
    },
  })

  const handleValidSubmit = () => {
    const params = {
      // link: data.link,
      // termLink: data.termLink,
      phone_number: data.phone_number,
      firm_name: data.firm_name,
      project_name: data.project_name,
      related_person: data.related_person,
      email: data.email,
    };

    if (props.inline) {
      params['inline'] = true;
    }

    if (id) {
      dispatch(updateCalculation({id, ...params}))
    } else {
      dispatch(addNewCalculation(params))
    }

    setTimeout(() => {
      if (props.inline) {
        props.onClose();
      }
    }, 300);

  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteCalculation({ id }))

      setTimeout(() => {
        if (props.inline) {
          props.onClose();
        } else {
          history.push("/calculation-list")
        }
      }, 300);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {/* <FormGroup className="mb-4" row>
        <Label
          htmlFor="link"
          className="col-form-label col-lg-2"
        >
          {props.t("Link")}
        </Label>
        <Col lg="10">
          <Input
            id="link"
            name="link"
            type="link"
            onChange={handleChange('link')}
            value={data.link}
            className="form-control"
            placeholder={props.t('form_placeholder', { name: props.t("Link")})}
          />
        </Col>
      </FormGroup> */}
      {/* <FormGroup className="mb-4" row>
        <Label
          htmlFor="termLink"
          className="col-form-label col-lg-2"
        >
          {props.t("Term Link")}
        </Label>
        <Col lg="10">
          <Input
            id="termLink"
            name="termLink"
            type="termLink"
            onChange={handleChange('termLink')}
            value={data.termLink}
            className="form-control"
            placeholder={props.t('form_placeholder', { name: props.t("Term Link")})}
          />
        </Col>
      </FormGroup> */}
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="phone_number"
          className="col-form-label col-lg-2"
        >
          {props.t("Phone Number")}
        </Label>
        <Col lg="10">
          <Input
            id="phone_number"
            name="phone_number"
            type="phone_number"
            onChange={handleChange('phone_number')}
            value={data.phone_number}
            className="form-control"
            placeholder={props.t('form_placeholder', { name: props.t("Phone Number")})}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="email"
          className="col-form-label col-lg-2"
        >
          {props.t("Email")}
        </Label>
        <Col lg="10">
          <Input
            id="email"
            name="email"
            type="email"
            onChange={handleChange('email')}
            value={data.email}
            className="form-control"
            placeholder={props.t('form_placeholder', { name: props.t("Email")})}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="firm_name"
          className="col-form-label col-lg-2"
        >
          {props.t("Firm Name")}
        </Label>
        <Col lg="10">
          <Input
            id="firm_name"
            name="firm_name"
            type="firm_name"
            onChange={handleChange('firm_name')}
            value={data.firm_name}
            className="form-control"
            placeholder={props.t('form_placeholder', { name: props.t("Firm Name")})}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="project_name"
          className="col-form-label col-lg-2"
        >
          {props.t("Project Name")}
        </Label>
        <Col lg="10">
          <Input
            id="project_name"
            name="project_name"
            type="project_name"
            onChange={handleChange('project_name')}
            value={data.project_name}
            className="form-control"
            placeholder={props.t('form_placeholder', { name: props.t("Project Name")})}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="related_person"
          className="col-form-label col-lg-2"
        >
          {props.t("Related Person")}
        </Label>
        <Col lg="10">
          <Input
            id="related_person"
            name="related_person"
            type="related_person"
            onChange={handleChange('related_person')}
            value={data.related_person}
            className="form-control"
            placeholder={props.t('form_placeholder', { name: props.t("Related Person")})}
          />
        </Col>
      </FormGroup>

      <div>
        <h5 className="text-center pt-2">DARBE SESİ DÜZEYİ DEĞERLENDİRMESİ</h5>

        <table className="table table-striped table-bordered">
          <tbody>
              <tr>
                  <td>Kaplamasız Betonarme Döşemenin Darbe Sesi Düzeyi L<sub>n0,w</sub>(dB)</td>
                  <td id="sonuc3-1" align="center" width="30%">{calculationDetail.sonuc1}</td>
              </tr>
              <tr>
                  <td>Yüzer Döşemenin Darbe Sesi Azaltımı ΔL<sub>w</sub>(dB)</td>
                  <td id="sonuc3-2" align="center" width="30%">{calculationDetail.sonuc2}</td>
              </tr>
              <tr>
                  <td>Elde Edilen Darbe Sesi Düzeyi L<sub>n,w</sub>(dB)</td>
                  <td id="sonuc3" align="center" width="30%">{calculationDetail.sonuc3}</td>
              </tr>
          </tbody>
        </table>

        <h5 className="text-center pt-2">HAVA DOĞUŞLU SES YALITIM DÜZEYİ DEĞERLENDİRMESİ</h5>

        <table className="table table-striped table-bordered hesaplamalar">
          <tbody>
              <tr>
                  <td>Kaplamasız Betonarme Döşemenin Hava Doğuşlu Ses Yalıtım Düzeyi R<sub>w</sub>(dB)</td>
                  <td id="sonuc5" width="30%" align="center">{calculationDetail.sonuc4}</td>
              </tr>
              <tr>
                  <td>Yüzer Döşemenin Hava Doğuşlu Ses Yalıtımına Katkısı ΔR<sub>w</sub>(dB)</td>
                  <td id="sonuc6" width="30%" align="center">{calculationDetail.sonuc5}</td>
              </tr>
              <tr>
                  <td>Elde Edilen Hava Doğuşlu Ses Yalıtım Değeri R<sub>w</sub>(dB)</td>
                  <td id="sonuc7" width="30%" align="center">{calculationDetail.sonuc6}</td>
              </tr>
          </tbody>
      </table>
      </div>
                    
      {/* <Row className="justify-content-end">
        <Col lg="10">
          {id ? (
            <>
              <Button type="submit" color="primary">
              {props.t("edit_btn_for_model", { name: props.t("Calculation")})}
              </Button>
              <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
              {props.t("delete_btn_for_model", { name: props.t("Calculation")})}
              </Button>
            </>
          ) : (
            <Button type="submit" color="primary">
              {props.t("create_btn_for_model", { name: props.t("Calculation")})}
            </Button>
          )}
        </Col>
      </Row> */}
    </Form>
  )
}

CalculationCreateForm.propTypes = {
  t: PropTypes.any,
  id: PropTypes.string,
  inline: PropTypes.bool,
  onClose: PropTypes.func,
}

export default withTranslation()(CalculationCreateForm)
