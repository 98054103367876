
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_OURVALUES, GET_OURVALUE_DETAIL , ADD_NEW_OURVALUE , DELETE_OURVALUE, UPDATE_OURVALUE } from "./actionTypes"

import {
  setOurValueDetail,
  setOurValues,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getOurValues, getOurValueDetail, deleteOurValue, updateOurValue, addOurValue } from "../../helpers/backend_helper"


function* fetchOurValues({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setOurValueDetail({}))
  try {
    const response = yield call(getOurValues, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setOurValues(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchOurValueDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getOurValueDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setOurValueDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateOurValue({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateOurValue, payload)
    yield put(toggleActionLoading(false))
    yield put(setOurValueDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'OurValue updated successfuly.', redirectUrl: '/ourvalue-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteOurValue({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteOurValue, id)
    const responsePages = yield call(getOurValues, { params })
    yield put(setOurValues(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "OurValue deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewOurValue({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addOurValue, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'OurValue added successfuly.', redirectUrl: '/ourvalue-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* ourValuesSaga() {
  yield takeEvery(GET_OURVALUES, fetchOurValues)
  yield takeEvery(GET_OURVALUE_DETAIL, fetchOurValueDetail)
  yield takeEvery(ADD_NEW_OURVALUE, onAddNewOurValue)
  yield takeEvery(UPDATE_OURVALUE, onUpdateOurValue)
  yield takeEvery(DELETE_OURVALUE, onDeleteOurValue)
}

export default ourValuesSaga
