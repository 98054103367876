import {
  GET_ASSETS,
  SET_ASSETS,
  ADD_NEW_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET,
} from "./actionTypes"

export const getAssets = (params) => ({
  type: GET_ASSETS,
  payload: { params },
})

export const setAssets = payload => ({
  type: SET_ASSETS,
  payload,
})

export const addNewAsset = payload => ({
  type: ADD_NEW_ASSET,
  payload,
})

export const deleteAsset = payload => ({
  type: DELETE_ASSET,
  payload,
})


