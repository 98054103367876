
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PRODUCTS, GET_PRODUCT_DETAIL , ADD_NEW_PRODUCT , DELETE_PRODUCT, UPDATE_PRODUCT } from "./actionTypes"

import {
  setProductDetail,
  setProducts,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../../form/actions"

import { getProducts, getProductDetail, deleteProduct, updateProduct, addProduct } from "../../../helpers/backend_helper"


function* fetchProducts({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setProductDetail({}))
  try {
    const response = yield call(getProducts, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setProducts(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchProductDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getProductDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setProductDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateProduct({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateProduct, payload)
    yield put(toggleActionLoading(false))
    yield put(setProductDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Product updated successfuly.', redirectUrl: '/products/product-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteProduct({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteProduct, id)
    const responsePages = yield call(getProducts, { params })
    yield put(setProducts(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Product deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewProduct({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addProduct, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Product added successfuly.', redirectUrl: '/products/product-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* ProductsSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts)
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
  yield takeEvery(ADD_NEW_PRODUCT, onAddNewProduct)
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
}

export default ProductsSaga
