import { useTranslation } from 'react-i18next';


const validations = (t) => ({
    name: { required: { value: true, message: `${t('validation_required_message', { name: t('Name')})}` }},
    full_name: { required: { value: true, message: `${t('validation_required_message', { name: t('Full Name')})}` }},
    code: { required: { value: true, message: `${t('validation_required_message', { name: t('Code')})}` }},
    title: { required: { value: true, message: `${t('validation_required_message', { name: t('Title')})}` }},
    language: { required: { value: true, message: `${t('validation_required_message', { name: t('Language')})}` }},
    short_title: { required: { value: true, message: `${t('validation_required_message', { name: t('Short Title')})}` }},
    meta_title: { required: { value: true, message: `${t('validation_required_message', { name: t('Meta Title')})}` }},
    meta_description: { required: { value: true, message: `${t('validation_required_message', { name: t('Meta Description')})}` }},
    state: { required: { value: true, message: `${t('validation_required_message', { name: t('State')})}` }},
    url: { required: { value: true, message: `${t('validation_required_message', { name: t('URL')})}` }},
    subject: {
        custom: {
            isValid: (value) => value?.length > 3,
            message: `${t('The content needs to be at least 3 characters long')}`,
        }
    },
    message: {
        custom: {
            isValid: (value) => value?.length > 3,
            message: `${t('The content needs to be at least 3 characters long')}`,
        }
    },
    image_cover: {
        custom: {
            isValid: (value) => {
                return value?.length > 0;
            },
            message: `${t('You must be upload one cover photo')}`,
        },
    },
    image: {
        custom: {
            isValid: (value) => {
                return value?.length > 0;
            },
            message: `${t('You must be upload one photo')}`,
        },
    },
    images: {
        custom: {
            isValid: (value) => value?.length > 0,
            message: `${t('You must be upload one photo')}`,
        },
    },
    content: {
        custom: {
            isValid: (value) => value?.length > 3,
            message: `${t('The content needs to be at least 3 characters long')}`,
        }
    },
    email: {
        required: {
            value: true, message: `${t('validation_required_message', { name: t('Email')})}`
        },
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: `${t('form_invalid_error_message', { name: t('Email')})}`
        },
    },
    meta_title: { type: { value: 'string', message: `${t('validation_must_string_message', { name: t('Meta Title')})}` }, required: { value: true, message: `${t('validation_required_message', { name: t('Meta Title')})}` }},
    meta_description: { required: { value: true, message: `${t('validation_required_message', { name: t('Meta Description')})}` }},
    meta_keywords: { required: { value: true, message: `${t('validation_required_message', { name: t('Meta Keywords')})}` }},
    info_cards: {
    nested: {
        validations: {
        type: { required: { value: true, message: `${t('validation_required_message', { name: t('Type')})}` }},
        code: { required: { value: true, message: `${t('validation_required_message', { name: t('Code')})}` }},
        }
    },
    },

    steps: {
        nested: {
          validations: {
            title: { required: { value: true, message: `${t('validation_required_message', { name: t('Title')})}` }},
            content: { required: { value: true, message: `${t('validation_required_message', { name: t('Content')})}` }},
          }
        },
      },
});

const generateValidation = (list, t) => {
    if (!list?.length) {
        return {};
    }
    const validationObj = {};
    const validationList = validations(t);
    list.forEach(code => {
        validationObj[code] = validationList[code];
        return;
    });

    return validationObj;

}

export {
    generateValidation,
    validations,
}