/* eslint-disable */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import RemoteTable from "components/Common/RemoteTable";

import {
  getLanguages as onGetLanguages,
  deleteLanguage as onDeleteLanguage,
} from "store/languages/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import getImage from "helpers/getImage";


const LanguagesList = props => {
  const dispatch = useDispatch();

  const { languages, loading } = useSelector(state => ({
    languages: state.languages.languages,
    loading: state.languages.loading,
  }));
	const [perPage, setPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState('');

  const columns = [
    {
        name: '#',
        formatter: (cellContent, row) => (
          <>
            {!row.image ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                {row?.title?.toUpperCase()?.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={getImage({ path: row.image })}
                  alt={row.title}
                />
              </div>
            )}
          </>
        ),
    },
    {
      text: `${props.t("Name")}`,
      dataField: "name",
    },
    {
      text: `${props.t("Code")}`,
      dataField: "code",
    },
    {
      text: `${props.t("Menu")}`,
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to={`/language-edit/${row._id}`}>
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteLanguage(row._id)}
            ></i>
          </Link>
        </div>
      )
    }
  ];

  const handleDeleteLanguage = id => {
    if (id !== undefined && confirm('Are you sure?')) {
      const params = {
        page: languages.page,
        limit: perPage
      };
      if (searchValue) {
        params['title[regex]'] = searchValue
      }
      
      dispatch(onDeleteLanguage({ id, params }));
    }
  };

  useEffect(() => {
    dispatch(onGetLanguages());
  }, []);

  
  const handleSearch = (inputValue) => {
    const params = {
      limit: perPage,
    }
    if (inputValue) {
      params['name[regex]'] = inputValue;
    }
    setSearchValue(inputValue);
    dispatch(onGetLanguages(params));
  }


  const handleTableChange  = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    if (type == 'sort') return;
    setPerPage(sizePerPage);
    const params = {
      limit: sizePerPage,
      page: page
    }
    dispatch(onGetLanguages(params));
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("page_info_list", { name: props.t("Language") })} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t('Language')} breadcrumbItem={props.t("page_info_create", { name: props.t("Language") })} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <RemoteTable
                    search
                    onSearch={handleSearch}
                    columns={columns}
                    data={languages?.data || []}
                    page={languages?.page || 1}
                    sizePerPage={perPage}
                    totalSize={languages?.total || 0}
                    onTableChange={handleTableChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

LanguagesList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(withRouter(LanguagesList))
