
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_DIRECTORS, GET_DIRECTOR_DETAIL , ADD_NEW_DIRECTOR , DELETE_DIRECTOR, UPDATE_DIRECTOR } from "./actionTypes"

import {
  setDirectorDetail,
  setDirectors,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getDirectors, getDirectorDetail, deleteDirector, updateDirector, addDirector } from "../../helpers/backend_helper"


function* fetchDirectors({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setDirectorDetail({}))
  try {
    const response = yield call(getDirectors, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setDirectors(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchDirectorDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getDirectorDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setDirectorDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateDirector({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateDirector, payload)
    yield put(toggleActionLoading(false))
    yield put(setDirectorDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Director updated successfuly.', redirectUrl: '/director-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteDirector({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteDirector, id)
    const responsePages = yield call(getDirectors, { params })
    yield put(setDirectors(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Director deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewDirector({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addDirector, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Director added successfuly.', redirectUrl: '/director-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* directorsSaga() {
  yield takeEvery(GET_DIRECTORS, fetchDirectors)
  yield takeEvery(GET_DIRECTOR_DETAIL, fetchDirectorDetail)
  yield takeEvery(ADD_NEW_DIRECTOR, onAddNewDirector)
  yield takeEvery(UPDATE_DIRECTOR, onUpdateDirector)
  yield takeEvery(DELETE_DIRECTOR, onDeleteDirector)
}

export default directorsSaga
