import { SET_BLOG_POSTS, SET_BLOG_POST_DETAIL } from "./actionTypes"

const INIT_STATE = {
  blogPosts: [],
  blogPostDetail: {},
}

const blogPost = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_BLOG_POSTS:
      return {
        ...state,
        blogPosts: action.payload,
      }

    case SET_BLOG_POST_DETAIL:
      return {
        ...state,
        blogPostDetail: action.payload,
      }
    default:
      return state
  }
}

export default blogPost
