import {
  GET_DISTRIBUTORSHIP_DETAIL,
  SET_DISTRIBUTORSHIP_DETAIL,
  GET_DISTRIBUTORSHIPS,
  SET_DISTRIBUTORSHIPS,
  ADD_NEW_DISTRIBUTORSHIP,
  UPDATE_DISTRIBUTORSHIP,
  DELETE_DISTRIBUTORSHIP,
} from "./actionTypes"

export const getDistributorships = (params) => ({
  type: GET_DISTRIBUTORSHIPS,
  payload: { params },
})

export const setDistributorships = payload => ({
  type: SET_DISTRIBUTORSHIPS,
  payload,
})

export const addNewDistributorship = payload => ({
  type: ADD_NEW_DISTRIBUTORSHIP,
  payload,
})

export const getDistributorshipDetail = (params) => ({
  type: GET_DISTRIBUTORSHIP_DETAIL,
  payload: { params },
})

export const setDistributorshipDetail = payload => ({
  type: SET_DISTRIBUTORSHIP_DETAIL,
  payload,
})


export const updateDistributorship = payload => ({
  type: UPDATE_DISTRIBUTORSHIP,
  payload,
})


export const deleteDistributorship = payload => ({
  type: DELETE_DISTRIBUTORSHIP,
  payload,
})


