import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Card, Spinner, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  updateGlobal as onUpdateGlobal,
  resetMessages as onResetAllMessages,
  getGlobalDetail as onGetGlobalDetail,
} from "store/global/actions"

import { generateValidation } from '../../validations';

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

const validationList = ['meta_title', 'meta_description', 'meta_keyword', 'info_cards'];

const SiteGlobalUpdate = (props) => {
  const [cardField, setCardField] = useState([])
  const dispatch = useDispatch()
  const { globalDetail, fetchingLoading } = useSelector(state => ({
    fetchingLoading: state.form.fetchingLoading,
    globalDetail: state.global.globalDetail,
  }))

  useEffect(() => {
    dispatch(onGetGlobalDetail())
  }, [])

  useEffect(() => {
    if (Object.keys(globalDetail).length) {
      setDataForce({
        meta_title: globalDetail.meta_title,
        meta_description: globalDetail.meta_description,
        meta_keywords: globalDetail.meta_keywords,
        info_cards: globalDetail?.info_cards || []
      })
      if (globalDetail?.info_cards?.length) {
        setCardField(globalDetail.info_cards.map((card, idx) => ({
          id: idx + 1,
          type: card.type,
          code: card.code,
          title: card.title,
          content: card.content,
          href: card.href
        })))
      }
    }
  }, [globalDetail])
  


  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations: {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      info_cards: [{
        type: '', //['social', 'contact']
        code: '',
        title: '',
        content: '',
        href: '',
      }]
    },
  })

  const handleValidSubmit = () => {
    const payload = {
      meta_title: data.meta_title,
      meta_description: data.meta_description,
      meta_keywords: data.meta_keywords,
      info_cards: data.info_cards,
    };

    dispatch(onUpdateGlobal(payload));
  }



  const handleAddRowNested = () => {
    let modifiedCardFields = [...cardField]
    modifiedCardFields.push({
      id: modifiedCardFields.length + 1,
      type: 'social', //['social', 'contact']
      code: '',
      title: '',
      content: '',
      href: '',
    })
    setCardField(modifiedCardFields)
    setDataForce({ ...data, info_cards: modifiedCardFields })
  }

  const handleDeleteAllRowNested = () => {
    if (confirm('Are you sure?')) {
      setCardField([])
      setDataForce({ ...data, info_cards: [] })
    }
  }

  const handleRemoveRow = (id) => {
    if (confirm('Are you sure?')) {
      if (id !== 1) {
        let modifiedCardFields = [...cardField]
        modifiedCardFields = modifiedCardFields.filter(x => x["id"] !== id)
        setCardField(modifiedCardFields)
      } else {
        setCardField([])
        setDataForce({ ...data, info_cards: [] })
      }
    }
  }

  const handleCardChange = (e, row, key) => {
    const modifiedCardFields = cardField.map((field) => {
      if (field.id === row.id) {
        return {
          ...field,
          [key]: e.target.value
        }
      }
      return field
    })
    setCardField(modifiedCardFields)
    handleChange('info_cards')(modifiedCardFields)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Site Global")}| Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={`${props.t("Dashboard")}`} titleLink="/" breadcrumbItem={props.t("Site Global")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t("Site Global")}</CardTitle>

                  {fetchingLoading ? (
                    <Spinner className="ms-2" color="primary" />
                  ) : (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="meta_title"
                          className="col-form-label col-lg-2"
                        >
                          {props.t("Meta Title")}
                        </Label>
                        <Col lg="10">
                          <Input
                            id="meta_title"
                            name="meta_title"
                            type="text"
                            invalid={errors.meta_title}
                            onChange={handleChange('meta_title')}
                            value={data?.meta_title || ''}
                            className="form-control"
                            placeholder={props.t('form_placeholder', { name: props.t("Meta Title")})}
                          />
                          {errors.meta_title && <FormFeedback>{errors.meta_title}</FormFeedback>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="meta_description"
                          className="col-form-label col-lg-2"
                        >
                          {props.t("Meta Description")}
                        </Label>
                        <Col lg="10">
                          <Input
                            id="meta_description"
                            name="meta_description"
                            type="textarea"
                            rows={5}
                            invalid={errors.meta_description}
                            onChange={handleChange('meta_description')}
                            value={data?.meta_description || ''}
                            className="form-control"
                            placeholder={props.t('form_placeholder', { name: props.t("Meta Description")})}
                          />
                          {errors.meta_description && <FormFeedback>{errors.meta_description}</FormFeedback>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="meta_keywords"
                          className="col-form-label col-lg-2"
                        >
                          {props.t("Meta Keywords")}
                        </Label>
                        <Col lg="10">
                          <Input
                            id="meta_keywords"
                            name="meta_keywords"
                            type="textarea"
                            rows={5}
                            invalid={errors.meta_keywords}
                            onChange={handleChange('meta_keywords')}
                            value={data?.meta_keywords || ''}
                            className="form-control"
                            placeholder={props.t('form_placeholder', { name: props.t("Meta Keywords")})}
                          />
                          {errors.meta_keywords && <FormFeedback>{errors.meta_keywords}</FormFeedback>}
                        </Col>
                      </FormGroup>
                      <hr />
                      <FormGroup className="mb-4" row>
                        <Label
                          className="col-form-label col-lg-2"
                        >
                          {props.t('Info Cards')}
                        </Label>
                        <Col lg="10">
                          <div className="inner-repeater mb-4">
                            <table style={{ width: "100%" }}>
                              <tbody>
                                {(cardField || []).map((formRow, key) => {
                                  const errorFields = errors?.info_cards ? errors?.info_cards[key] : {}
                                  return (
                                    <tr key={key}>
                                      <td>
                                        <Row className="mb-2">
                                          <Col md="10">
                                            <Row>
                                              <Col md="12" className="mb-2">
                                                <Label className="col-form-label">
                                                {props.t('Type')}*</Label>
                                                <Input
                                                id="type"
                                                name="type"
                                                type="select"
                                                onChange={(e) => handleCardChange(e, formRow, 'type')}
                                                value={data?.info_cards[key]?.type || 'social'}
                                                invalid={errorFields?.type}
                                              >
                                                <option value="social">
                                                  Social
                                                </option>
                                                <option value="contact">
                                                  Contact
                                                </option>
                                              </Input>
                                              {errorFields?.type && <FormFeedback>{errorFields?.type}</FormFeedback>}
                                              </Col>
                                              <Col md="12" className="mb-2">
                                              <Label className="col-form-label">
                                                {props.t('Code')}*
                                              </Label>
                                              <Input
                                              type="text"
                                              className="inner form-control"
                                              placeholder={props.t('form_placeholder', { name: props.t("Code")})}
                                              value={data?.info_cards[key]?.code || ''}
                                              invalid={errorFields?.code}
                                              onChange={(e) => handleCardChange(e, formRow, 'code')}
                                            />
                                              {errorFields?.code && <FormFeedback>{errorFields?.code}</FormFeedback>}
                                              </Col>
                                              <Col md="12" className="mb-2">
                                              <Label className="col-form-label">
                                                {props.t('Title')}
                                              </Label>
                                              <Input
                                                type="text"
                                                className="inner form-control"
                                                placeholder={props.t('form_placeholder', { name: props.t("Title")})}
                                                value={data?.info_cards[key]?.title || ''}
                                                invalid={errorFields?.key}
                                                onChange={(e) => handleCardChange(e, formRow, 'title')}
                                              />
                                                {errorFields?.key && <FormFeedback>{errorFields?.key}</FormFeedback>}
                                              </Col>
                                              <Col md="12" className="mb-2">
                                              <Label className="col-form-label">
                                                {props.t('Href')}
                                              </Label>
                                              <Input
                                                type="text"
                                                className="inner form-control"
                                                placeholder={props.t('form_placeholder', { name: props.t("Href")})}
                                                value={data?.info_cards[key]?.href || ''}
                                                invalid={errorFields?.href}
                                                onChange={(e) => handleCardChange(e, formRow, 'href')}
                                              />
                                                {errorFields?.href && <FormFeedback>{errorFields?.href}</FormFeedback>}
                                              </Col>
                                              <Col md="12" className="mb-2">
                                              <Label className="col-form-label">
                                              {props.t('Content')}
                                              </Label>
                                              <Input
                                                type="text"
                                                className="inner form-control"
                                                placeholder={props.t('form_placeholder', { name: props.t("Content")})}
                                                value={data?.info_cards[key]?.content || ''}
                                                invalid={errorFields?.content}
                                                onChange={(e) => handleCardChange(e, formRow, 'content')}
                                              />
                                                {errorFields?.content && <FormFeedback>{errorFields?.content}</FormFeedback>}
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col md="2">
                                            <Button
                                              color="danger"
                                              className="btn-block inner"
                                              id="unknown-btn"
                                              style={{ width: "100%" }}
                                              onClick={e => {
                                                handleRemoveRow(formRow.id)
                                              }}
                                            >
                                              {props.t('Delete')}
                                            </Button>
                                          </Col>
                                        </Row>
                                        <hr />
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                            <Button
                              onClick={handleAddRowNested}
                              color="success"
                              className="mt-1"
                            >
                              {props.t("add_btn_for_model", { name: props.t("Info Card")})}
                            </Button>
                            {cardField?.length ? (<Button
                              onClick={handleDeleteAllRowNested}
                              color="danger"
                              className="mt-1"
                              style={{ marginLeft: '1rem' }}
                            >
                              {props.t("delete_btn_for_model", { name: props.t("Info Card")})}
                            </Button>) : null}
                          </div>
                        </Col>
                      </FormGroup>                    
                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button type="submit" color="primary">
                          {props.t("edit_btn_for_model", { name: props.t("Site Global")})}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SiteGlobalUpdate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SiteGlobalUpdate)