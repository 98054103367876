
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_CHARGING_STATIONS, GET_CHARGING_STATION_DETAIL , ADD_NEW_CHARGING_STATION , DELETE_CHARGING_STATION, UPDATE_CHARGING_STATION } from "./actionTypes"

import {
  setChargingStationDetail,
  setChargingStations,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getChargingStations, getChargingStationDetail, deleteChargingStation, updateChargingStation, addChargingStation } from "../../helpers/backend_helper"


function* fetchChargingStations({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setChargingStationDetail({}))
  try {
    const response = yield call(getChargingStations, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setChargingStations(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchChargingStationDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getChargingStationDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setChargingStationDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateChargingStation({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateChargingStation, payload)
    yield put(toggleActionLoading(false))
    yield put(setChargingStationDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'ChargingStation updated successfuly.', redirectUrl: '/charging-station-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteChargingStation({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteChargingStation, id)
    const responsePages = yield call(getChargingStations, { params })
    yield put(setChargingStations(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "ChargingStation deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewChargingStation({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addChargingStation, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'ChargingStation added successfuly.', redirectUrl: '/charging-station-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* chargingStationsSaga() {
  yield takeEvery(GET_CHARGING_STATIONS, fetchChargingStations)
  yield takeEvery(GET_CHARGING_STATION_DETAIL, fetchChargingStationDetail)
  yield takeEvery(ADD_NEW_CHARGING_STATION, onAddNewChargingStation)
  yield takeEvery(UPDATE_CHARGING_STATION, onUpdateChargingStation)
  yield takeEvery(DELETE_CHARGING_STATION, onDeleteChargingStation)
}

export default chargingStationsSaga
