import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"

import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../validations';

import {
  addNewDirector,
  updateDirector,
  getDirectorDetail as onGetDirectorDetail,
  deleteDirector as onDeleteDirector
} from "store/directors/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import FileUpload from "components/FileUpload"
import LanguageSelect from "components/LanguageSelect"
import StateSelect from "components/StateSelect"


const DirectorCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { directors, directorDetail, formOptions } = useSelector(state => ({
    directors: state.directors.directors,
    directorDetail: state.directors.directorDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    if (id) {
      dispatch(onGetDirectorDetail(id))
    } else {
      setDataForce({
        language: '',
        job_title: '',
        fullname: '',
        sort: '',
        job_description: '',
        image_cover: [],
        image_cover2: [],
        state: 'published',
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(directorDetail).length) {
      setDataForce({
        language: directorDetail.language,
        job_title: directorDetail.job_title,
        fullname: directorDetail.fullname,
        sort: directorDetail.sort,
        job_description: directorDetail.job_description,
        image_cover: directorDetail?.image_cover ? [directorDetail?.image_cover] : [],
        image_cover2: directorDetail?.image_cover2 ? [directorDetail?.image_cover2] : [],
        state: directorDetail.state,
      })
    }
  }, [directorDetail])
  

  const validationList = ['job_title', 'language','fullname','job_description','state'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      job_title: '',
      fullname: '',
      sort: '',
      job_description: '',
      image_cover: [],
      image_cover2: [],
      state: 'published'
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      job_title: data.job_title,
      language: data.language,
      fullname: data.fullname,
      sort: data.sort,
      job_description: data.job_description,
      state: data.state,
      image_cover: data.image_cover[0] || '',
      image_cover2: data.image_cover2[0] || '',
    }

    if (id) {
      dispatch(updateDirector(params))
    } else {
      dispatch(addNewDirector(params))
    }

  }


  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm(props.t('Are you sure?'))) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteDirector({ id }))
      setTimeout(() => {
        history.push("/director-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Directors") }) : props.t("page_info_create", { name: props.t("Directors") });


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Directors')} titleLink="/director-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("State")}
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          t={props.t}
                          value={data?.state}
                          valueKey="state"
                          onChange={(key, value) => handleChange(key)(value)}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="fullname"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Fullname")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="fullname"
                          name="fullname"
                          type="text"
                          invalid={errors.fullname}
                          onChange={handleChange('fullname')}
                          value={data?.fullname || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Fullname")})}
                        />
                        {errors.fullname && <FormFeedback>{errors.fullname}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="job_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Job Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="job_title"
                          name="job_title"
                          type="text"
                          invalid={errors.job_title}
                          onChange={handleChange('job_title')}
                          value={data.job_title}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Job Title")})}
                        />
                        {errors.job_title && <FormFeedback>{errors.job_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="job_description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Job Description")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="job_description"
                          name="job_description"
                          type="textarea"
                          rows={5}
                          invalid={errors.job_description}
                          onChange={handleChange('job_description')}
                          value={data?.job_description || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Job Description")})}
                        />
                        {errors.job_description && <FormFeedback>{errors.job_description}</FormFeedback>}
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="sort"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Sort")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="sort"
                          name="sort"
                          type="number"
                          invalid={errors.sort}
                          onChange={handleChange('sort')}
                          value={data?.sort || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Sort")})}
                        />
                        {/* {errors.sort && <FormFeedback>{errors.sort}</FormFeedback>} */}
                      </Col>
                    </FormGroup>
                    
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover")(files)}
                          multiple={false}
                          images={data?.image_cover}
                          errorMessage={errors.image_cover}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover')}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover Back")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover2")(files)}
                          multiple={false}
                          images={data?.image_cover2}
                          errorMessage={errors.image_cover2}
                          valueKey="image_cover2"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover2')}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Directors")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Directors")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Directors")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DirectorCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DirectorCreate)
