import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown"

import error from "../../assets/images/error-img.png"

const Pages404 = (props) => {
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>{props.t('404 Error Page')} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />
                  4
                </h1>
                <h4 className="text-uppercase">
                  {props.t('Sorry, No content found in this language. Please change language')}
                </h4>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary "
                    to="/dashboard"
                  >
                    {props.t('Back to Dashboard')}
                  </Link>
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="text-uppercase">{props.t('Change Language')} </span> <LanguageDropdown />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Pages404.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Pages404)
