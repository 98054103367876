import { SET_ORDERS, SET_ORDER_DETAIL } from "./actionTypes"

const INIT_STATE = {
  orders: [],
  orderDetail: {},
}

const Order = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      }

    case SET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.payload,
      }
    default:
      return state
  }
}

export default Order
