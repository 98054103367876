import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Container, Row, Col, Card, CardBody, Media, Button } from "reactstrap"
import { useParams, useHistory } from "react-router"
import {
  getContactDetail as onGetContactDetail,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "store/contacts/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Image
import avatar2 from "../../../assets/images/users/avatar-1.png"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const ContactDetail = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const { contactDetail } = useSelector(state => ({
    contactDetail: state.contacts.contactDetail,
  }))

  useEffect(() => {
    dispatch(onGetContactDetail(id))
  }, [])

  const handleDeleteContact = () => {
    dispatch(onDeleteContact(contactDetail._id))
    history.push("/contact-list")
  }
  
  const handleReadStatus = () => {
    // dispatch(onDeleteContact(contactDetail._id));
    // history.push("/contact-list");
    const updateContact = {
      _id: contactDetail._id,
      isReaded: !contactDetail.isReaded,
      readedAt: contactDetail.isReaded ? null : Date.now()
    }

    // update contact
    dispatch(onUpdateContact(updateContact))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("page_info_detail", { name: props.t("Contact") })} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Contacts")} breadcrumbItem={props.t("page_info_detail", { name: props.t("Contact") })} />

          <Row>
            <Col xs="12">
                <Card>
                  {/* Render Email Top Tool Bar */}
                  <div className="btn-toolbar p-3" role="toolbar">
                    <div className="btn-group me-2 mb-2 mb-sm-0">
                      <Button
                        type="button"
                        color="primary"
                        onClick={handleReadStatus}
                      >
                        <i className={`fa ${contactDetail.isReaded ? 'fa-envelope' : 'fa-envelope-open-text'}`} />
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        onClick={handleDeleteContact}
                      >
                        <i className="far fa-trash-alt" />
                      </Button>
                    </div>
                  </div>

                  <CardBody>
                    <Media className="mb-4">
                      <img
                        className="d-flex me-3 rounded-circle avatar-sm"
                        src={avatar2}
                        alt="skote"
                      />
                      <Media body>
                        <h5 className="font-size-14 mt-1">
                          {contactDetail.full_name}
                        </h5>
                        <small className="text-muted">{contactDetail.email}</small><br/>
                      </Media>
                    </Media>

                    <h4 className="mt-0 font-size-16">
                    {contactDetail.subject}
                    </h4>

                    {contactDetail.message}
                    <hr />
                    <p className="mb-0">
                      <strong>{props.t("Language")}: </strong> {contactDetail.language}
                    </p>
                    <p className="mb-0">
                      <strong>{props.t("Created At")}: </strong> {moment(contactDetail.createdAt).format("DD-MM-YY hh:mm")}
                    </p>
                    <p className="mb-0">
                      <strong>{props.t("Read Status")}:</strong> {contactDetail.isReaded ? 'Readed' : 'Unreaded'}
                    </p>
                    {contactDetail.isReaded && (
                      <p className="mb-0">
                        <strong>{props.t("Read Date")}:</strong> {moment(contactDetail.readedAt).format("DD-MM-YY hh:mm")}
                      </p>
                    )}
                    <hr />

                    <Link
                      to="/contact-list"
                      className="btn btn-secondary  mt-4"
                    >
                      <i className="mdi mdi-reply"></i> {props.t("Go Back")}
                    </Link>
                  </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}



ContactDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ContactDetail)
