import {
  GET_SUBSCRIBER_DETAIL,
  SET_SUBSCRIBER_DETAIL,
  GET_SUBSCRIBERS,
  SET_SUBSCRIBERS,
  ADD_NEW_SUBSCRIBER,
  UPDATE_SUBSCRIBER,
  DELETE_SUBSCRIBER,
} from "./actionTypes"

export const getSubscribers = (params) => ({
  type: GET_SUBSCRIBERS,
  payload: { params },
})

export const setSubscribers = payload => ({
  type: SET_SUBSCRIBERS,
  payload,
})

export const addNewSubscriber = payload => ({
  type: ADD_NEW_SUBSCRIBER,
  payload,
})

export const getSubscriberDetail = (params) => ({
  type: GET_SUBSCRIBER_DETAIL,
  payload: { params },
})

export const setSubscriberDetail = payload => ({
  type: SET_SUBSCRIBER_DETAIL,
  payload,
})


export const updateSubscriber = payload => ({
  type: UPDATE_SUBSCRIBER,
  payload,
})


export const deleteSubscriber = payload => ({
  type: DELETE_SUBSCRIBER,
  payload,
})


