
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_CONTACTS, GET_CONTACT_DETAIL , ADD_NEW_CONTACT , DELETE_CONTACT, UPDATE_CONTACT } from "./actionTypes"

import {
  setContactDetail,
  setContacts,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getContacts, getContactDetail, deleteContact, updateContact, addContact } from "../../helpers/backend_helper"


function* fetchContacts({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setContactDetail({}))
  try {
    const response = yield call(getContacts, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setContacts(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchContactDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getContactDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setContactDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateContact({ payload }) {
  const isInline = !!payload.inline;
  delete payload.inline;
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateContact, payload)
    yield put(setContactDetail(response.data))
    if (isInline) {
      const responseData = yield call(getContacts, {})
      yield put(setContacts(responseData))
    }
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Contact updated successfuly.', redirectUrl: isInline ? null : '/contact-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteContact({ payload: { id, isInline } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteContact, id)
    const responsePages = yield call(getContacts, {})
    yield put(setContacts(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Contact deleted successfuly.", redirectUrl: isInline ? null : '/contact-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewContact({ payload }) {
  const isInline = !!payload.inline;
  delete payload.inline;
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addContact, payload)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Contact added successfuly.', redirectUrl: isInline ? null : '/contact-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* contactsSaga() {
  yield takeEvery(GET_CONTACTS, fetchContacts)
  yield takeEvery(GET_CONTACT_DETAIL, fetchContactDetail)
  yield takeEvery(ADD_NEW_CONTACT, onAddNewContact)
  yield takeEvery(UPDATE_CONTACT, onUpdateContact)
  yield takeEvery(DELETE_CONTACT, onDeleteContact)
}

export default contactsSaga
