import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../validations';

import {
  addNewSubscriber,
  updateSubscriber,
  getSubscriberDetail as onGetSubscriberDetail,
  deleteSubscriber as onDeleteSubscriber
} from "store/subscribers/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import FileUpload from "components/FileUpload"
import LanguageSelect from "components/LanguageSelect"
import StateSelect from "components/StateSelect"


const SubscriberCreate = (props) => {
  const history = useHistory()

  const dispatch = useDispatch()

  const { subscribers } = useSelector(state => ({
    subscribers: state.subscribers.subscribers,
  }))


  useEffect(() => {
    setDataForce({
      email: '',
    })
  }, [])

  

  const validationList = ['email'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      email: '',
    },
  })

  const handleValidSubmit = () => {
    const params = {
      email: data.email,
    }
    dispatch(addNewSubscriber(params))
  }



  const seoTitle = props.t("page_info_create", { name: props.t("Subscribers") });


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Subscribers')} titleLink="/subscriber-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="email"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Email")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          invalid={errors.email}
                          onChange={handleChange('email')}
                          value={data.email}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Email")})}
                        />
                        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                          {props.t("create_btn_for_model", { name: props.t("Subscribers")})}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SubscriberCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SubscriberCreate)
