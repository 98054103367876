/* eslint-disable */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import RemoteTable from "components/Common/RemoteTable";

import {
  getChargingStations as onGetChargingStations,
  deleteChargingStation as onDeleteChargingStation,
} from "store/charging_stations/actions";

//redux
import { useSelector, useDispatch } from "react-redux";


const ChargingStationList = props => {
  const dispatch = useDispatch();

  const { chargingStations, loading } = useSelector(state => ({
    chargingStations: state.chargingStations.chargingStations,
    loading: state.chargingStations.loading,
  }));

	const [perPage, setPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState('');

  const badgeColor = {
    'AC': 'badge-soft-info',
    'DC': 'badge-soft-success',
  }

  const columns = [
    {
        name: '#',
        formatter: (cellContent, row) => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
            {row?.name?.toUpperCase()?.charAt(0)}
            </span>
          </div>
        ),
    },
    {
      text: props.t("Language"),
      dataField: "language",
    },
    {
      text: props.t("Name"),
      dataField: "name",
    },
    {
        text: props.t('Recommended'),
        formatter: (cellContent, row) => (
          <div>
            <span>{row?.recommended ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>}</span>
          </div>
        ),
    },
    {
      text: props.t("Type"),
      formatter: (cellContent, row) => (
        <span
          to="#"
          className={`badge ${badgeColor[row?.type]} font-size-11 m-1`}
        >
          {row?.type?.toUpperCase()}
        </span>
      ),
    },
    {
      text: props.t("Menu"),
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to={`/charging-station-edit/${row._id}`}>
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteChargingStation(row._id)}
            ></i>
          </Link>
        </div>
      )
    }
  ];

  const handleDeleteChargingStation = id => {
    if (id !== undefined && confirm('Are you sure?')) {
      const params = {
        page: chargingStations.page,
        limit: perPage
      };
      if (searchValue) {
        params['title[regex]'] = searchValue
      }
      
      dispatch(onDeleteChargingStation({ id, params }));
    }
  };

  useEffect(() => {
    dispatch(onGetChargingStations());
  }, []);

  
  const handleSearch = (inputValue) => {
    const params = {
      limit: perPage,
    }
    if (inputValue) {
      params['title[regex]'] = inputValue;
    }
    setSearchValue(inputValue);
    dispatch(onGetChargingStations(params));
  }


  const handleTableChange  = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    if (type == 'sort') return;
    setPerPage(sizePerPage);
    const params = {
      limit: sizePerPage,
      page: page
    }
    dispatch(onGetChargingStations(params));
  }


  const seoTitle = props.t("page_info_list", { name: props.t("Charging Station") });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Charging Station")} breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <RemoteTable
                    search
                    onSearch={handleSearch}
                    columns={columns}
                    data={chargingStations?.data || []}
                    page={chargingStations?.page || 1}
                    sizePerPage={perPage}
                    totalSize={chargingStations?.total || 0}
                    onTableChange={handleTableChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ChargingStationList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ChargingStationList)