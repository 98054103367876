import { SET_OUR_PROCESSS, SET_OUR_PROCESS_DETAIL } from "./actionTypes"

const INIT_STATE = {
  ourProcess: [],
  ourProcessDetail: {},
}

const ourProcess = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_OUR_PROCESSS:
      return {
        ...state,
        ourProcess: action.payload,
      }

    case SET_OUR_PROCESS_DETAIL:
      return {
        ...state,
        ourProcessDetail: action.payload,
      }
    default:
      return state
  }
}

export default ourProcess
