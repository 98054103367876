import { SET_REFERANCES, SET_REFERANCE_DETAIL } from "./actionTypes"

const INIT_STATE = {
  referances: [],
  referanceDetail: {},
}

const referance = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_REFERANCES:
      return {
        ...state,
        referances: action.payload,
      }

    case SET_REFERANCE_DETAIL:
      return {
        ...state,
        referanceDetail: action.payload,
      }
    default:
      return state
  }
}

export default referance
