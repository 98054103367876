
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_DYNAMIC_PAGES, GET_DYNAMIC_PAGE_DETAIL , ADD_NEW_DYNAMIC_PAGE , DELETE_DYNAMIC_PAGE, UPDATE_DYNAMIC_PAGE } from "./actionTypes"

import {
  setDynamicPageDetail,
  setDynamicPages,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getDynamicPages, getDynamicPageDetail, deleteDynamicPage, updateDynamicPage, addDynamicPage } from "../../helpers/backend_helper"


function* fetchDynamicPages({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setDynamicPageDetail({}))
  try {
    const response = yield call(getDynamicPages, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setDynamicPages(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchDynamicPageDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getDynamicPageDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setDynamicPageDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateDynamicPage({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateDynamicPage, payload)
    yield put(toggleActionLoading(false))
    yield put(setDynamicPageDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'DynamicPage updated successfuly.', redirectUrl: '/dynamic-page-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteDynamicPage({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteDynamicPage, id)
    const responsePages = yield call(getDynamicPages, { params })
    yield put(setDynamicPages(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "DynamicPage deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewDynamicPage({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addDynamicPage, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'DynamicPage added successfuly.', redirectUrl: '/dynamic-page-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* dynamicPagesSaga() {
  yield takeEvery(GET_DYNAMIC_PAGES, fetchDynamicPages)
  yield takeEvery(GET_DYNAMIC_PAGE_DETAIL, fetchDynamicPageDetail)
  yield takeEvery(ADD_NEW_DYNAMIC_PAGE, onAddNewDynamicPage)
  yield takeEvery(UPDATE_DYNAMIC_PAGE, onUpdateDynamicPage)
  yield takeEvery(DELETE_DYNAMIC_PAGE, onDeleteDynamicPage)
}

export default dynamicPagesSaga
