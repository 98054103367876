import { SET_SOLUTIONS, SET_SOLUTION_DETAIL } from "./actionTypes"

const INIT_STATE = {
  solutions: [],
  solutionDetail: {},
}

const solution = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SOLUTIONS:
      return {
        ...state,
        solutions: action.payload,
      }

    case SET_SOLUTION_DETAIL:
      return {
        ...state,
        solutionDetail: action.payload,
      }
    default:
      return state
  }
}

export default solution
