import { SET_PROJECTS, SET_PROJECT_DETAIL } from "./actionTypes"

const INIT_STATE = {
  projects: [],
  projectDetail: {},
}

const Project = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      }

    case SET_PROJECT_DETAIL:
      return {
        ...state,
        projectDetail: action.payload,
      }
    default:
      return state
  }
}

export default Project
