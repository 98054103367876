
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_ASSETS, ADD_NEW_ASSET, DELETE_ASSET } from "./actionTypes"

import {
  setAssets,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getAssets, addAsset, deleteAsset } from "../../helpers/backend_helper"


function* fetchAssets({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getAssets, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setAssets(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewAsset({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addAsset, user)
    const responseData = yield call(getAssets, { })
    yield put(setAssets(responseData))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Asset added successfuly.'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}


function* onDeleteAsset({ payload: { path, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteAsset, path)
    const responsePages = yield call(getAssets, { params })
    yield put(setAssets(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Asset deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}


function* assetsSaga() {
  yield takeEvery(GET_ASSETS, fetchAssets)
  yield takeEvery(ADD_NEW_ASSET, onAddNewAsset)
  yield takeEvery(DELETE_ASSET, onDeleteAsset)
}

export default assetsSaga
