import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../validations';

import {
  addNewHomeSlider,
  updateHomeSlider,
  getHomeSliderDetail as onGetHomeSliderDetail,
  deleteHomeSlider as onDeleteHomeSlider
} from "store/home_sliders/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import FileUpload from "components/FileUpload"
import LanguageSelect from "components/LanguageSelect"
import StateSelect from "components/StateSelect"


const HomeSliderCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { homeSliders, homeSliderDetail, formOptions } = useSelector(state => ({
    homeSliders: state.homeSliders.homeSliders,
    homeSliderDetail: state.homeSliders.homeSliderDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    if (id) {
      dispatch(onGetHomeSliderDetail(id))
    } else {
      setDataForce({
        language: '',
        title: '',
        short_title: '',
        info_text: '',
        content: '',
        url: '',
        info_cover: [],
        image_cover: [],
        state: 'published',
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(homeSliderDetail).length) {
      setDataForce({
        language: homeSliderDetail.language,
        title: homeSliderDetail.title,
        short_title: homeSliderDetail.short_title,
        info_text: homeSliderDetail.info_text,
        url: homeSliderDetail.url,
        content: homeSliderDetail.content,
        info_cover: homeSliderDetail?.info_cover ? [homeSliderDetail?.info_cover] : [],
        image_cover: homeSliderDetail?.image_cover ? [homeSliderDetail?.image_cover] : [],
        state: homeSliderDetail.state,
      })
    }
  }, [homeSliderDetail])
  

  const validationList = ['title', 'language','short_title', 'content', 'state'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      title: '',
      short_title: '',
      info_text: '',
      url: '',
      content: '',
      info_cover: [],
      image_cover: [],
      state: 'published'
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      title: data.title,
      language: data.language,
      short_title: data.short_title,
      url: data.url,
      info_text: data.info_text,
      content: data.content,
      state: data.state,
      info_cover: data.info_cover[0] || '',
      image_cover: data.image_cover[0] || '',
    }

    if (id) {
      dispatch(updateHomeSlider(params))
    } else {
      dispatch(addNewHomeSlider(params))
    }

  }


  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm(props.t('Are you sure?'))) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteHomeSlider({ id }))
      setTimeout(() => {
        history.push("/homeSlider-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Home Slider") }) : props.t("page_info_create", { name: props.t("Home Slider") });


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Home Slider')} titleLink="/home-sliders-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("State")}
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          t={props.t}
                          value={data?.state}
                          valueKey="state"
                          onChange={(key, value) => handleChange(key)(value)}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          invalid={errors.title}
                          onChange={handleChange('title')}
                          value={data.title}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Title")})}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="short_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Short Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="short_title"
                          name="short_title"
                          type="text"
                          invalid={errors.short_title}
                          onChange={handleChange('short_title')}
                          value={data?.short_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Short Title")})}
                        />
                        {errors.short_title && <FormFeedback>{errors.short_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="url"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("URL")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="url"
                          name="url"
                          type="text"
                          invalid={errors.url}
                          onChange={handleChange('url')}
                          value={data.url}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("URL")})}
                        />
                        {errors.url && <FormFeedback>{errors.url}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="content"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Content")}
                      </Label>
                      <Col lg="10">
                        <CKEditor
                          editor={ClassicEditor}
                          data={data.content}
                          onChange={(event, editor) => {
                            const editorData = editor.getData()
                            if (id) {
                              if (id && data?.content?.charAt(0) === '<') {
                                handleChange('content')(editorData)
                              }
                            } else {
                              handleChange('content')(editorData)
                            }
                          }}
                        />
                        {errors.content && <span className="error-form-field">{errors.content}</span>}
                      </Col>
                    </FormGroup>  
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="info_text"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Info Text")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="info_text"
                          name="info_text"
                          type="text"
                          invalid={errors.info_text}
                          onChange={handleChange('info_text')}
                          value={data?.info_text || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Info Text")})}
                        />
                        {errors.info_text && <FormFeedback>{errors.info_text}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Info Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("info_cover")(files)}
                          multiple={false}
                          images={data?.info_cover}
                          errorMessage={errors.info_cover}
                          valueKey="info_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'info_cover')}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover")(files)}
                          multiple={false}
                          images={data?.image_cover}
                          errorMessage={errors.image_cover}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover')}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Home Slider")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Home Slider")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Home Slider")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

HomeSliderCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(HomeSliderCreate)
