import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import {
  Button,
  Col,
  Input,
  Row,
  FormFeedback,
} from "reactstrap"

export default function TagInput({
  onChange,
  t,
  valueKey,
  errorMessage,
  tags,
}) {
  const [tagField, setTagField] = useState([{ id: 1, text: '' }]);

  useEffect(() => {
    if (tags?.length) {
      setTagField(tags.map((tag, idx) => ({ id: idx + 1, text: tag  })));
    } else if (!tags?.length && (!tagField?.length || !tagField?.[0]?.title )) {
      setTagField([{ id: 1, text: '' }]);
    }

  }, [tags]);

  const handleAddRowNested = () => {
    const modifiedTagFields = [...tagField]
    modifiedTagFields.push({ id: modifiedTagFields.length + 1, text: '' })
    setTagField(modifiedTagFields)
  }

  const handleRemoveRow = (id) => {
    if (id !== 1) {
      var modifiedTagFields = [...tagField]
      modifiedTagFields = modifiedTagFields.filter(x => x["id"] !== id)
      setTagField(modifiedTagFields)
      onChange(valueKey, modifiedTagFields.map(t => t.text))
    }
  }

  const handleTagChange = (e, row) => {    
    const modifiedTagFields = [...tagField]
    modifiedTagFields.forEach(t => {
      if (t.id === row.id) {
        t.text = e.target.value
      }
    })
    setTagField(modifiedTagFields)
    onChange(valueKey, modifiedTagFields.map(t => t.id == row.id ? e.target.value : t.text))
  }

  return (
    <div className="inner-repeater mb-4">
      <table style={{ width: "100%" }}>
        <tbody>
          {(tagField || []).map((formRow, key) => (
            <tr key={key}>
              <td>
                <Row className="mb-2">
                  <Col md="10">
                    <Input
                      type="text"
                      className="inner form-control"
                      placeholder={t('form_placeholder', { name: t("Tag")})}
                      value={formRow.text}
                      invalid={errorMessage && !tags[key]}
                      onChange={(e) => handleTagChange(e, formRow)}
                    />
                    {errorMessage && !tags[key] && <FormFeedback>{errorMessage}</FormFeedback>}
                  </Col>
                  <Col md="2">
                    <Button
                      color="primary"
                      className="btn-block inner"
                      id="unknown-btn"
                      style={{ width: "100%" }}
                      onClick={e => {
                        handleRemoveRow(formRow.id)
                      }}
                    >
                      {t("Delete")}
                    </Button>
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        onClick={() => {
          handleAddRowNested()
        }}
        color="success"
        className="mt-1"
      >
        {t("Add")}
      </Button>
    </div>
  )
}

TagInput.propTypes = {
  valueKey: PropTypes.string,
  tags: PropTypes.any,
  errorMessage: PropTypes.any,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDeleteFile: PropTypes.func,
  t: PropTypes.any,
}
