import {
  GET_LANGUAGE_DETAIL,
  SET_LANGUAGE_DETAIL,
  GET_LANGUAGES,
  SET_LANGUAGES,
  ADD_NEW_LANGUAGE,
  UPDATE_LANGUAGE,
  DELETE_LANGUAGE,

} from "./actionTypes"


export const getLanguages = (params) => ({
  type: GET_LANGUAGES,
  payload: { params },
})

export const setLanguages = payload => ({
  type: SET_LANGUAGES,
  payload,
})

export const addNewLanguage = payload => ({
  type: ADD_NEW_LANGUAGE,
  payload,
})

export const getLanguageDetail = (params) => ({
  type: GET_LANGUAGE_DETAIL,
  payload: { params },
})

export const setLanguageDetail = payload => ({
  type: SET_LANGUAGE_DETAIL,
  payload,
})


export const updateLanguage = payload => ({
  type: UPDATE_LANGUAGE,
  payload,
})


export const deleteLanguage = payload => ({
  type: DELETE_LANGUAGE,
  payload,
})
