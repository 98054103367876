import { SET_PRODUCTS, SET_PRODUCT_DETAIL } from "./actionTypes"

const INIT_STATE = {
  products: [],
  productDetail: {},
}

const Product = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }

    case SET_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: action.payload,
      }
    default:
      return state
  }
}

export default Product
