import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"

import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../validations';

import {
  addNewFaq,
  updateFaq,
  getFaqDetail as onGetFaqDetail,
  deleteFaq as onDeleteFaq
} from "store/faqs/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import LanguageSelect from "components/LanguageSelect"
import StateSelect from "components/StateSelect"


const FaqCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { faqs, faqDetail, formOptions } = useSelector(state => ({
    faqs: state.faqs.faqs,
    faqDetail: state.faqs.faqDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    if (id) {
      dispatch(onGetFaqDetail(id))
    } else {
      setDataForce({
        language: '',
        title: '',
        content: '',
        state: 'published',
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(faqDetail).length) {
      setDataForce({
        language: faqDetail.language,
        title: faqDetail.title,
        content: faqDetail.content,
        state: faqDetail.state,
      })
    }
  }, [faqDetail])
  

  const validationList = ['title', 'language','content','state'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      title: '',
      content: '',
      state: 'published'
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      title: data.title,
      language: data.language,
      content: data.content,
      state: data.state,
    }

    if (id) {
      dispatch(updateFaq(params))
    } else {
      dispatch(addNewFaq(params))
    }

  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteFaq({ id }))
      setTimeout(() => {
        history.push("/faq-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Faqs") }) : props.t("page_info_create", { name: props.t("Faqs") });


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Faqs')} titleLink="/faq-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          invalid={errors.title}
                          onChange={handleChange('title')}
                          value={data.title}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Title")})}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("State")}
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          t={props.t}
                          value={data?.state}
                          valueKey="state"
                          onChange={(key, value) => handleChange(key)(value)}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="content"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Content")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="content"
                          name="content"
                          type="textarea"
                          rows={5}
                          invalid={errors.content}
                          onChange={handleChange('content')}
                          value={data?.content || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Content")})}
                        />
                        {errors.content && <FormFeedback>{errors.content}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Faqs")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Faqs")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Faqs")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

FaqCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(FaqCreate)
