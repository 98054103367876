import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import formSaga from "./form/saga"
import languagesSaga from "./languages/saga"
import contactsSaga from "./contacts/saga"
import calculationsSaga from "./calculations/saga"
import usersSaga from "./users/saga"
import globalSaga from "./global/saga"
import dynamicPagesSaga from "./dynamic_pages/saga"
import blogPostsSaga from "./blog_posts/saga"
import ourProcessSaga from "./our_process/saga"
import solutionsSaga from "./solutions/saga"
import referancesSaga from "./referances/saga"
import subscribersSaga from "./subscribers/saga"
import chargingStationsSaga from "./charging_stations/saga"
import directorsSaga from "./directors/saga"
import faqsSaga from "./faqs/saga"
import sustainablesSaga from "./sustainables/saga"
import ourValuesSaga from "./our_values/saga"
import assetsSaga from "./assets/saga"
import product_categoriesSaga from "./products/product_categories/saga"
import productsSaga from "./products/products/saga"
import ordersSaga from "./products/orders/saga"
import project_categoriesSaga from "./projects/project_categories/saga"
import projectsSaga from "./projects/projects/saga"
import homeSlidersSaga from "./home_sliders/saga"
import videoSlidersSaga from "./video_sliders/saga"
import distributorship_categoriesSaga from "./distributorships/distributorship_categories/saga"
import distributorshipSaga from "./distributorships/distributorship/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(formSaga),
    fork(languagesSaga),
    fork(contactsSaga),
    fork(calculationsSaga),
    fork(usersSaga),
    fork(globalSaga),
    fork(dynamicPagesSaga),
    fork(blogPostsSaga),
    fork(ourProcessSaga),
    fork(solutionsSaga),
    fork(referancesSaga),
    fork(subscribersSaga),
    fork(chargingStationsSaga),
    fork(directorsSaga),
    fork(faqsSaga),
    fork(sustainablesSaga),
    fork(ourValuesSaga),
    fork(assetsSaga),
    fork(product_categoriesSaga),
    fork(productsSaga),
    fork(ordersSaga),

    fork(project_categoriesSaga),
    fork(projectsSaga),
    
    fork(homeSlidersSaga),
    fork(videoSlidersSaga),

    fork(distributorship_categoriesSaga),
    fork(distributorshipSaga)
  ])
}
