import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"


const Login = props => {
  const dispatch = useDispatch()

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{props.t("Login")} | Dragon CMS - DaseinSoftware Hub</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("Welcome Back !")}</h5>
                        <p>{props.t("Sign in to continue to Dragon CMS")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div style={{ position: 'absolute', right: 10 }}>
                    <span>{props.t("Language")}: </span> <LanguageDropdown />
                  </div>
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          className="form-control"
                          placeholder={props.t("form_placeholder", { name: props.t("Email") })}
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label={props.t("Password")}
                          type="password"
                          required
                          placeholder={props.t("form_placeholder", { name: props.t("Password") })}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {props.t("Login")}
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t("Don't have an account ?")}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    {props.t("Register")}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Dragon CMS. {props.t("Powered by")}{" "}
                  <i className="mdi mdi-heart text-danger" /> <a href="https://daseinworld.com" target="_blank" rel="noreferrer">Dasein Software</a> 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


Login.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(withRouter(Login))

