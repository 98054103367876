import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"

export default function LanguageSelect({
  onChange,
  t,
  valueKey,
  errorMessage,
  value,
}) {

  const { formOptions } = useSelector(state => ({
    formOptions: state.form
  }))


  const selectedVal = value && formOptions?.languageOptions ? formOptions?.languageOptions?.find(ln => ln.value == value) || null : null;
  return (
    <>
      <Select
          id={valueKey}
          name={valueKey}
          className={errorMessage ? 'react-select-error' : ''}
          value={selectedVal}
          onChange={(e) => onChange(valueKey, e.value)}
          options={formOptions.languageOptions}
          classNamePrefix="select2-selection"
          isLoading={formOptions.loading}
          placeholder={t('form_placeholder', { name: t("Language")})}
        />
        {errorMessage && <span className="error-form-field">{errorMessage}</span>}
    </>
  )
}

LanguageSelect.propTypes = {
  valueKey: PropTypes.string,
  value: PropTypes.any,
  errorMessage: PropTypes.any,
  onChange: PropTypes.func,
  t: PropTypes.any,
}
