import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

const SocialSource = () => {
  const socials = [
    {
      title: "Instagram",
      bgColor: "bg-danger",
      iconClass: "mdi-instagram",
      href: "https://www.instagram.com/DaseinSoftware/"
    },
    {
      title: "Whatsapp",
      bgColor: "bg-success",
      iconClass: "mdi-whatsapp",
      href: "https://wa.me/+902166062131"
    },
    {
      title: "Linkedin",
      bgColor: "bg-primary",
      iconClass: "mdi-linkedin",
      href: "https://www.linkedin.com/company/DaseinSoftware/"
    },
  ]

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">DaseinSoftware Hub 🚀 </CardTitle>
          <div className="text-center">
            <p className="font-16 text-muted mb-2"></p>
            {/* <h5>
              <Link to="https://www.instagram.com/DaseinSoftware/" target={"_blank"} className="text-dark">
                Dragon CMS
              </Link>
            </h5> */}
            {/* <p className="text-muted">
              Buraya CMS ile ilgili yazılacak dökümanın tanıtım yazısı yazılmalı.
            </p> */}
            <a href="http://test.dkminsaat.com/use-dashboard" target={"_blank"} className="text-primary font-16" rel="noreferrer">
              Dökümana Git <i className="mdi mdi-chevron-right"></i>
            </a>
          </div>
          {/* <Row className="mt-5">
            {socials.map((social, key) => (
              <Col xs="4" key={"_li_" + key}>
                <Link to={social.href} target={"_blank"}>
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">

                      <span
                        className={
                          "avatar-title rounded-circle " +
                          social.bgColor +
                          " font-size-16"
                        }
                      >
                        <i
                          className={"mdi " + social.iconClass + " text-white"}
                        ></i>
                      </span>
                    </div>
                    <h5 className="font-size-15">{social.title}</h5>
                  </div>
                </Link>
              </Col>
            ))}
          </Row> */}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SocialSource
