export const GET_USERS = "GET_USERS"
export const SET_USERS = "SET_USERS"
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const SET_USER_DETAIL = "SET_USER_DETAIL"
export const ADD_NEW_USER = "ADD_NEW_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER = "DELETE_USER"
export const UPDATE_USER_INLINE = "UPDATE_USER_INLINE"
export const ADD_NEW_USER_INLINE = "ADD_NEW_USER_INLINE"

