
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PRODUCT_CATEGORIES, GET_PRODUCT_CATEGORY_DETAIL , ADD_NEW_PRODUCT_CATEGORY , DELETE_PRODUCT_CATEGORY, UPDATE_PRODUCT_CATEGORY } from "./actionTypes"

import {
  setProductCategoryDetail,
  setProductCategories,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../../form/actions"

import { getProductCategories, getProductCategoryDetail, deleteProductCategory, updateProductCategory, addProductCategory } from "../../../helpers/backend_helper"


function* fetchProductCategories({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setProductCategoryDetail({}))
  try {
    const response = yield call(getProductCategories, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setProductCategories(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchProductCategoryDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getProductCategoryDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setProductCategoryDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateProductCategory({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateProductCategory, payload)
    yield put(toggleActionLoading(false))
    yield put(setProductCategoryDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'ProductCategory updated successfuly.', redirectUrl: '/products/product-category-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteProductCategory({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteProductCategory, id)
    const responsePages = yield call(getProductCategories, { params })
    yield put(setProductCategories(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "ProductCategory deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewProductCategory({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addProductCategory, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'ProductCategory added successfuly.', redirectUrl: '/products/product-category-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* ProductCategoriesSaga() {
  yield takeEvery(GET_PRODUCT_CATEGORIES, fetchProductCategories)
  yield takeEvery(GET_PRODUCT_CATEGORY_DETAIL, fetchProductCategoryDetail)
  yield takeEvery(ADD_NEW_PRODUCT_CATEGORY, onAddNewProductCategory)
  yield takeEvery(UPDATE_PRODUCT_CATEGORY, onUpdateProductCategory)
  yield takeEvery(DELETE_PRODUCT_CATEGORY, onDeleteProductCategory)
}

export default ProductCategoriesSaga
