// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapperMap__12Nf5 {\n  position: relative;\n  width: 100%;\n  height: 250px;\n  margin: 15px 0 0 0;\n}\n\n.styles_logo__3-vQX {\n  text-align: right;\n  padding: 0.25rem 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/Map/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":[".wrapperMap {\n  position: relative;\n  width: 100%;\n  height: 250px;\n  margin: 15px 0 0 0;\n}\n\n.logo {\n  text-align: right;\n  padding: 0.25rem 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperMap": "styles_wrapperMap__12Nf5",
	"logo": "styles_logo__3-vQX"
};
export default ___CSS_LOADER_EXPORT___;
