import {
  GET_CHARGING_STATION_DETAIL,
  SET_CHARGING_STATION_DETAIL,
  GET_CHARGING_STATIONS,
  SET_CHARGING_STATIONS,
  ADD_NEW_CHARGING_STATION,
  UPDATE_CHARGING_STATION,
  DELETE_CHARGING_STATION,
} from "./actionTypes"

export const getChargingStations = (params) => ({
  type: GET_CHARGING_STATIONS,
  payload: { params },
})

export const setChargingStations = payload => ({
  type: SET_CHARGING_STATIONS,
  payload,
})

export const addNewChargingStation = payload => ({
  type: ADD_NEW_CHARGING_STATION,
  payload,
})

export const getChargingStationDetail = (params) => ({
  type: GET_CHARGING_STATION_DETAIL,
  payload: { params },
})

export const setChargingStationDetail = payload => ({
  type: SET_CHARGING_STATION_DETAIL,
  payload,
})


export const updateChargingStation = payload => ({
  type: UPDATE_CHARGING_STATION,
  payload,
})


export const deleteChargingStation = payload => ({
  type: DELETE_CHARGING_STATION,
  payload,
})


