import React from "react"
import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard"

// Dashboard
import Global from "../pages/Global"

// Home Slider Component
import LanguageList from "../pages/Languages/List"
import LanguageCreateOrEdit from "../pages/Languages/CreateOrEdit"

// Profile
import UserProfile from "../pages/Authentication/user-profile"


// Contacts
import ContactList from "../pages/Contacts/ContactList"
import ContactDetail from "../pages/Contacts/ContactDetail"
import CreateContact from "../pages/Contacts/CreateContact"

// Contacts
import CalculationList from "../pages/Calculations/CalculationList"

// Users
import UserList from "../pages/Users/List"
import CreateUser from "../pages/Users/Create"

// Dynamic Pages
import DynamicPageList from "../pages/DynamicPages/List"
import DynamicPageCreateOrEdit from "../pages/DynamicPages/CreateOrEdit"

// Blog Posts
import BlogPostList from "../pages/BlogPosts/List"
import BlogPostCreateOrEdit from "../pages/BlogPosts/CreateOrEdit"

// Dynamic Pages
import SolutionList from "../pages/Solutions/List"
import SolutionCreateOrEdit from "../pages/Solutions/CreateOrEdit"

// Our Process
import OurProcessList from "../pages/OurProcess/List"
import OurProcessCreateOrEdit from "../pages/OurProcess/CreateOrEdit"

// Referances
import ReferanceList from "../pages/Referances/List"
import ReferanceCreateOrEdit from "../pages/Referances/CreateOrEdit"

// Charging Station
import ChargingStationList from "../pages/ChargingStations/List"
import ChargingStationCreateOrEdit from "../pages/ChargingStations/CreateOrEdit"

// Subscribers
import SubscriberList from "../pages/Subscribers/SubscriberList"
import SubscriberDetail from "../pages/Subscribers/SubscriberDetail"
import CreateSubscriber from "../pages/Subscribers/CreateSubscriber"

// Faqs
import FaqList from "../pages/FAQs/List"
import FaqCreateOrEdit from "../pages/FAQs/CreateOrEdit"

// Sustainables
import SustainableList from "../pages/Sustainables/List"
import SustainableCreateOrEdit from "../pages/Sustainables/CreateOrEdit"

// Our Values
import OurValueList from "../pages/OurValues/List"
import OurValueCreateOrEdit from "../pages/OurValues/CreateOrEdit"

// Directors
import DirectorList from "../pages/Directors/List"
import DirectorCreateOrEdit from "../pages/Directors/CreateOrEdit"

// Success Pages
import MailConfirm from "../pages/Success/MailConfirm"

// Product
import ProductCategoryPageList from "../pages/Products/ProductCategories/List"
import ProductCategoryCreateOrEdit from "../pages/Products/ProductCategories/CreateOrEdit"

import ProductPageList from "../pages/Products/Product/List"
import ProductCreateOrEdit from "../pages/Products/Product/CreateOrEdit"

import OrderPageList from "../pages/Products/Orders/List"
import OrderDetail from "../pages/Products/Orders/Detail"

// Project
import ProjectCategoryPageList from "../pages/Projects/ProjectCategories/List"
import ProjectCategoryCreateOrEdit from "../pages/Projects/ProjectCategories/CreateOrEdit"

import ProjectPageList from "../pages/Projects/Project/List"
import ProjectCreateOrEdit from "../pages/Projects/Project/CreateOrEdit"

// HomeSlider
import HomeSliderList from "../pages/HomeSliders/List"
import HomeSliderCreateOrEdit from "../pages/HomeSliders/CreateOrEdit"

// VideoSlider
import VideoSliderList from "../pages/VideoSliders/List"
import VideoSliderCreateOrEdit from "../pages/VideoSliders/CreateOrEdit"

// Distributorship
import DistributorshipCategoryPageList from "../pages/Distributorships/DistributorshipCategories/List"
import DistributorshipCategoryCreateOrEdit from "../pages/Distributorships/DistributorshipCategories/CreateOrEdit"
import DistributorshipList from "../pages/Distributorships/Distributorship/List"
import DistributorshipCreateOrEdit from "../pages/Distributorships/Distributorship/CreateOrEdit"

// Error Pages
import Pages500 from "../pages/Error/pages-500"
import Pages404 from "../pages/Error/pages-404"
import ChangeLanguage from "../pages/Error/change-language"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  
  // Global
  { path: "/global", component: Global },

  // Languages Component
  { path: "/language-list", component: LanguageList },
  { path: "/language-create", component: LanguageCreateOrEdit },
  { path: "/language-edit/:id", component: LanguageCreateOrEdit },

  // Account Profile
  { path: "/profile", component: UserProfile },

  // Contacts
  { path: "/contact-list", component: ContactList },
  { path: "/contact-list/:id", component: ContactDetail },
  { path: "/contact-create", component: CreateContact },
  
  // Contacts
  { path: "/calculation-list", component: CalculationList },

  // Users
  { path: "/user-list", component: UserList },
  { path: "/user-create", component: CreateUser },

  // Dynamic Pages
  { path: "/dynamic-page-list", component: DynamicPageList },
  { path: "/dynamic-page-create", component: DynamicPageCreateOrEdit },
  { path: "/dynamic-page-edit/:id", component: DynamicPageCreateOrEdit },

  // Blog Posts
  { path: "/blog-post-list", component: BlogPostList },
  { path: "/blog-post-create", component: BlogPostCreateOrEdit },
  { path: "/blog-post-edit/:id", component: BlogPostCreateOrEdit },
  
  // Our Process
  { path: "/our-process-list", component: OurProcessList },
  { path: "/our-process-create", component: OurProcessCreateOrEdit },
  { path: "/our-process-edit/:id", component: OurProcessCreateOrEdit },
  
  // Solutions
  { path: "/solution-list", component: SolutionList },
  { path: "/solution-create", component: SolutionCreateOrEdit },
  { path: "/solution-edit/:id", component: SolutionCreateOrEdit },
  
  // Referances
  { path: "/referance-list", component: ReferanceList },
  { path: "/referance-create", component: ReferanceCreateOrEdit },
  { path: "/referance-edit/:id", component: ReferanceCreateOrEdit },
  
  // Subscribers
  { path: "/subscriber-list", component: SubscriberList },
  { path: "/subscriber-edit/:id", component: SubscriberDetail },
  { path: "/subscriber-create", component: CreateSubscriber },

  // Charging Stations
  { path: "/charging-station-list", component: ChargingStationList },
  { path: "/charging-station-create", component: ChargingStationCreateOrEdit },
  { path: "/charging-station-edit/:id", component: ChargingStationCreateOrEdit },

  // Faq
  { path: "/faq-list", component: FaqList },
  { path: "/faq-create", component: FaqCreateOrEdit },
  { path: "/faq-edit/:id", component: FaqCreateOrEdit },

  // Sustainables
  { path: "/sustainable-list", component: SustainableList },
  { path: "/sustainable-create", component: SustainableCreateOrEdit },
  { path: "/sustainable-edit/:id", component: SustainableCreateOrEdit },
  
  // Our Values
  { path: "/ourvalue-list", component: OurValueList },
  { path: "/ourvalue-create", component: OurValueCreateOrEdit },
  { path: "/ourvalue-edit/:id", component: OurValueCreateOrEdit },

  // Our Values
  { path: "/director-list", component: DirectorList },
  { path: "/director-create", component: DirectorCreateOrEdit },
  { path: "/director-edit/:id", component: DirectorCreateOrEdit },


  // Product
  { path: "/products/product-category-list", component: ProductCategoryPageList },
  { path: "/products/product-category-create", component: ProductCategoryCreateOrEdit },
  { path: "/products/product-category-edit/:id", component: ProductCategoryCreateOrEdit },
  
  { path: "/products/product-list", component: ProductPageList },
  { path: "/products/product-create", component: ProductCreateOrEdit },
  { path: "/products/product-edit/:id", component: ProductCreateOrEdit },

  { path: "/products/order-list", component: OrderPageList },
  { path: "/products/order-detail/:id", component: OrderDetail },

  // Project
  { path: "/projects/project-category-list", component: ProjectCategoryPageList },
  { path: "/projects/project-category-create", component: ProjectCategoryCreateOrEdit },
  { path: "/projects/project-category-edit/:id", component: ProjectCategoryCreateOrEdit },
  
  { path: "/projects/project-list", component: ProjectPageList },
  { path: "/projects/project-create", component: ProjectCreateOrEdit },
  { path: "/projects/project-edit/:id", component: ProjectCreateOrEdit },

  // Home Sliders
  { path: "/home-sliders-list", component: HomeSliderList },
  { path: "/home-sliders-create", component: HomeSliderCreateOrEdit },
  { path: "/home-sliders-edit/:id", component: HomeSliderCreateOrEdit },

  // Video Sliders
  { path: "/video-sliders-list", component: VideoSliderList },
  { path: "/video-sliders-create", component: VideoSliderCreateOrEdit },
  { path: "/video-sliders-edit/:id", component: VideoSliderCreateOrEdit },

  // Distributorships
  { path: "/distributorships/distributorship-category-list", component: DistributorshipCategoryPageList },
  { path: "/distributorships/distributorship-category-create", component: DistributorshipCategoryCreateOrEdit },
  { path: "/distributorships/distributorship-category-edit/:id", component: DistributorshipCategoryCreateOrEdit },

  { path: "/distributorships/distributorship-list", component: DistributorshipList },
  { path: "/distributorships/distributorship-create", component: DistributorshipCreateOrEdit },
  { path: "/distributorships/distributorship-edit/:id", component: DistributorshipCreateOrEdit },


  // Error Pages
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
  { path: "/change-language", component: ChangeLanguage },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  { path: "*", component: Pages404 },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/confirm-mail/:token", component: MailConfirm },
]

export { publicRoutes, authProtectedRoutes }
