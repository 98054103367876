import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../../validations';

import {
  addNewProduct,
  updateProduct,
  getProductDetail as onGetProductDetail,
  deleteProduct as onDeleteProduct
} from "store/products/products/actions"

import {
  getProductCategoryOptions,
  getProductPropertyOptions,
} from "store/form/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import FileUpload from "components/FileUpload"
import LanguageSelect from "components/LanguageSelect"
import CategorySelect from "components/CategorySelect"
import StateSelect from "components/StateSelect"
import ProductProperties from "components/ProductProperties"
import TagInput from "components/TagInput"


const ProductCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { products, productDetail, formOptions } = useSelector(state => ({
    products: state.products.products,
    productDetail: state.products.productDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    dispatch(getProductCategoryOptions())
    if (id) {
      dispatch(onGetProductDetail(id))
    } else {
      setDataForce({
        language: '',
        title: '',
        short_title: '',
        video_urls: [],
        // price: '',
        // quantity: '',
        meta_title: '',
        meta_description: '',
        description: '',
        images: [],
        image_cover: [],
        state: 'published',
        category: null,
        properties: [],
      })
    }
  }, [id])

  useEffect(() => {
    if (id && Object.keys(productDetail).length) {
      setDataForce({
        language: productDetail.language,
        title: productDetail.title,
        short_title: productDetail.short_title,
        video_urls: productDetail?.video_urls || [],
        // price: productDetail.price,
        // quantity: productDetail.quantity,
        meta_title: productDetail.meta_title,
        meta_description: productDetail.meta_description,
        description: productDetail.description,
        image_cover: productDetail?.image_cover ? [productDetail?.image_cover] : [],
        images: productDetail?.images || [],
        state: productDetail.state,
        category: productDetail.category?.['_id'] || null,
        properties: productDetail.properties,
      })
    }
  }, [productDetail])

  useEffect(() => {
    setDataForce({
      ...data,
      properties: formOptions?.productPropertyOptions || []
    })
  }, [formOptions.productPropertyOptions])
  

  const validationList = ['title', 'language','short_title','meta_title','meta_description','state', 'description'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      title: '',
      short_title: '',
      video_urls: [],
      // price: '',
      // quantity: '',
      meta_title: '',
      meta_description: '',
      description: '',
      image_cover: [],
      images: [],
      state: 'published',
      category: null,
      properties: []
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      title: data.title,
      language: data.language,
      short_title: data.short_title,
      video_urls: data.video_urls,
      // price: data.price,
      // quantity: data.quantity,
      meta_title: data.meta_title,
      meta_description: data.meta_description,
      description: data.description,
      state: data.state,
      category: data.category,
      image_cover: data.image_cover[0] || '',
      images: data.images,
      properties: data.properties,
    }

    if (id) {
      dispatch(updateProduct(params))
    } else {
      dispatch(addNewProduct(params))
    }

  }


  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm(props.t('Are you sure?'))) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteProduct({ id }))
      setTimeout(() => {
        history.push("/products/product-category-list")
      }, 300)
    }
  }

  const handleChangeCategory = (key, value) => {
    handleChange(key)(value);
    dispatch(getProductPropertyOptions(value))
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Product") }) : props.t("page_info_create", { name: props.t("Product") });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Product Categories')} titleLink="/products/product-category-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          invalid={errors.title}
                          onChange={handleChange('title')}
                          value={data.title}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Title")})}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="category"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Category")}
                      </Label>
                      <Col lg="10">
                        <CategorySelect
                          t={props.t}
                          value={data?.category}
                          valueKey="category"
                          errorMessage={errors.category}
                          onChange={handleChangeCategory}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("State")}
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          t={props.t}
                          value={data?.state}
                          valueKey="state"
                          onChange={(key, value) => handleChange(key)(value)}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="short_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Short Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="short_title"
                          name="short_title"
                          type="text"
                          invalid={errors.short_title}
                          onChange={handleChange('short_title')}
                          value={data?.short_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Short Title")})}
                        />
                        {errors.short_title && <FormFeedback>{errors.short_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="meta_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Meta Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="meta_title"
                          name="meta_title"
                          type="text"
                          invalid={errors.meta_title}
                          onChange={handleChange('meta_title')}
                          value={data?.meta_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Meta Title")})}
                        />
                        {errors.meta_title && <FormFeedback>{errors.meta_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="meta_description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Meta Description")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="meta_description"
                          name="meta_description"
                          type="text"
                          invalid={errors.meta_description}
                          onChange={handleChange('meta_description')}
                          value={data?.meta_description || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Meta Description")})}
                        />
                        {errors.meta_description && <FormFeedback>{errors.meta_description}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Content")}
                      </Label>
                      <Col lg="10">
                        <CKEditor
                          editor={ClassicEditor}
                          data={data.description}
                          onChange={(event, editor) => {
                            const editorData = editor.getData()
                            if (id) {
                              if (id && data?.description?.charAt(0) === '<') {
                                handleChange('description')(editorData)
                              }
                            } else {
                              handleChange('description')(editorData)
                            }
                          }}
                        />
                        {errors.description && <span className="error-form-field">{errors.description}</span>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Video URLs")}
                      </Label>
                      <Col lg="10">
                        <TagInput
                          t={props.t}
                          tags={data?.video_urls || []}
                          errorMessage={errors.video_urls}
                          valueKey="video_urls"
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup> 
                    {/* <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="price"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Price")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="price"
                          name="price"
                          type="number"
                          invalid={errors.price}
                          onChange={handleChange('price')}
                          value={data?.price || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Price")})}
                        />
                        {errors.price && <FormFeedback>{errors.price}</FormFeedback>}
                      </Col>
                    </FormGroup>               
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="quantity"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Quantity")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="quantity"
                          name="quantity"
                          type="number"
                          invalid={errors.quantity}
                          onChange={handleChange('quantity')}
                          value={data?.quantity || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Quantity")})}
                        />
                        {errors.quantity && <FormFeedback>{errors.quantity}</FormFeedback>}
                      </Col>
                    </FormGroup>                */}
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover")(files)}
                          multiple={false}
                          images={data?.image_cover}
                          errorMessage={errors.image_cover}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover')}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Images")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("images")(files)}
                          multiple
                          images={data?.images}
                          errorMessage={errors.images}
                          valueKey="images"
                          onDeleteFile={(f) => handleDeleteFile(f, 'images')}
                        />
                      </Col>
                    </Row>
                    {data?.category && data?.properties?.length ? (
                      <Row className="mb-4">
                        <Label className="col-form-label col-lg-2">
                        </Label>
                        <Col lg="10">
                          <ProductProperties
                            t={props.t}
                            errors={errors}
                            properties={data?.properties || []}
                            errorMessage={errors.properties}
                            valueKey="properties"
                            onChange={(key, value) => handleChange(key)(value)}
                          />
                        </Col>
                      </Row>
                    ) : null}
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Product")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Product")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Product")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProductCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductCreate)
