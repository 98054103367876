import {
  GET_GLOBAL_DETAIL,
  SET_GLOBAL_DETAIL,
  UPDATE_GLOBAL,
} from "./actionTypes"


export const getGlobalDetail = (params) => ({
  type: GET_GLOBAL_DETAIL,
  payload: { params },
})

export const setGlobalDetail = payload => ({
  type: SET_GLOBAL_DETAIL,
  payload,
})

export const updateGlobal = payload => ({
  type: UPDATE_GLOBAL,
  payload,
})
