import { SET_CONTACTS, SET_CONTACT_DETAIL } from "./actionTypes"

const INIT_STATE = {
  contacts: [],
  contactDetail: {},
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      }

    case SET_CONTACT_DETAIL:
      return {
        ...state,
        contactDetail: action.payload,
      }
    default:
      return state
  }
}

export default contacts
