import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../validations';

import {
  addNewContact,
  updateContact,
  getContactDetail as onGetContactDetail,
  deleteContact as onDeleteContact
} from "store/contacts/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import LanguageSelect from "components/LanguageSelect"
import CreateContactForm from "../CreateContactForm"

const ContactCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { contacts, contactDetail, formOptions } = useSelector(state => ({
    contacts: state.contacts.contacts,
    contactDetail: state.contacts.contactDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    if (id) {
      dispatch(onGetContactDetail(id))
    } else {
      setDataForce({
        language: '',
        email: '',
        full_name: '',
        subject: '',
        message: '',
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(contactDetail).length) {
      setDataForce({
        language: contactDetail.language,
        email: contactDetail.email,
        full_name: contactDetail.full_name,
        subject: contactDetail.subject,
        message: contactDetail.message,
      })
    }
  }, [contactDetail])
  

  const validationList = ['email', 'language', 'full_name','subject','message'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      email: '',
      full_name: '',
      subject: '',
      message: '',
    },
  })

  const handleValidSubmit = () => {
    const params = {
      full_name: data.full_name,
      language: data.language,
      email: data.email,
      subject: data.subject,
      message: data.message,
    };

    if (id) {
      dispatch(updateContact({id, ...params}))
    } else {
      dispatch(addNewContact(params))
    }

  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteContact({ id }))
      setTimeout(() => {
        history.push("/contact-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Contact") }) : props.t("page_info_create", { name: props.t("Contact") });


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Contacts')} titleLink="/dynamic-page-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <CreateContactForm />

                  {/* <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="email"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Email")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          disabled={id}
                          invalid={errors.email}
                          onChange={handleChange('email')}
                          value={data.email}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Email")})}
                        />
                        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="full_name"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Full Name")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="full_name"
                          name="full_name"
                          type="text"
                          invalid={errors.full_name}
                          onChange={handleChange('full_name')}
                          value={data.full_name}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Full Name")})}
                        />
                        {errors.full_name && <FormFeedback>{errors.full_name}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="subject"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Subject")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="subject"
                          name="subject"
                          type="text"
                          invalid={errors.subject}
                          onChange={handleChange('subject')}
                          value={data.subject}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Subject")})}
                        />
                        {errors.subject && <FormFeedback>{errors.subject}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="message"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Message")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="message"
                          name="message"
                          invalid={errors.message}
                          type="textarea"
                          rows={5}
                          onChange={handleChange('message')}
                          value={data?.message || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Message")})}
                        />
                        {errors.message && <FormFeedback>{errors.message}</FormFeedback>}
                      </Col>
                    </FormGroup>                
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Contact")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Contact")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Contact")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ContactCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ContactCreate)
