import React, { useState } from 'react'

const useForm = (options) => {
  const [data, setData] = useState((options?.initialValues || {}))
  const [errors, setErrors] = useState({})

  const handleChange = (key, sanitizeFn) => (
    e,
  ) => {
    const value = e.target ? (sanitizeFn ? sanitizeFn(e.target.value) : e.target.value) : e
    setData({
      ...data,
      [key]: value,
    })
  }

  const resetForm = () => {
    setData(options?.initialValues || {})
    setErrors({})
  }
  
  const setDataForce = (values) => {
    setData(values || {})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validations = options?.validations
    if (validations) {
      let valid = true
      const newErrors = {}
      console.log('validations', validations)
      for (const key in validations) {
        const value = data[key]
        const validation = validations[key]
        // console.log('value', value, key)
        // console.log('validations', validations)

        if (validation?.type?.value && typeof value !== validation.type.value) {
          valid = false
          newErrors[key] = validation?.type?.message
        }

        if (validation?.required?.value && !value) {
          valid = false
          newErrors[key] = validation?.required?.message
        }

        const pattern = validation?.pattern
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false
          newErrors[key] = pattern.message
        }

        const custom = validation?.custom
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false
          newErrors[key] = custom.message
        }

        const nested = validation?.nested
        if (nested) {
          console.log('value', value)
          if (value?.length) {
            value.forEach((valItem, idx) => {
              const nestedValidations = nested.validations
              const nestedErrors = {}
                for (const nestedKey in nestedValidations) {
                  const nestedValue = valItem[nestedKey]
                  const nestedValidation = nestedValidations[nestedKey]

                  if (nestedValidation?.type?.value && typeof nestedValue !== nestedValidation.type.value) {
                    nestedErrors[nestedKey] = nestedValidation?.type?.message
                  }
          
                  if (nestedValidation?.required?.value && !nestedValue) {
                    nestedErrors[nestedKey] = nestedValidation?.required?.message
                  }
          
                  const pattern = nestedValidation?.pattern
                  if (pattern?.value && !RegExp(pattern.value).test(nestedValue)) {
                    nestedErrors[nestedKey] = pattern.message
                  }
          
                  const custom = nestedValidation?.custom
                  if (custom?.isValid && !custom.isValid(nestedValue)) {
                    nestedErrors[nestedKey] = custom.message
                  }
                }

              if (Object.keys(nestedErrors).length) {
                if (!newErrors[key]) newErrors[key] = {}
                newErrors[key][idx] = nestedErrors
              }
            }) 
          }

          console.log('sago', newErrors[key]);

          if (newErrors[key]) {
            valid = false
          }
        }
      }

      console.log('newErrors', newErrors)
      if (!valid) {
        setErrors(newErrors)
        return;
      }
    }

    setErrors({})

    if (options?.onSubmit) {
      options.onSubmit()
    }
  }

  return {
    data,
    handleChange,
    handleSubmit,
    resetForm,
    errors,
    setDataForce,
  }
}

export default useForm