
export const GET_LANGUAGE_OPTIONS = "GET_LANGUAGE_OPTIONS"
export const GET_LANGUAGE_OPTIONS_SUCCESS = "GET_LANGUAGE_OPTIONS_SUCCESS"
export const GET_LANGUAGE_OPTIONS_FAIL = "GET_LANGUAGE_OPTIONS_FAIL"

export const GET_PRODUCT_CATEGORY_OPTIONS = "GET_PRODUCT_CATEGORY_OPTIONS"
export const GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS = "GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS"
export const GET_PRODUCT_CATEGORY_OPTIONS_FAIL = "GET_PRODUCT_CATEGORY_OPTIONS_FAIL"

export const GET_PROJECT_CATEGORY_OPTIONS = "GET_PROJECT_CATEGORY_OPTIONS"
export const GET_PROJECT_CATEGORY_OPTIONS_SUCCESS = "GET_PROJECT_CATEGORY_OPTIONS_SUCCESS"
export const GET_PROJECT_CATEGORY_OPTIONS_FAIL = "GET_PROJECT_CATEGORY_OPTIONS_FAIL"

export const GET_PRODUCT_PROPERTIES_OPTIONS = "GET_PRODUCT_PROPERTIES_OPTIONS"
export const GET_PRODUCT_PROPERTIES_OPTIONS_SUCCESS = "GET_PRODUCT_PROPERTIES_OPTIONS_SUCCESS"
export const GET_PRODUCT_PROPERTIES_OPTIONS_FAIL = "GET_PRODUCT_PROPERTIES_OPTIONS_FAIL"

export const GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS = "GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS"
export const GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_SUCCESS = "GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_SUCCESS"
export const GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_FAIL = "GET_DISTRIBUTORSHIP_CATEGORY_OPTIONS_FAIL"


// action status
export const TOOGLE_FETCHING_LOADING = "TOOGLE_FETCHING_LOADING"
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION"
export const RESET_NOTIFICATION = "RESET_NOTIFICATION"
export const TOGGLE_ACTION_LOADING = "TOGGLE_ACTION_LOADING"