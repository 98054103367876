import {
  GET_REFERANCE_DETAIL,
  SET_REFERANCE_DETAIL,
  GET_REFERANCES,
  SET_REFERANCES,
  ADD_NEW_REFERANCE,
  UPDATE_REFERANCE,
  DELETE_REFERANCE,
} from "./actionTypes"

export const getReferances = (params) => ({
  type: GET_REFERANCES,
  payload: { params },
})

export const setReferances = payload => ({
  type: SET_REFERANCES,
  payload,
})

export const addNewReferance = payload => ({
  type: ADD_NEW_REFERANCE,
  payload,
})

export const getReferanceDetail = (params) => ({
  type: GET_REFERANCE_DETAIL,
  payload: { params },
})

export const setReferanceDetail = payload => ({
  type: SET_REFERANCE_DETAIL,
  payload,
})


export const updateReferance = payload => ({
  type: UPDATE_REFERANCE,
  payload,
})


export const deleteReferance = payload => ({
  type: DELETE_REFERANCE,
  payload,
})


