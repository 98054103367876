import { SET_FAQS, SET_FAQ_DETAIL } from "./actionTypes"

const INIT_STATE = {
  faqs: [],
  faqDetail: {},
}

const faq = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FAQS:
      return {
        ...state,
        faqs: action.payload,
      }

    case SET_FAQ_DETAIL:
      return {
        ...state,
        faqDetail: action.payload,
      }
    default:
      return state
  }
}

export default faq
