import { SET_HOME_SLIDERS, SET_HOME_SLIDER_DETAIL } from "./actionTypes"

const INIT_STATE = {
  homeSliders: [],
  homeSliderDetail: {},
}

const homeSlider = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_HOME_SLIDERS:
      return {
        ...state,
        homeSliders: action.payload,
      }

    case SET_HOME_SLIDER_DETAIL:
      return {
        ...state,
        homeSliderDetail: action.payload,
      }
    default:
      return state
  }
}

export default homeSlider
