import { SET_OURVALUES, SET_OURVALUE_DETAIL } from "./actionTypes"

const INIT_STATE = {
  ourValues: [],
  ourValueDetail: {},
}

const ourValue = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_OURVALUES:
      return {
        ...state,
        ourValues: action.payload,
      }

    case SET_OURVALUE_DETAIL:
      return {
        ...state,
        ourValueDetail: action.payload,
      }
    default:
      return state
  }
}

export default ourValue
