import { SET_SUSTAINABLES, SET_SUSTAINABLE_DETAIL } from "./actionTypes"

const INIT_STATE = {
  sustainables: [],
  sustainableDetail: {},
}

const Sustainable = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SUSTAINABLES:
      return {
        ...state,
        sustainables: action.payload,
      }

    case SET_SUSTAINABLE_DETAIL:
      return {
        ...state,
        sustainableDetail: action.payload,
      }
    default:
      return state
  }
}

export default Sustainable
