import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

import {
  postLogin,
  getMeViaToken,
} from "../../../helpers/backend_helper"


async function redirectToDashboard(response, loginResponse, history) {
  await localStorage.setItem("authUser", JSON.stringify({ ...response.data, accessToken: loginResponse.token }))
  window.location.reload();
}

function* loginUser({ payload: { user, history } }) {
  try {
    const loginResponse = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    const response = yield call(getMeViaToken, loginResponse.token)
    yield put(loginSuccess(response))
    redirectToDashboard(response, loginResponse, history);
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(apiError(errorMessage))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError('Something went wrong.'))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
