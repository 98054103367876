import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import {
  Button,
  Col,
  Input,
  Row,
  Label,
  FormFeedback,
} from "reactstrap"

const options = [{
  label: 'Fractional Number',
  value: 'fractionalNumber'
},
{
  label: 'Complete Number',
  value: 'completeNumber'
},
{
  label: 'Text (One Line)',
  value: 'textOneline'
},
{
  label: 'Text (Multiline Line)',
  value: 'textMultiline'
},
{
  label: 'Select (One)',
  value: 'selectOne'
},
{
  label: 'Select (Multiple)',
  value: 'selectMultiple'
}];

export default function ProductProperties({
  onChange,
  t,
  errors,
  valueKey,
  errorMessage,
  properties,
}) {



  const handleChange = (value, index) => {
    const modifiedCardFields = [...properties];
    modifiedCardFields[index].value = value;

    onChange(valueKey, modifiedCardFields)
  }


  return (
    <div className="inner-repeater mb-4">
      <div className="col-md-12 mt-2">
        <div className="card">
          <div className="card-header py-2">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <h2 className="m-0 h5">Properties</h2>
              </div>
            </div>
          </div>
          {properties?.map((property, index) => (
            <div key={index} className="card-body p-2">
              <div className="row">
                <div className="col-md-6 my-1">
                  <label>Name</label><sup className="text-danger">*</sup>
                  <input className="form-control" type="text" value={property.label} disabled />
                </div>
                <div className="col-md-4 col-xl-5 my-1">
                  <label>Value</label><sup className="text-danger">*</sup>
                  {['fractionalNumber', 'completeNumber'].includes(property.input.type) ? (
                    <input className="form-control" type="number" step="any" value={property?.value || ''} onChange={(e) => handleChange(e.target.value, index)} />
                  ) : null}
                  {['textOneline', 'textMultiline'].includes(property.input.type) ? (
                    <input className="form-control" type="text" value={property?.value || ''} onChange={(e) => handleChange(e.target.value, index)} />
                  ) : null}
                  {['selectOne', 'selectMultiple'].includes(property.input.type) ? (
                    <Select
                      name={valueKey}
                      multiple={property.input.type == 'selectMultiple'}
                      value={property.input.propertyChoices.find(o => o.value == property?.value) || null}
                      onChange={(e) => handleChange(e.value, index)}
                      options={property.input.propertyChoices?.map(p => ({ label: p.label, value: p.value }))}
                      classNamePrefix="select2-selection"
                      placeholder={t('form_placeholder', { name: t("Property Choice")})}
                    />
                  ) : null}
                  
                </div>
              </div>
              </div>
          ))}
        </div>
      </div>
    </div>
  )
}

ProductProperties.propTypes = {
  valueKey: PropTypes.string,
  properties: PropTypes.any,
  errorMessage: PropTypes.any,
  errors: PropTypes.any,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDeleteFile: PropTypes.func,
  t: PropTypes.any,
}
