import { SET_DISTRIBUTORSHIPS, SET_DISTRIBUTORSHIP_DETAIL } from "./actionTypes"

const INIT_STATE = {
  distributorships: [],
  distributorshipDetail: {},
}

const distributorship = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DISTRIBUTORSHIPS:
      return {
        ...state,
        distributorships: action.payload,
      }

    case SET_DISTRIBUTORSHIP_DETAIL:
      return {
        ...state,
        distributorshipDetail: action.payload,
      }
    default:
      return state
  }
}

export default distributorship;
