export default function getImage ({ path, width, height, fit, position }) {
    if (!path) return '';

    const resize = width || height || fit || position;
    const params = resize ? `image/resize${path}/${width}/${height}/${fit}/${position}`.split('/undefined').join('') : `file${path}`;

    return process.env.IS_DEVELOPMENT === 'true' ?
        `http://localhost:5005/cdn/${params}` :
        `${process.env.REACT_APP_CDN_IMAGE_PATH}/${params}`;
}

