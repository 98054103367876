
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PROJECTS, GET_PROJECT_DETAIL , ADD_NEW_PROJECT , DELETE_PROJECT, UPDATE_PROJECT } from "./actionTypes"

import {
  setProjectDetail,
  setProjects,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../../form/actions"

import { getProjects, getProjectDetail, deleteProject, updateProject, addProject } from "../../../helpers/backend_helper"


function* fetchProjects({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setProjectDetail({}))
  try {
    const response = yield call(getProjects, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setProjects(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchProjectDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getProjectDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setProjectDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateProject({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateProject, payload)
    yield put(toggleActionLoading(false))
    yield put(setProjectDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Project updated successfuly.', redirectUrl: '/projects/project-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteProject({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteProject, id)
    const responsePages = yield call(getProjects, { params })
    yield put(setProjects(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Project deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewProject({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addProject, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Project added successfuly.', redirectUrl: '/projects/project-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* ProjectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects)
  yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail)
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject)
  yield takeEvery(UPDATE_PROJECT, onUpdateProject)
  yield takeEvery(DELETE_PROJECT, onDeleteProject)
}

export default ProjectsSaga
