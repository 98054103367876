import { call, put, takeEvery } from "redux-saga/effects"

import {

  GET_LANGUAGES,
  GET_LANGUAGE_DETAIL,
  ADD_NEW_LANGUAGE,
  DELETE_LANGUAGE,
  UPDATE_LANGUAGE,
} from "./actionTypes"

import {
  setLanguages,
  setLanguageDetail,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import {
  getLanguages,
  getLanguageDetail,
  deleteLanguage,
  updateLanguage,
  addLanguage,
} from "../../helpers/backend_helper"


function* fetchLanguages({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setLanguageDetail({}))
  try {
    const response = yield call(getLanguages, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setLanguages(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchLanguageDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getLanguageDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setLanguageDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateLanguage({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateLanguage, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Language updated successfuly.", redirectUrl: '/language-list'}))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteLanguage({ payload: { id, params } }) {
  try {
    const response = yield call(deleteLanguage, id);
    const responsePages = yield call(getLanguages, { params })
    yield put(setLanguages(responsePages));
    yield put(showNotification({ type: 'success',  message: "Language deleted successfuly.", redirectUrl: '/language-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewLanguage({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addLanguage, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Language added successfuly.", redirectUrl: '/language-list'}))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* languageSaga() {
  yield takeEvery(GET_LANGUAGES, fetchLanguages)
  yield takeEvery(GET_LANGUAGE_DETAIL, fetchLanguageDetail)
  yield takeEvery(ADD_NEW_LANGUAGE, onAddNewLanguage)
  yield takeEvery(UPDATE_LANGUAGE, onUpdateLanguage)
  yield takeEvery(DELETE_LANGUAGE, onDeleteLanguage)
}

export default languageSaga
