import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_GLOBAL_DETAIL, UPDATE_GLOBAL } from "./actionTypes"

import {
  setGlobalDetail,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getGlobalDetail, updateGlobal } from "../../helpers/backend_helper"


function* fetchGlobalDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getGlobalDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setGlobalDetail(response.data))
  } catch (error) {
    if (error.message || error.response.status == 404) {
      window.location.href = '/change-language'
      yield put(showNotification({ type: 'error',  message: 'A global is not defined in this language.'}))
    } else {
      const errorMessage = error.response ? error.response.data.error : error.message;
      yield put(toggleActionLoading(false))
      yield put(showNotification({ type: 'error',  message: errorMessage}))
    }
  }
}

function* onUpdateService({ payload: params }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateGlobal, params)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: "success",  message: "Global updated successfuly." } ))
  } catch (error) {
    yield put(toggleActionLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}


function* servicesSaga() {
  yield takeEvery(GET_GLOBAL_DETAIL, fetchGlobalDetail)
  yield takeEvery(UPDATE_GLOBAL, onUpdateService)
}

export default servicesSaga
