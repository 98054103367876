import {
  SET_USERS,
  SET_USER_DETAIL,

} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userDetail: {},
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      }


    case SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      }

    default:
      return state
  }
}

export default users
