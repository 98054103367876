import {
  GET_SUSTAINABLE_DETAIL,
  SET_SUSTAINABLE_DETAIL,
  GET_SUSTAINABLES,
  SET_SUSTAINABLES,
  ADD_NEW_SUSTAINABLE,
  UPDATE_SUSTAINABLE,
  DELETE_SUSTAINABLE,
} from "./actionTypes"

export const getSustainables = (params) => ({
  type: GET_SUSTAINABLES,
  payload: { params },
})

export const setSustainables = payload => ({
  type: SET_SUSTAINABLES,
  payload,
})

export const addNewSustainable = payload => ({
  type: ADD_NEW_SUSTAINABLE,
  payload,
})

export const getSustainableDetail = (params) => ({
  type: GET_SUSTAINABLE_DETAIL,
  payload: { params },
})

export const setSustainableDetail = payload => ({
  type: SET_SUSTAINABLE_DETAIL,
  payload,
})


export const updateSustainable = payload => ({
  type: UPDATE_SUSTAINABLE,
  payload,
})


export const deleteSustainable = payload => ({
  type: DELETE_SUSTAINABLE,
  payload,
})


