import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"


//apply base url for axios
const API_URL = process.env.IS_DEVELOPMENT === 'true' ? "http://localhost:5005" : process.env.REACT_APP_API_DOMAIN;
// const API_URL = "http://localhost:5005"

const axiosApi = axios.create({
  baseURL: API_URL,
})

const token = authHeader()?.Authorization || null

if (token) {
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

axiosApi.interceptors.request.use(function (config) {
  if (!token) {
    const tokenNew = authHeader()?.Authorization || null;
    config.headers["Accept-Language"] = `Bearer ${tokenNew}`;
  }
  config.headers["Accept-Language"] = localStorage.getItem('i18nextLng') || 'en-US';
  config.headers["Accept-Language-i18n"] = localStorage.getItem('i18nextLng') || 'en-US';
  return config;
});


axiosApi.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status == 401) {
    localStorage.removeItem('authUser');
    window.location.href = '/login'
    return;
  }
  if (error?.response?.status == 404) {
    window.location.href = '/404'
    return;
  }
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function getWithoutPagination(url, config = {}) {
  return await axiosApi.get(`${url}/all`, { ...config }).then(response => response.data)
}

export async function postFormData(url, data) {
  return axiosApi
    .post(url, data, {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  console.log('data', data);
  const id = data.id || data._id;
  delete data._id;
  delete data.id;
  return axiosApi
    .put(`${url}/${id}`, { ...data }, { ...config })
    .then(response => response.data)
}

export async function putFormData(url, data) {
  const id = data.id || data._id;
  return axiosApi
    .put(`${url}/${id}`, data.formData, {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
