import {
  GET_SOLUTION_DETAIL,
  SET_SOLUTION_DETAIL,
  GET_SOLUTIONS,
  SET_SOLUTIONS,
  ADD_NEW_SOLUTION,
  UPDATE_SOLUTION,
  DELETE_SOLUTION,
} from "./actionTypes"

export const getSolutions = (params) => ({
  type: GET_SOLUTIONS,
  payload: { params },
})

export const setSolutions = payload => ({
  type: SET_SOLUTIONS,
  payload,
})

export const addNewSolution = payload => ({
  type: ADD_NEW_SOLUTION,
  payload,
})

export const getSolutionDetail = (params) => ({
  type: GET_SOLUTION_DETAIL,
  payload: { params },
})

export const setSolutionDetail = payload => ({
  type: SET_SOLUTION_DETAIL,
  payload,
})


export const updateSolution = payload => ({
  type: UPDATE_SOLUTION,
  payload,
})


export const deleteSolution = payload => ({
  type: DELETE_SOLUTION,
  payload,
})


