
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_SUSTAINABLES, GET_SUSTAINABLE_DETAIL , ADD_NEW_SUSTAINABLE , DELETE_SUSTAINABLE, UPDATE_SUSTAINABLE } from "./actionTypes"

import {
  setSustainableDetail,
  setSustainables,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getSustainables, getSustainableDetail, deleteSustainable, updateSustainable, addSustainable } from "../../helpers/backend_helper"


function* fetchSustainables({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setSustainableDetail({}))
  try {
    const response = yield call(getSustainables, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setSustainables(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchSustainableDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getSustainableDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setSustainableDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateSustainable({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateSustainable, payload)
    yield put(toggleActionLoading(false))
    yield put(setSustainableDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'Sustainable updated successfuly.', redirectUrl: '/sustainable-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteSustainable({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteSustainable, id)
    const responsePages = yield call(getSustainables, { params })
    yield put(setSustainables(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "Sustainable deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewSustainable({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addSustainable, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'Sustainable added successfuly.', redirectUrl: '/sustainable-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* SustainablesSaga() {
  yield takeEvery(GET_SUSTAINABLES, fetchSustainables)
  yield takeEvery(GET_SUSTAINABLE_DETAIL, fetchSustainableDetail)
  yield takeEvery(ADD_NEW_SUSTAINABLE, onAddNewSustainable)
  yield takeEvery(UPDATE_SUSTAINABLE, onUpdateSustainable)
  yield takeEvery(DELETE_SUSTAINABLE, onDeleteSustainable)
}

export default SustainablesSaga
