
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_BLOG_POSTS, GET_BLOG_POST_DETAIL , ADD_NEW_BLOG_POST , DELETE_BLOG_POST, UPDATE_BLOG_POST } from "./actionTypes"

import {
  setBlogPostDetail,
  setBlogPosts,
} from "./actions"

import {
  toggleFetchingLoading,
  toggleActionLoading,
  showNotification
} from "../form/actions"

import { getBlogPosts, getBlogPostDetail, deleteBlogPost, updateBlogPost, addBlogPost } from "../../helpers/backend_helper"


function* fetchBlogPosts({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  yield put(setBlogPostDetail({}))
  try {
    const response = yield call(getBlogPosts, { params })
    yield put(toggleFetchingLoading(false))
    yield put(setBlogPosts(response))
  } catch (error) {
    yield put(toggleFetchingLoading(false))
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* fetchBlogPostDetail({ payload: { params } }) {
  yield put(toggleFetchingLoading(true))
  try {
    const response = yield call(getBlogPostDetail, params)
    yield put(toggleFetchingLoading(false))
    yield put(setBlogPostDetail(response.data))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleFetchingLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onUpdateBlogPost({ payload }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(updateBlogPost, payload)
    yield put(toggleActionLoading(false))
    yield put(setBlogPostDetail(response.data))
    yield put(showNotification({ type: 'success', message: 'BlogPost updated successfuly.', redirectUrl: '/blog-post-list'}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onDeleteBlogPost({ payload: { id, params } }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(deleteBlogPost, id)
    const responsePages = yield call(getBlogPosts, { params })
    yield put(setBlogPosts(responsePages))
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success',  message: "BlogPost deleted successfuly."}))
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(showNotification({ type: 'error',  message: errorMessage}))
  }
}

function* onAddNewBlogPost({ payload: user }) {
  yield put(toggleActionLoading(true))
  try {
    const response = yield call(addBlogPost, user)
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'success', message: 'BlogPost added successfuly.', redirectUrl: '/blog-post-list'}))
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    yield put(toggleActionLoading(false))
    yield put(showNotification({ type: 'error',  message: errorMessage }))
  }
}

function* blogPostsSaga() {
  yield takeEvery(GET_BLOG_POSTS, fetchBlogPosts)
  yield takeEvery(GET_BLOG_POST_DETAIL, fetchBlogPostDetail)
  yield takeEvery(ADD_NEW_BLOG_POST, onAddNewBlogPost)
  yield takeEvery(UPDATE_BLOG_POST, onUpdateBlogPost)
  yield takeEvery(DELETE_BLOG_POST, onDeleteBlogPost)
}

export default blogPostsSaga
