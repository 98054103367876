import { SET_PROJECT_CATEGORIES, SET_PROJECT_CATEGORY_DETAIL } from "./actionTypes"

const INIT_STATE = {
  projectCategories: [],
  projectCategoryDetail: {},
}

const ProjectCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PROJECT_CATEGORIES:
      return {
        ...state,
        projectCategories: action.payload,
      }

    case SET_PROJECT_CATEGORY_DETAIL:
      return {
        ...state,
        projectCategoryDetail: action.payload,
      }
    default:
      return state
  }
}

export default ProjectCategory
