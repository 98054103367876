import {
  GET_CONTACT_DETAIL,
  SET_CONTACT_DETAIL,
  GET_CONTACTS,
  SET_CONTACTS,
  ADD_NEW_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
} from "./actionTypes"

export const getContacts = (params) => ({
  type: GET_CONTACTS,
  payload: { params },
})

export const setContacts = payload => ({
  type: SET_CONTACTS,
  payload,
})

export const addNewContact = payload => ({
  type: ADD_NEW_CONTACT,
  payload,
})

export const getContactDetail = (params) => ({
  type: GET_CONTACT_DETAIL,
  payload: { params },
})

export const setContactDetail = payload => ({
  type: SET_CONTACT_DETAIL,
  payload,
})


export const updateContact = payload => ({
  type: UPDATE_CONTACT,
  payload,
})


export const deleteContact = payload => ({
  type: DELETE_CONTACT,
  payload,
})


