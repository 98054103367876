import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardTitle,
} from "reactstrap"

import { getDashboardInfo } from "helpers/backend_helper"

import Breadcrumbs from "components/Common/Breadcrumb"
import WelcomeComp from "./WelcomeComp"
import SocialSource from "./SocialSource"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const Dashboard = props => {
  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState([
    { title: props.t("Total Project"), key: "project", iconClass: "bx-news", description: "" },
    { title: props.t("Blog Posts"), key: "post", iconClass: "bx-news", description: "" },
    {
      title: props.t("Subscribers"),
      key: "subscriber",
      iconClass: "bx-user",
      description: "",
    },
  ])

  useEffect(() => {
    getDashboardInfo().then((response) => {
      setReport(report.map(r => ({ ...r, description: response.data.count[r.key] })))
      setLoading(false)
    }).catch((err) => {
      console.log('dashboard info', err)
      setLoading(false)
    });
  }, [])



  const dispatch = useDispatch()

  console.log('report', report);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Dashboard")} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <WelcomeComp />
            </Col>
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {report.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                            {props.t(report.title)}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="d-flex">
            <Col xl="6">
              <SocialSource />
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
