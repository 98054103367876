import {
  GET_BLOG_POST_DETAIL,
  SET_BLOG_POST_DETAIL,
  GET_BLOG_POSTS,
  SET_BLOG_POSTS,
  ADD_NEW_BLOG_POST,
  UPDATE_BLOG_POST,
  DELETE_BLOG_POST,
} from "./actionTypes"

export const getBlogPosts = (params) => ({
  type: GET_BLOG_POSTS,
  payload: { params },
})

export const setBlogPosts = payload => ({
  type: SET_BLOG_POSTS,
  payload,
})

export const addNewBlogPost = payload => ({
  type: ADD_NEW_BLOG_POST,
  payload,
})

export const getBlogPostDetail = (params) => ({
  type: GET_BLOG_POST_DETAIL,
  payload: { params },
})

export const setBlogPostDetail = payload => ({
  type: SET_BLOG_POST_DETAIL,
  payload,
})


export const updateBlogPost = payload => ({
  type: UPDATE_BLOG_POST,
  payload,
})


export const deleteBlogPost = payload => ({
  type: DELETE_BLOG_POST,
  payload,
})


