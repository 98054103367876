import { SET_DIRECTORS, SET_DIRECTOR_DETAIL } from "./actionTypes"

const INIT_STATE = {
  directors: [],
  directorDetail: {},
}

const director = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DIRECTORS:
      return {
        ...state,
        directors: action.payload,
      }

    case SET_DIRECTOR_DETAIL:
      return {
        ...state,
        directorDetail: action.payload,
      }
    default:
      return state
  }
}

export default director
