import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../../validations';

import {
  addNewProductCategory,
  updateProductCategory,
  getProductCategoryDetail as onGetProductCategoryDetail,
  deleteProductCategory as onDeleteProductCategory
} from "store/products/product_categories/actions"

import {
  getProductCategoryOptions,
} from "store/form/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import FileUpload from "components/FileUpload"
import LanguageSelect from "components/LanguageSelect"
import CategorySelect from "components/CategorySelect"
import StateSelect from "components/StateSelect"
import TagInput from "components/TagInput"

// import CategoryProperties from "components/CategoryProperties"


const ProductCategoryCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { productCategories, productCategoryDetail, formOptions } = useSelector(state => ({
    productCategories: state.productCategories.productCategories,
    productCategoryDetail: state.productCategories.productCategoryDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    dispatch(getProductCategoryOptions())
    if (id) {
      dispatch(onGetProductCategoryDetail(id))
    } else {
      setDataForce({
        language: '',
        title: '',
        short_title: '',
        // is_leaf: false,
        slogan: '',
        type: '',
        content_title: '',
        tags: [],
        meta_title: '',
        meta_description: '',
        description: '',
        image_cover: [],
        // properties: [],
        state: 'published',
        parentId: null,
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(productCategoryDetail).length) {
      setDataForce({
        language: productCategoryDetail.language,
        title: productCategoryDetail.title,
        short_title: productCategoryDetail.short_title,
        slogan: productCategoryDetail.slogan,
        type: productCategoryDetail.type,
        content_title: productCategoryDetail.content_title,
        tags: productCategoryDetail?.tags || [],
        // is_leaf: productCategoryDetail.is_leaf || false,
        meta_title: productCategoryDetail.meta_title,
        meta_description: productCategoryDetail.meta_description,
        description: productCategoryDetail.description,
        image_cover: productCategoryDetail?.image_cover ? [productCategoryDetail?.image_cover] : [],
        state: productCategoryDetail.state,
        parentId: productCategoryDetail.parentId,
        // properties: productCategoryDetail?.properties || [],
      })
    }
  }, [productCategoryDetail])
  

  const validationList = ['title', 'language','short_title','meta_title','meta_description','state', 'description'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      title: '',
      short_title: '',
      slogan: '',
      type: '',
      content_title: '',
      tags: [],
      // is_leaf: false,
      meta_title: '',
      meta_description: '',
      description: '',
      image_cover: [],
      // properties: [],
      state: 'published',
      parentId: null
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      title: data.title,
      language: data.language,
      short_title: data.short_title,
      slogan: data.slogan,
      type: data.type,
      content_title: data.content_title,
      tags: data.tags,
      // is_leaf: data.is_leaf,
      meta_title: data.meta_title,
      meta_description: data.meta_description,
      description: data.description,
      state: data.state,
      parentId: data.parentId,
      // properties: data.properties,
      image_cover: data.image_cover[0] || '',
    }

    if (id) {
      dispatch(updateProductCategory(params))
    } else {
      dispatch(addNewProductCategory(params))
    }

  }


  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm(props.t('Are you sure?'))) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteProductCategory({ id }))
      setTimeout(() => {
        history.push("/products/product-category-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Product Category") }) : props.t("page_info_create", { name: props.t("Product Category") });


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Product Categories')} titleLink="/products/product-category-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          invalid={errors.title}
                          onChange={handleChange('title')}
                          value={data.title}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Title")})}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="parentId"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Category Parent Id")}
                      </Label>
                      <Col lg="10">
                        <CategorySelect
                          t={props.t}
                          value={data?.parentId}
                          valueKey="parentId"
                          errorMessage={errors.parentId}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup className="mb-4" row>
                      <div className="col-lg-2"></div>
                      <Col lg="10" className="d-flex align-items-center">
                        <Label
                          htmlFor="is_leaf"
                          className="col-form-label font-weight-normal"
                          style={{ marginRight: 10 }}
                        >
                          {props.t("Is Leaf Category")}
                        </Label>
                        <Input
                          id="is_leaf"
                          name="is_leaf"
                          type="checkbox"
                          invalid={errors.is_leaf}
                          onChange={handleChange('is_leaf')}
                          value={data.is_leaf}
                          className="form-control m-0"
                          placeholder={props.t('form_placeholder', { name: props.t("Is Leaf Category")})}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}

                      </Col>
                    </FormGroup> */}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("State")}
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          t={props.t}
                          value={data?.state}
                          valueKey="state"
                          onChange={(key, value) => handleChange(key)(value)}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="short_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Short Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="short_title"
                          name="short_title"
                          type="text"
                          invalid={errors.short_title}
                          onChange={handleChange('short_title')}
                          value={data?.short_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Short Title")})}
                        />
                        {errors.short_title && <FormFeedback>{errors.short_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="slogan"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Slogan")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="slogan"
                          name="slogan"
                          type="text"
                          invalid={errors.slogan}
                          onChange={handleChange('slogan')}
                          value={data?.slogan || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Slogan")})}
                        />
                        {errors.slogan && <FormFeedback>{errors.slogan}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="type"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Type")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="type"
                          name="type"
                          type="text"
                          invalid={errors.type}
                          onChange={handleChange('type')}
                          value={data?.type || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Type")})}
                        />
                        {errors.type && <FormFeedback>{errors.type}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="content_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Content Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="content_title"
                          name="content_title"
                          type="text"
                          invalid={errors.content_title}
                          onChange={handleChange('content_title')}
                          value={data?.content_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Content Title")})}
                        />
                        {errors.content_title && <FormFeedback>{errors.content_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="meta_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Meta Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="meta_title"
                          name="meta_title"
                          type="text"
                          invalid={errors.meta_title}
                          onChange={handleChange('meta_title')}
                          value={data?.meta_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Meta Title")})}
                        />
                        {errors.meta_title && <FormFeedback>{errors.meta_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="meta_description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Meta Description")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="meta_description"
                          name="meta_description"
                          type="text"
                          invalid={errors.meta_description}
                          onChange={handleChange('meta_description')}
                          value={data?.meta_description || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Meta Description")})}
                        />
                        {errors.meta_description && <FormFeedback>{errors.meta_description}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Tags")}
                      </Label>
                      <Col lg="10">
                        <TagInput
                          t={props.t}
                          tags={data?.tags || []}
                          errorMessage={errors.tags}
                          valueKey="tags"
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup> 
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Content")}
                      </Label>
                      <Col lg="10">
                        <CKEditor
                          editor={ClassicEditor}
                          data={data.description}
                          onChange={(event, editor) => {
                            const editorData = editor.getData()
                            if (id) {
                              if (id && data?.description?.charAt(0) === '<') {
                                handleChange('description')(editorData)
                              }
                            } else {
                              handleChange('description')(editorData)
                            }
                          }}
                        />
                        {errors.description && <span className="error-form-field">{errors.description}</span>}
                      </Col>
                    </FormGroup>                   
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover")(files)}
                          multiple={false}
                          images={data?.image_cover}
                          errorMessage={errors.image_cover}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover')}
                        />
                      </Col>
                    </Row>
                    {/* <FormGroup className="mb-4" row>
                      <Label
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Properties")}
                      </Label>
                      <Col lg="10">
                        <CategoryProperties
                          t={props.t}
                          errors={errors}
                          properties={data?.properties || []}
                          errorMessage={errors.properties}
                          valueKey="properties"
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>   */}
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Product Category")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Product Category")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Product Category")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProductCategoryCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductCategoryCreate)
