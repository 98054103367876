import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

import { generateValidation } from '../../../validations';

import {
  addNewVideoSlider,
  updateVideoSlider,
  getVideoSliderDetail as onGetVideoSliderDetail,
  deleteVideoSlider as onDeleteVideoSlider
} from "store/video_sliders/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import useForm from "hooks/useForm"

import FileUpload from "components/FileUpload"
import LanguageSelect from "components/LanguageSelect"
import StateSelect from "components/StateSelect"


const VideoSliderCreate = (props) => {
  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { videoSliders, videoSliderDetail, formOptions } = useSelector(state => ({
    videoSliders: state.videoSliders.videoSliders,
    videoSliderDetail: state.videoSliders.videoSliderDetail,
    formOptions: state.form
  }))


  useEffect(() => {
    if (id) {
      dispatch(onGetVideoSliderDetail(id))
    } else {
      setDataForce({
        language: '',
        title: '',
        short_title: '',
        url: '',
        duration: '',
        image_cover: [],
        state: 'published',
      })
    }
  }, [id])


  useEffect(() => {
    if (id && Object.keys(videoSliderDetail).length) {
      setDataForce({
        language: videoSliderDetail.language,
        title: videoSliderDetail.title,
        short_title: videoSliderDetail.short_title,
        url: videoSliderDetail.url,
        duration: videoSliderDetail.duration,
        image_cover: videoSliderDetail?.image_cover ? [videoSliderDetail?.image_cover] : [],
        state: videoSliderDetail.state,
      })
    }
  }, [videoSliderDetail])
  

  const validationList = ['title', 'language','short_title','state', 'url'];
  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
    setDataForce,
  } = useForm({
    validations : {
      ...generateValidation(validationList, props.t),
    },
    onSubmit: () => handleValidSubmit(),
    initialValues: {
      language: '',
      title: '',
      short_title: '',
      url: '',
      duration: '',
      image_cover: [],
      state: 'published'
    },
  })

  const handleValidSubmit = () => {
    const params = {
      id,
      title: data.title,
      language: data.language,
      short_title: data.short_title,
      url: data.url,
      duration: data.duration,
      state: data.state,
      image_cover: data.image_cover[0] || '',
    }

    if (id) {
      dispatch(updateVideoSlider(params))
    } else {
      dispatch(addNewVideoSlider(params))
    }

  }


  const handleDeleteFile = async (file, key) => {
    const fileIsString = typeof file === 'string'
    if (confirm(props.t('Are you sure?'))) {
      const newFiles = data[key].filter((f) => fileIsString ? f !== file : f.name !== file.name)
        handleChange(key)(newFiles)
    }
  }


  const handleDelete = () => {
    if(confirm(props.t('Are you sure?'))) {
      dispatch(onDeleteVideoSlider({ id }))
      setTimeout(() => {
        history.push("/videoSlider-list")
      }, 300)
    }
  }

  const seoTitle = id ? props.t("page_info_create", { name: props.t("Video Slider") }) : props.t("page_info_create", { name: props.t("Video Slider") });


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{seoTitle} | Dragon CMS - DaseinSoftware Hub</title>
        </MetaTags>
        <Container fluid>

          <Breadcrumbs title={props.t('Video Slider')} titleLink="/video-sliders-list" breadcrumbItem={seoTitle} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{seoTitle}</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="language"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Language')}
                      </Label>
                      <Col lg="10">
                        <LanguageSelect
                          t={props.t}
                          value={data?.language}
                          valueKey="language"
                          errorMessage={errors.language}
                          onChange={(key, value) => handleChange(key)(value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("State")}
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          t={props.t}
                          value={data?.state}
                          valueKey="state"
                          onChange={(key, value) => handleChange(key)(value)}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          invalid={errors.title}
                          onChange={handleChange('title')}
                          value={data.title}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Title")})}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="short_title"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Short Title")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="short_title"
                          name="short_title"
                          type="text"
                          invalid={errors.short_title}
                          onChange={handleChange('short_title')}
                          value={data?.short_title || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Short Title")})}
                        />
                        {errors.short_title && <FormFeedback>{errors.short_title}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="url"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("URL")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="url"
                          name="url"
                          type="text"
                          invalid={errors.url}
                          onChange={handleChange('url')}
                          value={data?.url || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("URL")})}
                        />
                        {errors.url && <FormFeedback>{errors.url}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="duration"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Duration")}
                      </Label>
                      <Col lg="10">
                        <Input
                          id="duration"
                          name="duration"
                          type="text"
                          invalid={errors.duration}
                          onChange={handleChange('duration')}
                          value={data?.duration || ''}
                          className="form-control"
                          placeholder={props.t('form_placeholder', { name: props.t("Duration")})}
                        />
                        {errors.duration && <FormFeedback>{errors.duration}</FormFeedback>}
                      </Col>
                    </FormGroup>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Image Cover")}
                      </Label>
                      <Col lg="10">
                        <FileUpload
                          onChange={(files) => handleChange("image_cover")(files)}
                          multiple={false}
                          images={data?.image_cover}
                          errorMessage={errors.image_cover}
                          valueKey="image_cover"
                          onDeleteFile={(f) => handleDeleteFile(f, 'image_cover')}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        {id ? (
                          <>
                            <Button type="submit" color="primary">
                            {props.t("edit_btn_for_model", { name: props.t("Video Slider")})}
                            </Button>
                            <Button type="button" style={{ marginLeft: '1rem'}} color="danger" onClick={handleDelete}>
                            {props.t("delete_btn_for_model", { name: props.t("Video Slider")})}
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("create_btn_for_model", { name: props.t("Video Slider")})}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

VideoSliderCreate.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(VideoSliderCreate)
