import {
  GET_PROJECT_DETAIL,
  SET_PROJECT_DETAIL,
  GET_PROJECTS,
  SET_PROJECTS,
  ADD_NEW_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
} from "./actionTypes"

export const getProjects = (params) => ({
  type: GET_PROJECTS,
  payload: { params },
})

export const setProjects = payload => ({
  type: SET_PROJECTS,
  payload,
})

export const addNewProject = payload => ({
  type: ADD_NEW_PROJECT,
  payload,
})

export const getProjectDetail = (params) => ({
  type: GET_PROJECT_DETAIL,
  payload: { params },
})

export const setProjectDetail = payload => ({
  type: SET_PROJECT_DETAIL,
  payload,
})


export const updateProject = payload => ({
  type: UPDATE_PROJECT,
  payload,
})


export const deleteProject = payload => ({
  type: DELETE_PROJECT,
  payload,
})


