import {
  GET_DYNAMIC_PAGE_DETAIL,
  SET_DYNAMIC_PAGE_DETAIL,
  GET_DYNAMIC_PAGES,
  SET_DYNAMIC_PAGES,
  ADD_NEW_DYNAMIC_PAGE,
  UPDATE_DYNAMIC_PAGE,
  DELETE_DYNAMIC_PAGE,
} from "./actionTypes"

export const getDynamicPages = (params) => ({
  type: GET_DYNAMIC_PAGES,
  payload: { params },
})

export const setDynamicPages = payload => ({
  type: SET_DYNAMIC_PAGES,
  payload,
})

export const addNewDynamicPage = payload => ({
  type: ADD_NEW_DYNAMIC_PAGE,
  payload,
})

export const getDynamicPageDetail = (params) => ({
  type: GET_DYNAMIC_PAGE_DETAIL,
  payload: { params },
})

export const setDynamicPageDetail = payload => ({
  type: SET_DYNAMIC_PAGE_DETAIL,
  payload,
})


export const updateDynamicPage = payload => ({
  type: UPDATE_DYNAMIC_PAGE,
  payload,
})


export const deleteDynamicPage = payload => ({
  type: DELETE_DYNAMIC_PAGE,
  payload,
})


